/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./popup.component.styl.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "./popup.component";
var styles_PopupComponent = [i0.styles];
var RenderType_PopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PopupComponent, data: {} });
export { RenderType_PopupComponent as RenderType_PopupComponent };
export function View_PopupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "col-md-10"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "col-md-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "button", [["aria-label", "Close"], ["class", "close mat-button-close"], ["id", "matClose"], ["mat-dialog-close", ""], ["type", "button"]], [[1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).dialogRef.close(i1.ɵnov(_v, 4).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { ariaLabel: [0, "ariaLabel"], dialogResult: [1, "dialogResult"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "video_cont"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "img", [["src", "../../../../../assets/gocampaign/images/popup_image.jpg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 3, "h6", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u201CThe \u2018moment of truth\u2019 we want to create must be personalized while remaining professional\u2026the videos are especially important during this period as customers are spending more time at home and performing their transactions online.\u201D Mr. Antoine Najib Maalouli, CEO, Al Fujairah National Insurance Company (AFNIC). "])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "a", [["href", "https://www.meinsurancereview.com/Magazine/ReadMagazineArticle/aid/43468/Staying-connected-in-a-changing-environment"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Read full article"]))], function (_ck, _v) { var currVal_1 = "Close"; var currVal_2 = ""; _ck(_v, 4, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 4).ariaLabel || null); _ck(_v, 3, 0, currVal_0); }); }
export function View_PopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-popup", [], null, null, null, View_PopupComponent_0, RenderType_PopupComponent)), i1.ɵdid(1, 114688, null, 0, i3.PopupComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PopupComponentNgFactory = i1.ɵccf("app-popup", i3.PopupComponent, View_PopupComponent_Host_0, {}, {}, []);
export { PopupComponentNgFactory as PopupComponentNgFactory };
