import { Component } from '@angular/core';
import { Location } from "@angular/common";
import { Title, Meta } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';



@Component({ selector: 'app-root', templateUrl: 'app.component.html' })
export class AppComponent {
    curr_path: string;
    constructor(
        location: Location,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private titleService: Title,
        private metaService: Meta

    ) {
        this.curr_path=location.path();
    }

    ngOnInit() {

        var htmlBody = document.getElementsByTagName("html")[0];
        htmlBody.scrollTop = 0;

        //PAGE SCROLL TOP
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0)
        });

      //  if (environment.production) {
      //      if (location.protocol === 'http:') {
      //          window.location.href = 'https://www.gocampaignvideos.com/';
      //       }else if(location.protocol === 'https:'){
      //          if(window.location.hostname=='gocampaignvideos.com'){
      //           window.location.href = 'https://www.gocampaignvideos.com/';
      //          }
      //       }
      //   } 

        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
          )
            .subscribe(() => {
       
              var rt = this.getChild(this.activatedRoute)
       
              rt.data.subscribe(data => {
                //console.log(data);
                this.titleService.setTitle(data.title)
       
                if (data.descrption) {
                  this.metaService.updateTag({ name: 'description', content: data.descrption })
                } else {
                  this.metaService.removeTag("name='description'")
                }
       
                if (data.robots) {
                  this.metaService.updateTag({ name: 'robots', content: data.robots })
                } else {
                  this.metaService.updateTag({ name: 'robots', content: "follow,index" })
                }
       
                if (data.ogUrl) {
                  this.metaService.updateTag({ property: 'og:url', content: data.ogUrl })
                } else {
                  this.metaService.updateTag({ property: 'og:url', content: this.router.url })
                }
       
                if (data.ogTitle) {
                  this.metaService.updateTag({ property: 'og:title', content: data.ogTitle })
                } else {
                  this.metaService.removeTag("property='og:title'")
                }
       
                if (data.ogDescription) {
                  this.metaService.updateTag({ property: 'og:description', content: data.ogDescription })
                } else {
                  this.metaService.removeTag("property='og:description'")
                }
       
                if (data.ogImage) {
                  this.metaService.updateTag({ property: 'og:image', content: data.ogImage })
                } else {
                  this.metaService.removeTag("property='og:image'")
                }

                if (data.ogKeywords) {
                  this.metaService.updateTag({ property: 'keywords', content: data.ogKeywords })
                } else {
                  this.metaService.removeTag("property='keywords'")
                }

              })
            })
        
    }


    getChild(activatedRoute: ActivatedRoute) {
        if (activatedRoute.firstChild) {
          return this.getChild(activatedRoute.firstChild);
        } else {
          return activatedRoute;
        }
     
      }




  


}