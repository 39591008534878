<section class="page_title cs s-pt-60 s-pb-10 s-pt-lg-100 s-pb-lg-30 page_title text-center">
  <div class="container-fluid">
     <div class="row">
        <div class="divider-60"></div>
        <div class="col-md-12 text-center">
           <h1>Pre-made video templates</h1>
        </div>
        <div class="divider-50"></div>
     </div>
  </div>
  <ol class="breadcrumb full-width">
     <li class="breadcrumb-item">
        <a href="">Home</a>
     </li>
     <li class="breadcrumb-item">
        <a href="javascript:void(0)">Solutions</a>
     </li>
     <li class="breadcrumb-item active">
        Pre-made video templates
     </li>
  </ol>
</section>

<section class="ls s-pt-75 s-pb-80 s-pb-lg-100 about-section text-center text-lg-left">
  <div class="d-none d-lg-block divider-10"></div>
  <div class="container">
     <!--<h4 class="top-title text-center">CREATE AWESOME VIDEOS IN SECONDS</h4>-->
     <div class="row align-items-center">
        <!--<div class="col-12 col-lg-12 ">-->
        <div class="col-12 col-lg-6 order-1 order-lg-1">
           <div class="d-none d-lg-block divider-20"></div>
           <div class="item-content">
              <h4 class="top-title text-center">CREATE AWESOME VIDEOS IN SECONDS</h4>
              <p class="big">With Selfanimate slide-show video maker, you can instantly create professional-looking videos that engage and influence your audience. Use our pre-made video templates to promote a sale, explain product features, showcase new catalogues, onboard employees, make effective presentations, and reveal company logos in an exciting way.</p>
              <div class="divider-20"></div>
              <div style="padding-left: 15px"><button id="rad" h="" name="rad" class="btn btn-maincolor" ><span><a routerLink="/contact.html">Get Started</a></span>
                 </button>
              </div>
              <div class="divider-20"></div>
           </div>
        </div>
        <div class="col-12 col-lg-6 order-2 order-lg-2">
           <div class="pmvtimagehide">
              <img src="../../../../assets/frontend/images/pre-made-video-templates/pre-made-video-templates.webp" alt="Pre-made Video Templates"/>
           </div>
           <div id="pmvtcontainer">
              <ul id="pmvtcontinents1">
                 <li class="pmvt-1">
                    <a href="javascript:void(0)">
                    <span class="pmvtimage1">
                    <img src="../../../../assets/frontend/images/pre-made-video-templates/pmvt-f-1.webp">
                    </span>
                    </a>
                 </li>
                 <li class="pmvt-2">
                    <a href="javascript:void(0)">
                    <span class="pmvtimage2">
                    <img class="img-responsive" src="../../../../assets/frontend/images/pre-made-video-templates/pmvt-f-2.webp">
                    </span>
                    <span class="pmvtimage2b">
                    <img class="img-responsive" src="../../../../assets/frontend/images/pre-made-video-templates/pmvt-f-2-b.png">
                    </span>
                    </a>
                 </li>
                 <li class="pmvt-3">
                    <a href="javascript:void(0)">
                    <span class="pmvtimage3">
                    <img src="../../../../assets/frontend/images/pre-made-video-templates/pmvt-f-3.webp" class="img-responsive" >
                    </span>
                    </a>
                 </li>
                 <li class="pmvt-4">
                    <a href="javascript:void(0)">
                    <span class="pmvtimage4">
                    <img src="../../../../assets/frontend/images/pre-made-video-templates/pmvt-f-4.webp" class="img-responsive" >
                    </span>
                    </a>
                 </li>
                 <li class="pmvt-5">
                    <a href="javascript:void(0)">
                    <span class="pmvtimage5">
                    <img src="../../../../assets/frontend/images/pre-made-video-templates/pmvt-f-5.webp" class="img-responsive" >
                    </span>
                    </a>
                 </li>
              </ul>
           </div>
        </div>
     </div>
  </div>
</section>
<section class="ls s-pb-50 s-pb-lg-60 s-pb-xl-60 c-mb-30 service-page2">
  <div class="container">
     <div class="row">
        <div class="col-md-4 col-12">
           <div class="hovereffect">
              <img class="img-responsive" src="../../../../assets/frontend/images/pre-made-video-templates/use-pre-made-business-video-templates.webp" alt=">Use pre-made business video templates">
              <div class="overlay">
                 <h2>USE PRE-MADE BUSINESS VIDEO TEMPLATES</h2>
                 <a class="info" href="javascript:void(0)">Select from our wide range of pre-made video templates that suit your specific requirements. Save time and money.</a>
              </div>
           </div>
        </div>
        <!-- .col-* -->
        <div class="col-md-4 col-12">
           <div class="hovereffect">
              <img class="img-responsive" src="../../../../assets/frontend/images/pre-made-video-templates/exponentially-increase-sales-conversions.webp" alt="Exponentially increase sales conversions">
              <div class="overlay">
                 <h2>EXPONENTIALLY INCREASE SALES CONVERSIONS</h2>
                 <a class="info" href="javascript:void(0)">Use the power of video-based communication to improve sales conversion and ensure customer loyalty.</a>
              </div>
           </div>
        </div>
        <!-- .col-* -->
        <div class="col-md-4 col-12">
           <div class="hovereffect">
              <img class="img-responsive" src="../../../../assets/frontend/images/pre-made-video-templates/go-digital.webp" alt="Go Digital">
              <div class="overlay">
                 <h2>GO DIGITAL </h2>
                 <a class="info" href="javascript:void(0)">Schedule and share custom videos to each customer or send them in bulk on email, or using other social media platforms.</a>
              </div>
           </div>
        </div>
        <!-- .col-* -->
     </div>
  </div>
</section>
<!-- Benefits -->
<section class="ls s-pt-50 s-pb-20 s-pt-lg-120 s-pb-lg-20 s-pt-xl-50 c-mb-30 c-mb-lg-50 ls tab">
  <div class="divider-10"></div>
  <div class="container">
     <h4 class="top-title text-center">NO SOFTWARE TO INSTALL. NO ADDITIONAL EDITING SKILLS NEEDED</h4>
     <p class="text-center">Selfanimate makes business video creation affordable and fun while saving you precious time and effort. Create your own branded product or services videos that connect at a human level.</p>
     <div class="divider-30"></div>
     <div class="row">
        <div class="col-12">
           <div class="row">
              <div class="col-lg-12">
                 <!-- tabs start -->
                 <ul class="nav nav-tabs" role="tablist" >
                    <li class="nav-item">
                       <a class="nav-link active" id="tab04" data-toggle="tab" href="#tab04_pane" role="tab" aria-controls="tab04_pane" aria-expanded="true">Pay-as-you-go video platform</a>
                    </li>
                    <li class="nav-item">
                       <a class="nav-link" id="tab05" data-toggle="tab" href="#tab05_pane" role="tab" aria-controls="tab05_pane">Give your promotions a turbo boost</a>
                    </li>
                    <li class="nav-item">
                       <a class="nav-link" id="tab06" data-toggle="tab" href="#tab06_pane" role="tab" aria-controls="tab06_pane">Cross-sell and up-sell seamlessly</a>
                    </li>
                 </ul>
                 <div class="tab-content tab-media" style="background-color: #fff">
                    <div class="tab-pane fade show active" id="tab04_pane" role="tabpanel" aria-labelledby="tab04">
                       <div class="media">
                          <img src="../../../../assets/frontend/images/pre-made-video-templates/pay-as-you-go-video-platform.webp" alt="Pay-as-you-go video platform" class="solu-noimg">
                          <div class="media-body">
                             <ul class="list-icon list-icon-check list-icon-colored">
                                <li>
                                   Manage your customer engagement with tailor-made video content
                                </li>
                                <li>
                                   Use any pre-made template from our library of templates to develop individualized videos - no software, no special editing skills required
                                </li>
                                <li>
                                   Deliver individualized videos one to one at your convenience
                                </li>
                             </ul>
                          </div>
                       </div>
                    </div>
                    <div class="tab-pane fade" id="tab05_pane" role="tabpanel" aria-labelledby="tab05">
                       <div class="media">
                          <div class="media-body">
                             <ul class="list-icon list-icon-check list-icon-colored">
                                <li>
                                   Drive overall campaign effectiveness by creating wow video moments
                                </li>
                                <li>
                                   Create 1-to-1 positive experiences, without losing the ability to scale at speed
                                </li>
                                <li>
                                   Unlock more value out of the campaign - branding, conversion or monetization
                                </li>
                             </ul>
                          </div>
                          <img src="../../../../assets/frontend/images/pre-made-video-templates/give-your-promotions-a-turbo-boost.webp" alt="Give Your Promotions a Turbo Boost" class="solu-noimg">
                       </div>
                    </div>
                    <div class="tab-pane fade" id="tab06_pane" role="tabpanel" aria-labelledby="tab06">
                       <div class="media">
                          <img src="../../../../assets/frontend/images/pre-made-video-templates/cross-sell-and-up-sell-seamlessly.webp" alt="Cross-Sell and Up-Sell Seamlessly" class="solu-noimg">
                          <div class="media-body">
                             <ul class="list-icon list-icon-check list-icon-colored">
                                <li>
                                   Leverage customer insights to add more value to each relationship
                                </li>
                                <li>
                                   Customize video content for selling horizontally and vertically - with contextual insights
                                </li>
                                <li>
                                   Increase customer retention by targeting them at the right time
                                </li>
                             </ul>
                          </div>
                       </div>
                    </div>
                 </div>
                 <!-- tabs end-->
              </div>
           </div>
        </div>
     </div>
  </div>
</section>
<!-- Benefits -->
<!-- Right Template -->
<section class="ls s-py-80 s-py-lg-120 s-py-xl-80">
  <div class="container">
     <div class="row">
        <h4 class="top-title text-center">PICK THE RIGHT TEMPLATE TO GET ACROSS YOUR BRAND MESSAGE</h4>
        <p class="text-center">Grab the power of pre-made video templates by adding music, photos, fonts, and create visually-stunning, awesome business videos in seconds.</p>
        <div class="divider-20"></div>
        <div class="col-lg-12">
           <div class="row c-gutter-60 isotope-wrapper masonry-layout c-mb-30  ">
              <div class="col-xl-4 col-sm-6 pre-made">
                 <div class="text-center  gallery-item">
                    <div class="item-media">
                       <img src="../../../../assets/frontend/images/video-template/product-showcase-template.webp">
                       <div class="media-links">
                          <a class="abs-link" title="" target="_blank" rel="sponsored nofollow" href="https://www.selfanimate.com/video/product_showcase_10213"></a>
                       </div>
                       <span class="full-absolute-floater">
                       <img src="../../../../assets/frontend/images/video-template/thumbnail-arrow-svg.svg" alt="" class="play-icon">
                       </span>
                    </div>
                    <div class="item-content1">
                       <h5 class="video-title">
                          Product Showcase Template
                       </h5>
                       <p>Most customers want to see quick videos of your products before they click on the link and go to your website. With our Product Showcase template, you can drive more traffic to your product pages with ease.</p>
                    </div>
                    <div class="divider-20"></div>
                    <a href="https://www.selfanimate.com/video/product_showcase_10213" rel="sponsored nofollow" target="_blank" class="btn btn-outline-maincolor"><span>Use Now</span></a>
                 </div>
              </div>
              <div class="col-xl-4 col-sm-6 pre-made1">
                 <div class="text-center gallery-item">
                    <div class="item-media">
                       <img src="../../../../assets/frontend/images/video-template/3D-logo-reveal-template.webp" alt="">
                       <div class="media-links">
                          <a class="abs-link" target="_blank"  title="" rel="sponsored nofollow" href="https://www.selfanimate.com/video/3d_logo_reveal_10225"></a>
                       </div>
                       <span class="full-absolute-floater">
                       <img src="../../../../assets/frontend/images/video-template/thumbnail-arrow-svg.svg" alt="" class="play-icon">
                       </span>
                    </div>
                    <div class="item-content1">
                       <h5 class="video-title">
                          3D Logo Reveal Template
                       </h5>
                       <p>Give your videos a dramatic feel with spectacular 3D logo animation. This catchy 3D Logo Reveal template is a great way to dazzle the audience with eye-popping animation. It is a perfect fit for product trailers and YouTube intros.</p>
                    </div>
                    <div class="divider-20"></div>
                    <a href="https://www.selfanimate.com/video/3d_logo_reveal_10225" rel="sponsored nofollow" target="_blank" class="btn btn-outline-maincolor"><span>Use Now</span></a>
                 </div>
              </div>
              <div class="col-xl-4 col-sm-6 pre-made">
                 <div class="text-center gallery-item">
                    <div class="item-media">
                       <img src="../../../../assets/frontend/images/video-template/powerful-motivation.webp" alt="Powerful Motivation Video">
                       <div class="media-links">
                          <a class="abs-link" target="_blank"  title="" rel="sponsored nofollow" href="https://www.selfanimate.com/video/powerful_motivational_video_4_10278"></a>
                       </div>
                       <span class="full-absolute-floater">
                       <img src="../../../../assets/frontend/images/video-template/thumbnail-arrow-svg.svg" alt="" class="play-icon">
                       </span>
                    </div>
                    <div class="item-content1">
                       <h5 class="video-title">
                          Powerful Motivation Video
                       </h5>
                       <p>When you motivate your audience, they feel extra engaged to your business presentation or sales pitch. Unleash that power by using our Powerful Motivation Video templates to make a stirring impression.</p>
                    </div>
                    <div class="divider-20"></div>
                    <a href="https://www.selfanimate.com/video/powerful_motivational_video_4_10278" rel="sponsored nofollow" target="_blank" class="btn btn-outline-maincolor"><span>Use Now</span></a>
                 </div>
              </div>
           </div>
           <div class="row c-gutter-60 isotope-wrapper masonry-layout c-mb-30  ">
              <div class="col-xl-4 col-sm-6 pre-made">
                 <div class="text-center gallery-item">
                    <div class="item-media">
                       <img src="../../../../assets/frontend/images/video-template/summer-sale.webp" alt="Summer Sale Discount Template">
                       <div class="media-links">
                          <a class="abs-link" target="_blank"  title="" rel="sponsored nofollow" href="https://www.selfanimate.com/video/summer_sale_discount_10291"></a>
                       </div>
                       <span class="full-absolute-floater">
                       <img src="../../../../assets/frontend/images/video-template/thumbnail-arrow-svg.svg" alt="" class="play-icon">
                       </span>
                    </div>
                    <div class="item-content1">
                       <h5 class="video-title">
                          Summer Sale Discount Template
                       </h5>
                       <p>With summer approaching, get ready to offer great discounts on products or services! With our Summer Sale Discount template, you can make those discounts look attractive and visually engaging.</p>
                    </div>
                    <div class="divider-20"></div>
                    <a  href="https://www.selfanimate.com/video/summer_sale_discount_10291" rel="sponsored nofollow" target="_blank"  class="btn btn-outline-maincolor"><span>Use Now</span></a>
                 </div>
              </div>
              <div class="col-xl-4 col-sm-6 pre-made1">
                 <div class="text-center gallery-item">
                    <div class="item-media">
                       <img src="../../../../assets/frontend/images/video-template/thank-you-note-template.webp" alt="Thank You Note Template">
                       <div class="media-links">
                          <a class="abs-link" target="_blank"  title="" rel="sponsored nofollow" href="https://www.selfanimate.com/video/thank_you_note_10261"></a>
                       </div>
                       <span class="full-absolute-floater">
                       <img src="../../../../assets/frontend/images/video-template/thumbnail-arrow-svg.svg" alt="" class="play-icon">
                       </span>
                    </div>
                    <div class="item-content1">
                       <h5 class="video-title">
                          Thank You Note Template
                       </h5>
                       <p>Great business always starts and ends with gratitude. What better way to thank your customers, employees, colleagues, or even your boss than by leaving them with a heart-warming message. Use our Thank You Note template and win those hearts!</p>
                    </div>
                    <div class="divider-20"></div>
                    <a href="https://www.selfanimate.com/video/thank_you_note_10261" rel="sponsored nofollow" target="_blank"   class="btn btn-outline-maincolor"><span>Use Now</span></a>
                 </div>
              </div>
              <div class="col-xl-4 col-sm-6 pre-made">
                 <div class="text-center gallery-item">
                    <div class="item-media">
                       <img src="../../../../assets/frontend/images/video-template/employee-birthday-wishes-template.webp" alt="Employee Birthday Wishes Template">
                       <div class="media-links">
                          <a class="abs-link" target="_blank"  title="" rel="sponsored nofollow" href="https://www.selfanimate.com/video/employee_birthday_wishes_10236"></a>
                       </div>
                       <span class="full-absolute-floater">
                       <img src="../../../../assets/frontend/images/video-template/thumbnail-arrow-svg.svg" alt="" class="play-icon">
                       </span>
                    </div>
                    <div class="item-content1">
                       <h5 class="video-title">
                          Employee Birthday Wishes Template
                       </h5>
                       <p>Your employees are your real assets. Share the joy of an employee’s special day and show you care with a birthday wishes video that is personal, fun, and memorable with our Employee Birthday Wishes template.</p>
                    </div>
                    <div class="divider-20"></div>
                    <a href="https://www.selfanimate.com/video/employee_birthday_wishes_10236" rel="sponsored nofollow" target="_blank"  class="btn btn-outline-maincolor"><span>Use Now</span></a>
                 </div>
              </div>
           </div>
           <div class="row c-gutter-60 isotope-wrapper masonry-layout c-mb-30  ">
              <div class="col-xl-4 col-sm-6 pre-made pre-made">
                 <div class="text-center gallery-item">
                    <div class="item-media">
                       <img src="../../../../assets/frontend/images/video-template/employee-onboarding-video.webp" alt="Employee Onboarding Video Template">
                       <div class="media-links">
                          <a class="abs-link" target="_blank"  title="" rel="sponsored nofollow" href="https://www.selfanimate.com/video/employee_onboarding_video_template_10235"></a>
                       </div>
                       <span class="full-absolute-floater">
                       <img src="../../../../assets/frontend/images/video-template/thumbnail-arrow-svg.svg" alt="" class="play-icon">
                       </span>
                    </div>
                    <div class="item-content1">
                       <h5 class="video-title">
                          Employee Onboarding Video Template
                       </h5>
                       <p>Your employee’s first few days with your company can set the tone for the rest of his/her tenure. With our Employee Onboarding Video template, you can create a fun and meaningful way of welcoming them into your business.</p>
                    </div>
                    <div class="divider-20"></div>
                    <a href="https://www.selfanimate.com/video/employee_onboarding_video_template_10235" rel="sponsored nofollow" target="_blank"  class="btn btn-outline-maincolor"><span>Use Now</span></a>
                 </div>
              </div>
              <div class="col-xl-4 col-sm-6 pre-made1">
                 <div class="text-center gallery-item">
                    <div class="item-media">
                       <img src="../../../../assets/frontend/images/video-template/logo-on-roof-reveal-template.webp" alt="Logo On Roof Reveal Template">
                       <div class="media-links">
                          <a class="abs-link" target="_blank"  title="" rel="sponsored nofollow" href="https://www.selfanimate.com/video/logo_on_rooftop_10112"></a>
                       </div>
                       <span class="full-absolute-floater">
                       <img src="../../../../assets/frontend/images/video-template/thumbnail-arrow-svg.svg" alt="" class="play-icon">
                       </span>
                    </div>
                    <div class="item-content1">
                       <h5 class="video-title">
                          Logo On Roof Reveal Template
                       </h5>
                       <p>Use the Logo on Roof Reveal template to showcase your brand logo in a cinematic and stylish way. It is a creative way of impressing the viewer with an unforgettably dramatic brand image experience. It is a perfect fit for product trailers and YouTube intros.</p>
                    </div>
                    <div class="divider-20"></div>
                    <a href="https://www.selfanimate.com/video/logo_on_rooftop_10112" rel="sponsored nofollow" target="_blank"  class="btn btn-outline-maincolor"><span>Use Now</span></a>
                 </div>
              </div>
              <div class="col-xl-4 col-sm-6 pre-made">
                 <div class="text-center gallery-item">
                    <div class="item-media">
                       <img src="../../../../assets/frontend/images/video-template/history-slideshow-montage.webp" alt="History Slideshow Montage Template">
                       <div class="media-links">
                          <a class="abs-link"  target="_blank" title="" rel="sponsored nofollow" href="https://www.selfanimate.com/video/history_slideshow_montage_10270"></a>
                       </div>
                       <span class="full-absolute-floater">
                       <img src="../../../../assets/frontend/images/video-template/thumbnail-arrow-svg.svg" alt="" class="play-icon">
                       </span>
                    </div>
                    <div class="item-content1">
                       <h5 class="video-title">
                          History Slideshow Montage Template
                       </h5>
                       <p>Taking inspiration from history is an engaging way to get your audience’s attention. Whether you are pitching to your customers or presenting to your employees, use our History Slideshow Montage template to recreate the past.</p>
                    </div>
                    <div class="divider-20"></div>
                    <a href="https://www.selfanimate.com/video/history_slideshow_montage_10270" rel="sponsored nofollow" target="_blank"  class="btn btn-outline-maincolor"><span>Use Now</span></a>
                 </div>
              </div>
           </div>
           <div class="row c-gutter-60 isotope-wrapper masonry-layout c-mb-30  ">
              <div class="col-xl-4 col-sm-6 pre-made">
                 <div class="text-center gallery-item">
                    <div class="item-media">
                       <img src="../../../../assets/frontend/images/video-template/thriller-and-suspense-logo.webp" alt="Thriller And Suspense Logo Reveal">
                       <div class="media-links">
                          <a class="abs-link" target="_blank" title="" rel="sponsored nofollow" href="https://www.selfanimate.com/video/thriller_and_suspense_logo_reveal_10117"></a>
                       </div>
                       <span class="full-absolute-floater">
                       <img src="../../../../assets/frontend/images/video-template/thumbnail-arrow-svg.svg" alt="" class="play-icon">
                       </span>
                    </div>
                    <div class="item-content1">
                       <h5 class="video-title">
                          Thriller And Suspense Logo Reveal 
                       </h5>
                       <p>A little thrill and suspense can always go a long way to build the right hype for your business. Use our Thriller and Suspense Logo Reveal template to add creativity to showcase your brand identity.</p>
                    </div>
                    <div class="divider-20"></div>
                    <a href="https://www.selfanimate.com/video/thriller_and_suspense_logo_reveal_10117" rel="sponsored nofollow" target="_blank"  class="btn btn-outline-maincolor"><span>Use Now</span></a>
                 </div>
              </div>
              <div class="col-xl-4 col-sm-6 pre-made1">
                 <div class="text-center gallery-item">
                    <div class="item-media">
                       <img src="../../../../assets/frontend/images/video-template/waterfall-logo-reveal-template.webp" alt="Waterfall Logo Reveal Template">
                       <div class="media-links">
                          <a class="abs-link" target="_blank"  title="" rel="sponsored nofollow" href="https://www.selfanimate.com/video/waterfall_logo_reveal_10116"></a>
                       </div>
                       <span class="full-absolute-floater">
                       <img src="../../../../assets/frontend/images/video-template/thumbnail-arrow-svg.svg" alt="" class="play-icon">
                       </span>
                    </div>
                    <div class="item-content1">
                       <h5 class="video-title">
                          Waterfall Logo Reveal Template
                       </h5>
                       <p>Invoke the expressiveness of waterfalls through this eye-soothing animated Waterfall Logo Reveal template and give your audience a smooth viewing experience. Get your brand to stand out by introducing it with suave and style.</p>
                    </div>
                    <div class="divider-20"></div>
                    <a href="https://www.selfanimate.com/video/waterfall_logo_reveal_10116" rel="sponsored nofollow" target="_blank"  class="btn btn-outline-maincolor"><span>Use Now</span></a>
                 </div>
              </div>
              <div class="col-xl-4 col-sm-6 pre-made">
                 <div class="text-center gallery-item">
                    <div class="item-media">
                       <img src="../../../../assets/frontend/images/video-template/digital-grid-logo-reveal-template.webp" alt="Digital Grid Logo Reveal Template">
                       <div class="media-links">
                          <a class="abs-link" target="_blank"  title="" rel="sponsored nofollow" href="https://www.selfanimate.com/video/digital_grid_logo_reveal_10115"></a>
                       </div>
                       <span class="full-absolute-floater">
                       <img src="../../../../assets/frontend/images/video-template/thumbnail-arrow-svg.svg" alt="" class="play-icon">
                       </span>
                    </div>
                    <div class="item-content1">
                       <h5 class="video-title">
                          Digital Grid Logo Reveal Template
                       </h5>
                       <p>Make an astonishing intro with a powerful and energetic Digital Grid Logo Reveal Template that brings the power of digital through a creative eye. It is a perfect fit for product trailers and YouTube intros.</p>
                    </div>
                    <div class="divider-20"></div>
                    <a href="https://www.selfanimate.com/video/digital_grid_logo_reveal_10115" rel="sponsored nofollow" target="_blank"  class="btn btn-outline-maincolor"><span>Use Now</span></a>
                 </div>
              </div>
           </div>
           <!-- .isotope-wrapper-->
        </div>
     </div>
  </div>
</section>
<!-- ---->
<section class="s-py-40 s-py-lg-40 s-py-xl-40 ls ms">
  <div class="container">
     <h4 class="top-title text-center">NO ANNUAL PLANS TO LOCK YOU IN</h4>
     <p class="text-center">Use our pay-as-you-go pricing model to use specific pre-made video templates suiting your specific video needs.</p>
     <div class="divider-25 d-none d-lg-block"></div>
     <div class="row c-gutter-60">
        <div class="col-lg-6 col-12">
           <div class="pricing-plan hero-bg rounded price-">
              <div class="plan-name  bg-maincolor">
                 <h3>
                    FREE TIER
                 </h3>
              </div>
              <div class="divider-25"></div>
              <span><a href="https://www.selfanimate.com/?id=reg" target="_blank" >Register</a></span> and get <br>
              <p><b>1 free credit </b><br>
                 to create your first video absolutely free
              </p>
              <div class="divider-20"></div>
           </div>
           <div class="divider-45 d-block d-lg-none"></div>
        </div>
        <div class="col-lg-6 col-12">
           <div class="pricing-plan hero-bg rounded">
              <div class="plan-name bg-maincolor">
                 <h3>
                    PAY AS YOU GO
                 </h3>
              </div>
              <div class="divider-25"></div>
              <div class="row">
                 <div class="col-6 ">5 Credits= $ <del>5</del> 4</div>
                 <div class="col-6">10 Credits= $ <del>10</del> 8</div>
              </div>
              <div class="row">
                 <div class="col-6">20 Credits= $ <del>20</del> 15</div>
                 <div class="col-6">30 Credits= $ <del>30</del> 24</div>
              </div>
              <div class="row">
                 <div class="col-6">50 Credits= $ <del>50</del> 38</div>
              </div>
           </div>
        </div>
        <div class="divider-20 d-block d-md-none"></div>
     </div>
  </div>
  <div class="divider-25"></div>
</section>
<!-- Personalized Videos -->
<section class="s-pt-80 s-pb-0 s-pt-lg-160 s-pb-lg-60 s-pt-lg-160 s-pb-lg-60 c-gutter-60  ls pv">
  <div class="container">
     <div class="row">
        <div class="col-lg-8">
           <h4 class="top-title pvt">CREATE ON-BRAND VIDEOS USING PRE-MADE VIDEO TEMPLATES.</h4>
           <!--<p>Create on-brand, professional quality video at scale using GoCampaign pre-made video templates.</p>-->
           <p>Let's talk on the next steps!</p>
        </div>
        <div class="col-lg-3">
           <div class="d-block d-lg-none divider-30"></div>
           <div class="row" style="padding-top: 5px">
              <div class="col-sm-8 gcv-but">
                 <button  id="rad1" h name="rad" class="btn btn-maincolor"><span><a routerLink="/request-a-demo.html">Request a Demo</a></span>
                 </button>
              </div>
              <div class="col-sm-4 gcv-but">
                 <button id="contact-me" name="contact-me" class="btn btn-maincolor"><span><a routerLink="/contact.html">Contact Me</a></span>
                 </button>
              </div>
           </div>
        </div>
     </div>
  </div>
</section>
<!-- Personalized Videos -->