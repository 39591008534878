<section class="page_title cs s-pt-60 s-pb-10 s-pt-lg-100 s-pb-lg-30 text-center">
  <div class="container-fluid">
     <div class="row">
        <div class="divider-60"></div>
        <div class="col-md-12 text-center">
           <h1>Cookies Notice</h1>
        </div>
        <div class="divider-50"></div>
     </div>
  </div>
  <ol class="breadcrumb full-width">
     <li class="breadcrumb-item">
        <a href="">Home</a>
     </li>
     <li class="breadcrumb-item active">
        Cookies Notice
     </li>
  </ol>
</section>

<section class="ls s-pb-20 s-pb-md-60 s-pb-lg s-pb-xl-100 s-pt-75 c-mb-60 c-mb-md-0">
  <div class="container">
     <div class="row">
        <div class="col-sm-6" data-animation="scaleAppear">
           <div class="divider-60 d-none d-md-block"></div>
           <h5>About cookies and similar technologies</h5>
           <p> "GoCampaign or GoCampaignVideos (referred to in this Notice as “we” or “us”), uses cookies and similar technologies (together "cookies"). Cookies are small data files uploaded on your device when you visit a website or mobile app. Cookies allow a website or a mobile app to collect and store a range of data on your devices. Some cookies are essential, while others allow us to enhance your experience, personalise content and adverts across your devices, and provide insights into how our website and mobile apps are being used.
           </p>
           <p>
              We use Session Cookies which will automatically expire at the end of your browser session on our website and mobile apps. These are generally used for security purposes or to facilitate your use of our website. For example, we use session cookies to analyse the traffic on our website but also to remember the content of your online shopping basket.
              We may also use Persistent Cookies which will be stored for a longer period depending on the nature of their purpose. For example, persistent cookies are used to remember your preferences and choices when you use our website or for targeted advertising purposes.
           </p>
           <h5>Essential cookies</h5>
           <p>Essential cookies enable core functionality such as the online shopping basket. Our website cannot function properly without these cookies. Therefore, we don’t request your permission to use essential cookies.</p>
           <p>We also use essential cookies for security, network management, accessibility and to help webpage to load quickly. They help us to keep track of what you are booking as you move through each stage of the booking process. Without them the online booking process cannot take place. To learn more about the essential cookies we use on our website, please email <a href="mailto:info@gocampaignvideos.com"><font color="#000">info@gocampaignvideos.com</font></a>. </p>
           <h5>Non-essential cookies</h5>
           <p>Non-essential cookies help us make your experience better, improve the performance of our website and deliver relevant online, customer tailored advertising. They won’t be placed on your device or collect any of your information unless you give your permission to do so. Some non-essential cookies are placed by us and some by third parties.</p>
           <p>Personalisation Cookies to improve the way our website and mobile app work and to provide you with enhanced, more personal features.
           <p>These cookies improve the way our website work and provide you with enhanced, more personal features. For example, they collect data on how you use our website and help us assess and improve the way it works. They also help us to remember your preferences, recent searches, and shortlisted holidays, to make it easy for you to return to the previous search. To learn more about the non-essential cookies we use on our website, please email <a href="mailto:info@gocampaignvideos.com"><font color="#000">info@gocampaignvideos.com</font></a>. </p>
           <h5>Targeting & Advertising Cookies</h5>
           <p>Targeting & Advertising Cookies deliver relevant online advertising and measures the effectiveness of our marketing communications, including online advertising</p>
           <p>These cookies help us to deliver relevant online advertising and to measure the effectiveness of our marketing communications. They collect data about your online behaviour, IP address, the website you arrived from, information about your purchase history or the content of your shopping basket, so that we can try to predict what other products, services, and information you might be most interested in. They also tell us if you have seen a specific advert, how long it has been since you saw it and control the number of times you are shown an advert. They also tell us if you have opened a marketing email that we sent you.</p>
        </div>
        <div class="col-sm-6" data-animation="scaleAppear">
           <div class="divider-60 d-none d-md-block"></div>
           <p>By not accepting these cookies, you may continue to see adverts on our website, and on other organisations’ websites. The adverts you see will not be personalised or based on information from your past online behaviour, purchase history or the content of your shopping basket. To learn more about the Targeting & Advertising Cookies we use on our website, please email <a href="mailto:info@gocampaignvideos.com"><font color="#000">info@gocampaignvideos.com</font></a>.</p>
           <h5>Your choices when it comes to cookies</h5>
           <p>You can manage non-essential cookies and change your preferences at any time in Manage Cookies feature in the website. You can adjust the available sliders to ‘On’ or ‘Off’, then click ‘Save and Close’. You may need to refresh your page for your settings to take effect.
           <p>Alternatively, you can use your browser settings to accept or reject new cookies and to delete existing persistent cookies. You can also set your browser to notify you each time new cookies are placed on your device. You can find more detailed information about how you can manage cookies at the All About Cookies website.
           <p>You can find out how to manage cookies on popular browsers:
           <ul>
              <li>Google Chrome</li>
              <li>Microsoft Edge</li>
              <li>Mozilla Firefox</li>
              <li>Microsoft Internet Explorer</li>
              <li>Opera</li>
              <li>Apple Safari</li>
           </ul>
           <p>If you choose to disable some or all cookies, you may not be able to make full use of our website as it may disable some of our essential cookies. 
           <p>Google Analytics
           <p>Google Analytics helps us to understand how you interact with our website by collecting and reporting information anonymously. We use Google Analytics to help us analyse the information collected. It also reports website trends without identifying individual visitors. You can opt out of Google Analytics without affecting how you visit our website – for more information on opting out of being tracked by Google Analytics across all websites you use, visit:  <a href="http://tools.google.com/dlpage/gaoptout. ">http://tools.google.com/dlpage/gaoptout. </a>
           <h5>Changes to our Notice</h5>
           <p>This Cookie Notice replaces all previous versions. We may change the Cookie Notice at any time so please check it regularly on our website(s) for any updates. If the changes are significant, we will provide a prominent notice on our website(s) including, if we believe it is appropriate, electronic notification of Cookie Notice changes.</p>
           <p>Last update: April 2021</p>
        </div>
     </div>
  </div>
</section>