import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-playvideo',
  templateUrl: './playvideo.component.html',
  styleUrls: ['./playvideo.component.styl']
})
export class PlayvideoComponent implements OnInit {
  url: string;
  className:string;

  constructor() { }

  ngOnInit() {

      if(localStorage.getItem('_url')){
        let url = localStorage.getItem('_url');
        this.url=url;
      }

      if(localStorage.getItem('_type')==='cruiseline'){
        this.className='portrait';
      }else{
        this.className='landscape';
      }

      localStorage.clear();
      

  }
}
