import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import {MatDialog} from '@angular/material';
import { PlayvideoComponent } from '../landing/playvideo/playvideo.component';  //IMPORT CAMPAIGN RECORD COMPONENT
import { ToastrManager } from 'ng6-toastr-notifications';
import { FrontendService } from 'src/app/_services';

@Component({
  selector: 'app-cruiseline',
  templateUrl: './cruiseline.component.html',
  styleUrls: ['./cruiseline.component.styl']
})
export class CruiselineComponent implements OnInit {

  PerVideoForm: FormGroup;
  presubmitted: boolean;
  preloading: boolean;
  name: string;

  constructor( 
    private frontendService:FrontendService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public toastr: ToastrManager,) { }

  ngOnInit() {
    //SET META TAGE START
    this.frontendService.setCurrentPage({currPage: window.location.pathname});
      //VALIDATE FORM
      this.PerVideoForm = this.formBuilder.group({
        name: ['',  [Validators.required, Validators.maxLength(20)]]
    });


  }


  get b() 
  { 
    return this.PerVideoForm.controls; 
  }


  /**
   * Play Demo Video
   */


  playDemoVideo(fileNo: any){
     console.log('fileName',fileNo);
     //DETECT DEVICE
     var ua = navigator.userAgent;
     if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)){
        var device='mobile';
     }else if(/Chrome/i.test(ua)){
        var device='desktop';
     }else{
        var device='desktop';
     }

     const files={1:'involve_me',2:'hear_me',3:'enable_me',4:'dazzle_me',5:'remember_me'}

     localStorage.setItem('_type','cruiseline');
     localStorage.setItem('_url','https://gocampaignvideos.com/assets/frontend/videos/cruiseline/'+files[fileNo]+'.mp4');

     if(device=='mobile'){
      var device_width='80%';
      var device_height='90%';
    }else{
      var device_width='40%';
      var device_height='90%';
    }



    //OPEN DIALOG BOX
    let dialogRef = this.dialog.open(PlayvideoComponent, {
        height: device_height,
        width: device_width,
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });



  }

    /**
   * SUBMIT CREATE MY VIDEO FORM
   */


    submit_personalization_video_form(){

      this.presubmitted = true;
      // stop here if form is invalid
      if (this.PerVideoForm.invalid) {
          return;
      }
      this.preloading = true;
      var data =this.PerVideoForm.value;
  
          //DETECT DEVICE
          var ua = navigator.userAgent;
          if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)){
             var device='mobile';
          }else if(/Chrome/i.test(ua)){
             var device='desktop';
          }else{
             var device='desktop';
          }

      localStorage.setItem('_type','cruiseline');

      this.frontendService.personalised_video_request(data)
      .pipe(first())
      .subscribe(
          data => {
             const myObjStr = JSON.stringify(data);
             console.log(myObjStr);
             var object=JSON.parse(myObjStr);
             var message: string=object.message; 
             var url: string=object.url; // GET VIDEO URL
  
             if(message=='unsuccess'){
                   //Swal.fire('Oops...', 'Something went wrong please try again!', 'error');
                   alert('Oops... Something went wrong please try again!');
                  this.preloading = false;  
             }
             if(message=='success'){ 
                  //RESET ALL INPUT FIELD
                  this.name = '';
                  this.presubmitted = false;
                  this.preloading = false; //LOADING REMOVED
  
                  if(url){
                    localStorage.setItem('_url',url);
                  }
  
                  if(device=='mobile'){
                    var device_width='80%';
                    var device_height='80%';
                  }else{
                    var device_width='40%';
                    var device_height='90%';
                  }
  
                  //OPEN DIALOG BOX
                  let dialogRef = this.dialog.open(PlayvideoComponent, {
                      height: device_height,
                      width: device_width,
                  });
            
                  dialogRef.afterClosed().subscribe(result => {
                    console.log(`Dialog result: ${result}`);
                  });
  
  
             }   
          },
          error => {
              this.preloading = false;
          })
  
    }


}
