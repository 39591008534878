<div class="cruiseline">
  <section class="section section1">
      <div class="container">
          <h1>Create Memorable & unique <br>guest experiences</h1>
      </div>
      <div class="breadcrumbs">
           <ul>
            <li><a href="">Home</a></li>
            <li><a href="javascript:void(0)">Solutions</a></li>
            <li><a href="javascript:void(0)">Personalized Videos - Cruise</a></li>
        </ul>
      </div>
  </section>

  <section class="section section2">
      <div class="container">
          <div class="row">
              <div class="col-md-7">
                  <h2>Personalization is the new basis for cruiselines</h2>
                  <div class="desc"><p>Cruise business is not a Monday to Friday activity. It's a business where you live with your customers 24/7 during the entire voyage and beyond. Therefore it is important for business stakeholders to identify the critical guest touch points where interactions and experiences can be personalized and delivered as email or SMS or as in-App messages.</p><p>By empowering and delighting guests, you can create the emotional connections that will entice them to fall in love with your cruise.</p></div>
                  <div class="row">
                    <div class="col-md-5"> </div>
                     <div class="col-md-5">
                      <div class="submit"><a href="../../../../assets/frontend/pdfs/gocampaign_cruiseline_brochure.pdf" class="btn">Download<br/>Brochure</a></div>
                     </div>
                  </div> 
                  
              </div>
              <div class="col-md-5"><img src="../../../../assets/frontend/images/cruise/hero-img.png" class="img-fluid" /></div>
          </div>
      </div>
  </section>

  <section class="section section3">
      <div class="container">
          <div class="row">
              <div class="col-md-6"></div>
              <div class="col-md-6">
                  <div class="desc">GoCampaignVideos is an automated video personalization product that delivers impactful, hyper-personalized customer interactions using videos. Integrate video personalization in your customer engagement strategy for superior customer experiences.</div>
                  <!-- <div class="card">
                      <div class="card-header">Create a personalised video now!</div>
                      <div class="card-body">
                          <input type="text" placeholder="Enter your name here and experience personalization at work" />
                      </div>
                  </div>
                  <div class="submit"><a href="#" class="btn lvideo">Submit</a></div> -->
              
                  <form [formGroup]="PerVideoForm" (ngSubmit)="submit_personalization_video_form()"> 
                    <div class="card">
                      <div class="card-header">Create a personalised video now!</div>
                      <div class="card-body">
                          <div [formGroup]="PerVideoForm" class="form-group has-placeholder">
                            <input type="text" [(ngModel)]="name" formControlName="name" maxlength="20" class="form-control" class="form-control" [ngClass]="{ 'is-invalid': presubmitted && b.name.errors }" placeholder="Enter your name here and experience personalization at work" >
                            <div *ngIf="submit_personalization_video_form && b.name.errors" class="invalid-feedback" style="text-align: center;">
                              <div *ngIf="b.name.errors.required">Name is required</div>
                            </div>
                            </div>
                      </div>
                  </div>
                  <div [formGroup]="PerVideoForm" class="submit">
                    <button [disabled]="preloading" class="btn lvideo" id="madeVideoBtn"><span>Submit</span></button>
                    <mat-spinner style="margin-left: 17px; margin-top: 4px;" strokeWidth="3" [diameter]="50" *ngIf="preloading"></mat-spinner>
                    
                    <!-- <img *ngIf="preloading" class="pl-3" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" /> -->
                  </div>
                  </form>
              
              
                </div>
          </div>
      </div>
  </section>

  <section class="section section4">
      <div class="container">
          <div class="icon animate animated fadeInUp" data-animation="fadeInUp">
              <img src="../../../../assets/frontend/images/cruise/img1.png" class="img-fluid" />
              <h5>Automated customer interactions</h5>
          </div>
          <div class="icon animate animated fadeInUp" data-animation="fadeInUp">
              <img src="../../../../assets/frontend/images/cruise/img2.png" class="img-fluid" />
              <h5>Elevate responses up to 300%</h5>
          </div>
          <div class="icon animate animated fadeInUp" data-animation="fadeInUp">
              <img src="../../../../assets/frontend/images/cruise/img3.png" class="img-fluid" />
              <h5>Improve customer loyalty</h5>
          </div>
          <div class="icon animate animated fadeInUp" data-animation="fadeInUp">
              <img src="../../../../assets/frontend/images/cruise/img4.png" class="img-fluid" />
              <h5>Transform customer experiences</h5>
          </div>
          <div class="icon animate animated fadeInUp" data-animation="fadeInUp">
              <img src="../../../../assets/frontend/images/cruise/img5.png" class="img-fluid" />
              <h5>Reduce customer support costs</h5>
          </div>
      </div>
  </section>

  <section class="section section5">
    <div class="container">
        <div class="title">Personalize and match guest expectations</div>
        <div class="desc">Establish the all-important human connection with guests by matching their expectations. Create superior guest experiences that will lead to higher loyalty and faster business growth.</div>
        <div class="row podSection">
            <div class="col-md-6 animate animated fadeInUp" data-animation="fadeInUp">
                <div class="pod">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="title">"Involve me"</div>
                            <div class="desc">Demonstrate authenticity and attention by informing the guests on important must-knows using personalized videos.</div>
                            <div class="submit">
                                <div class="demo"><a href="javascript:void(0);" class="btn lvideo" (click)="playDemoVideo(1)">Demo Video</a></div>
                                <div class="qrCodeSection">
                                    <div class="text">Scan for mobile view</div>
                                    <div class="qrCode"><img src="../../../../assets/frontend/images/cruise/involve-me.png" class="img-fluid" /></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 text-center mobileImage">
                            <img src="../../../../assets/frontend/images/cruise/mobile1.png" class="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 animate animated fadeInUp" data-animation="fadeInUp">
                <div class="pod">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="title">"Hear me"</div>
                            <div class="desc">Listen and empathize with the guests and provide information that they can use, with a well-timed personalized video.</div>
                            <div class="submit">
                                <div class="demo"><a href="javascript:void(0);" class="btn lvideo" (click)=playDemoVideo(2)>Demo Video</a></div>
                                <div class="qrCodeSection">
                                    <div class="text">Scan for mobile view</div>
                                    <div class="qrCode"><img src="../../../../assets/frontend/images/cruise/hear-me.png" class="img-fluid" /></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 text-center mobileImage">
                            <img src="../../../../assets/frontend/images/cruise/mobile2.jpeg" class="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-12 animate animated fadeInUp" data-animation="fadeInUp">
                <div class="pod">
                    <div class="row">
                        <div class="col-md-10">
                            <div class="title">"Enable me"</div>
                            <div class="desc">When cruise experience is the product, anticipate customer requirements and provide guests the information they need via customized videos.</div>
                            <div class="submit">
                                <div class="demo"><a href="javascript:void(0);" class="btn lvideo" (click)=playDemoVideo(3)>Demo Video</a></div>
                                <div class="qrCodeSection">
                                    <div class="text">Scan for mobile view</div>
                                    <div class="qrCode"><img src="../../../../assets/frontend/images/cruise/enable-me.png" class="img-fluid" /></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 text-center mobileImage">
                            <img src="../../../../assets/frontend/images/cruise/mobile3.png" class="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 animate animated fadeInUp" data-animation="fadeInUp">
                <div class="pod">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="title">"Dazzle me"</div>
                            <div class="desc">Delight the customer by creating moments of excitement that will exceed their expectations. Create these value drivers at key intersections using personalized videos.</div>
                            <div class="submit">
                                <div class="demo"><a href="javascript:void(0);" class="btn lvideo" (click)=playDemoVideo(4)>Demo Video</a></div>
                                <div class="qrCodeSection">
                                    <div class="text">Scan for mobile view</div>
                                    <div class="qrCode"><img src="../../../../assets/frontend/images/cruise/dazzle-me.png" class="img-fluid" /></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 text-center mobileImage">
                            <img src="../../../../assets/frontend/images/cruise/mobile4.png" class="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 animate animated fadeInUp" data-animation="fadeInUp">
                <div class="pod">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="title">"Remember me"</div>
                            <div class="desc">Use customer information to know guests. Integrate intelligence into personalized videos and establish instant connections with guests</div>
                            <div class="submit">
                                <div class="demo"><a href="javascript:void(0);" class="btn lvideo" (click)=playDemoVideo(5)>Demo Video</a></div>
                                <div class="qrCodeSection">
                                    <div class="text">Scan for mobile view</div>
                                    <div class="qrCode"><img src="../../../../assets/frontend/images/cruise/remember-me.png" class="img-fluid" /></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 text-center mobileImage">
                            <img src="../../../../assets/frontend/images/cruise/mobile5.png" class="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>

  <section class="section section6">
      <div class="container">
          <div class="title">Engage with crew using personalised videos</div>
          <div class="desc"><p>Operational excellence centers around how connected and inspired your workforce is. However, it is not possible for sr. management or departmental leaders to stay engaged and communicate with their crew, at a one-on-one level.</p>
            <p>GoCampaignVideos makes it possible for leaders and business stakeholders to communicate with their crew at an individual level using personalized videos. Create a connected and motivated workforce, retain current staff, and attract new ones with timely, personalized video-powered communications.</p>
          </div>
          <div class="imageSection">
              <div class="icon">
                  <img src="../../../../assets/frontend/images/cruise/pic4.png" class="img-fluid" />
                  <h5>Guest Services</h5>
              </div>
              <div class="icon">
                  <img src="../../../../assets/frontend/images/cruise/pic1.png" class="img-fluid" />
                  <h5>Housekeeping</h5>
              </div>
              <div class="icon">
                  <img src="../../../../assets/frontend/images/cruise/pic6.png" class="img-fluid" />
                  <h5>Maître D'</h5>
              </div>
              <div class="icon">
                  <img src="../../../../assets/frontend/images/cruise/pic7.png" class="img-fluid" />
                  <h5>Gally</h5>
              </div>
              <div class="icon">
                  <img src="../../../../assets/frontend/images/cruise/pic3.png" class="img-fluid" />
                  <h5>Chef</h5>
              </div>
          </div>
      </div>
  </section>

  <section class="section section7">
      <div class="container">
          <div class="row">
              <div class="col-md-6">
                  <div class="title">Provide value at every turn with personalization.<br/>Make cruising more attractive.</div>
                  <div class="desc">Let's talk on the next steps!</div>
              </div>
              <div class="col-md-6">
                  <div class="btnSection">
                      <a routerLink="/request-a-demo.html" class="btn">Request a Demo</a>
                      <a routerLink="/request-a-demo.html" class="btn">Contact Me</a>
                  </div>
              </div>
          </div>
      </div>
  </section>
</div>