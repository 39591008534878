<style >.nav-link{min-width: 200px!important;}</style>
<section class="page_title cs s-pt-60 s-pb-10 s-pt-lg-100 s-pb-lg-30 page_title text-center">
  <div class="container-fluid">
     <div class="row">
        <div class="divider-60"></div>
        <div class="col-md-12 text-center">
           <h1>How it Works</h1>
        </div>
        <div class="divider-50"></div>
     </div>
  </div>
  <ol class="breadcrumb full-width">
     <li class="breadcrumb-item">
        <a href="">Home</a>
     </li>
     <li class="breadcrumb-item active">
        How it Works
     </li>
  </ol>
</section>

<section class="ls s-pt-75 s-pb-80 s-pb-lg-100 about-section text-center text-lg-left">
  <div class="d-none d-lg-block divider-10"></div>
  <div class="container">
     <h4 class="top-title text-center">DATA-DRIVEN CUSTOMER EXPERIENCES THAT MATTER</h4>
     <div class="row align-items-center">
        <div class="col-12 col-lg-12 order-1 order-lg-1">
           <div class="d-none d-lg-block divider-20"></div>
           <div class="item-content">
              <p class="big">GoCampaign video personalization platform, transforms your customer engagements using the power of customer data and engaging videos, to deliver differentiated, hyper-personalized, experiences and win over the customer's trust.</p>
              <div class="hwtimagehide">
                <img src="../../../../assets/frontend/images/how-it-works/how-it-works-campaign.webp" alt="How it Works"  />
              </div>
           </div>
           <div id="hiwcontainer">
              <ul id="hiwcontinents1">
                 <li class="hiw-1">
                    <a href="javascript:void(0)">
                    <span class="hiwimage1">
                    <img src="../../../../assets/frontend/images/how-it-works/dynamic-image.webp">
                    </span>
                    </a>
                 </li>
                 <li class="hiw-2">
                    <a href="javascript:void(0)">
                    <span class="hiwimage2">
                    <img class="img-responsive" src="../../../../assets/frontend/images/how-it-works/dynamic-voice-over-different-languages.webp">
                    </span>
                    </a>
                 </li>
                 <li class="hiw-3">
                    <a href="javascript:void(0)">
                    <span class="hiwimage3">
                    <img src="../../../../assets/frontend/images/how-it-works/personalised-name-and-policy-details.webp" class="img-responsive" >
                    </span>
                    </a>
                 </li>
                 <li class="hiw-4">
                    <a href="javascript:void(0)">
                    <span class="hiwimage4">
                    <img src="../../../../assets/frontend/images/how-it-works/dynamic-data.webp" class="img-responsive" >
                    </span>
                    </a>
                 </li>
                 <li class="hiw-5">
                    <a href="javascript:void(0)">
                    <span class="hiwimage5">
                    <img src="../../../../assets/frontend/images/how-it-works/api-integratiom-toany-system.webp" class="img-responsive" >
                    </span>
                    </a>
                 </li>
                 <li class="hiw-6">
                    <a href="javascript:void(0)">
                       <span class="hiwimage6">
                       <img src="../../../../assets/frontend/images/how-it-works/personalised-url-landing-page.webp" class="img-responsive" >
                       </span>
                    </a>
                 </li>
              </ul>
           </div>
        </div>
     </div>
  </div>
</section>
<!-- ******************** personalized video creation steps (accordion) section starts here ********************** -->		
<section class="ls s-pt-50 s-pb-20 s-pt-lg-120 s-pb-lg-20 s-pt-xl-50 c-mb-30 c-mb-lg-50 ls tab">
   <div class="divider-10"></div>
   <div class="container">
      <h4 class="top-title text-center">PERSONALIZED VIDEO CREATION STEPS</h4>
      <div class="divider-30"></div>
      <div class="row">
         <div class="col-12">
            <div class="row">
               <div class="col-lg-12">
                  <!-- tabs start -->
                  <ul class="nav nav-tabs" role="tablist" >
                     <li class="nav-item">
                        <a class="nav-link active" id="tab04" data-toggle="tab" href="#tab04_pane" role="tab" aria-controls="tab04_pane" aria-expanded="true">Create video</a>
                     </li>
                     <li class="nav-item">
                        <a class="nav-link" id="tab05" data-toggle="tab" href="#tab05_pane" role="tab" aria-controls="tab05_pane">Customize the data</a>
                     </li>
                     <li class="nav-item">
                        <a class="nav-link" id="tab06" data-toggle="tab" href="#tab06_pane" role="tab" aria-controls="tab06_pane">Personalize the video</a>
                     </li>
                     <li class="nav-item">
                        <a class="nav-link" id="tab07" data-toggle="tab" href="#tab07_pane" role="tab" aria-controls="tab07_pane">Engage with user</a>
                     </li>
                     <li class="nav-item">
                        <a class="nav-link" id="tab08" data-toggle="tab" href="#tab08_pane" role="tab" aria-controls="tab08_pane">Measure results</a>
                     </li>
                  </ul>
                  <div class="tab-content tab-media" style="background-color: #fff">
                     <div class="tab-pane fade show active" id="tab04_pane" role="tabpanel" aria-labelledby="tab04">
                        <div class="media">
                           <img src="../../../../assets/frontend/images/how-it-works/create_video.webp" class="solu-noimg">
                           <div class="media-body">
                              <p class="pvcs">GoCampaign with its core at video production, can assist with developing the custom video template for your campaign – right from ideation, storyboarding, scripting, execution and post production.</p>
                           </div>
                        </div>
                     </div>
                     <div class="tab-pane fade" id="tab05_pane" role="tabpanel" aria-labelledby="tab05">
                        <div class="media">
                           <div class="media-body">
                              <p class="pvcs">GoCampaign easily connects with your CRM data or with other contextual data to merge and generate personalized videos with individualized content for each customer.</p>
                           </div>
                           <img src="../../../../assets/frontend/images/how-it-works/customize_data.webp" alt="Strengthen your customer outreach" class="solu-noimg">
                        </div>
                     </div>
                     <div class="tab-pane fade" id="tab06_pane" role="tabpanel" aria-labelledby="tab06">
                        <div class="media">
                           <img src="../../../../assets/frontend/images/how-it-works/personalize_video.webp" alt="Automate interactions yet be human" class="solu-noimg">
                           <div class="media-body">
                              <p class="pvcs">The data driven videos are dynamically created without any manual intervention. The result, thousands of personalized videos are delivered automatically to multiple users at one go, in just a few seconds!</p>
                           </div>
                        </div>
                     </div>
                     <div class="tab-pane fade" id="tab07_pane" role="tabpanel" aria-labelledby="tab07">
                        <div class="media">
                           <img src="../../../../assets/frontend/images/how-it-works/engage_user.webp" alt="Automate interactions yet be human" class="solu-noimg">
                           <div class="media-body">
                              <p class="pvcs">Engage one-on-one with each customer using personalized video interactions that serve contextual and relevant information for better customer engagement and decision making.</p>
                           </div>
                        </div>
                     </div>
                     <div class="tab-pane fade" id="tab08_pane" role="tabpanel" aria-labelledby="tab08">
                        <div class="media">
                           <img src="../../../../assets/frontend/images/how-it-works/measure_results.webp" alt="Automate interactions yet be human" class="solu-noimg">
                           <div class="media-body">
                              <p class="pvcs">Get the best results from the automated personalized videos. Track campaign results using rich, real-time analytics. Take your marketing performance to the next level and outperform your goals.</p>
                           </div>
                        </div>
                     </div>
                  </div>
                  <!-- tabs end-->
               </div>
            </div>
         </div>
      </div>
   </div>
 </section>	
<!-- ******************** personalized video creation steps (accordion) section ends here ********************** -->			
<!-- ******************** creative possibilities section starts here ******************** -->	
<section class="ls background-gradient s-pt-75 about-section text-center text-lg-left">
  <div class="d-none d-lg-block divider-10"></div>
  <div class="container">
     <h4 class="top-title text-center cpw">CREATIVE POSSIBILITIES</h4>
     <div class="row align-items-center">
        <div class="col-12 col-lg-12 order-1 order-lg-1">
           <div class="d-none d-lg-block divider-20"></div>
           <div class="item-content">
              <p class="cpw">We do not subscribe to letting our clients create the video templates themselves using bots, as that is not their core competence or the best use of their time. Nor do we recommend a fixed creative style or reusing an existing video template for new campaigns. The tailor-made video templates we create are highly relevant and ideally suited for each personalized video engagement planned.</p>
           </div>
        </div>
     </div>
     <div class="divider-60"></div>
     <div class="row">
        <div class="col-lg-3 col-md-6">
           <video poster="../../../../assets/frontend/images/2D_animation_video.jpg" id="VisaChipCardVideo" width="260" controls>
              <source src="../../../../assets/frontend/videos/2D_animation_video.mp4" type="video/mp4">
           </video>
           <div class="cpcontainer">
              <h6 class="cphead text-center">
                 2D animation video
              </h6>
              <div class="cpoverlay">
                 <div class="cptext">Make your product story highly visual and easy to understand. 
                    Explain concepts better for improved user comprehension.
                 </div>
              </div>
           </div>
        </div>
        <div class="col-lg-3 col-md-6">
           <video poster="../../../../assets/frontend/images/Stock_footage_based_video.jpg" id="VisaChipCardVideo1" width="260" controls>
              <source src="../../../../assets/frontend/videos/Stock_footage_based_video.mp4" type="video/mp4">
           </video>
           <div class="cpcontainer">
              <h6 class="cphead text-center">
                 Stock footage based video
              </h6>
              <div class="cpoverlay">
                 <div class="cptext">Make the product story come alive with video stock footages. Capture audience attention and drive impact.
                 </div>
              </div>
           </div>
        </div>
        <div class="col-lg-3 col-md-6">
           <video poster="../../../../assets/frontend/images/Live_footage_based_video.jpg" id="VisaChipCardVideo2" width="260" controls>
              <source src="../../../../assets/frontend/videos/Live_footage_based_video.mp4" type="video/mp4">
           </video>
           <div class="cpcontainer">
              <h6 class="cphead text-center">
                 Live footage based video
              </h6>
              <div class="cpoverlay">
                 <div class="cptext">Use your own products or people in the video and make an authentic statement. Increase confidence and trust in your brand.
                 </div>
              </div>
           </div>
        </div>
        <div class="col-lg-3 col-md-6">
           <video poster="../../../../assets/frontend/images/Fluidic_graphics_based_video.jpg" id="VisaChipCardVideo3" width="260" controls>
              <source src="../../../../assets/frontend/videos/Fluidic_graphics_based_video.mp4" type="video/mp4">
           </video>
           <div class="cpcontainer">
              <h6 class="cphead text-center">
                 Fluid graphic based video
              </h6>
              <div class="cpoverlay">
                 <div class="cptext">Overlay animated 3D objects and graphs and create custom animated pie charts, bar and line charts for video invoices, account statements and infographics.
                 </div>
              </div>
           </div>
           <br><br>
          
        </div>
     </div>
  </div>
</section>
<!-- creative possibilities section ends here -->	
<!-- integrations section starts here -->
<section class="ls s-pt-80 s-pb-50 s-pt-lg-90 s-pb-lg-100 s-pt-xl-60 s-pb-xl-130 c-gutter-30 c-mb-30">
  <div class="container">
     <div class="divider-60"></div>
     <h4 class="top-title text-center">INTEGRATIONS</h4>
     <div class="item-content">
        <p>GoCampaign platform can be integrated with most leading marketing automation and Email Service Provider tools, using our API library or by creating custom scripts. Automate the delivery of personalized videos and improve campaign ROI.</p>
     </div>
     <div class="divider-60"></div>
     <!-- row 1 starts here -->
     <div class="row">
        <div class="divider-5  d-lg-block"></div>
        <div class="col-sm-6 col-lg-2">
           <div class="channel">
              <a href="https://acoustic.com/" target="_blank">
              <img src="../../../../assets/frontend/images/service-providers/acoustic_campaign1.webp" alt="Acoustic Campaign"></a>
           </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-lg-2">
           <div class="channel">
              <a href="https://www.actito.com/en-BE/" target="_blank">
              <img src="../../../../assets/frontend/images/service-providers/actito-logo1.webp" alt="Actito">
              </a>
           </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-2">
           <div class="channel">
              <a href="https://www.activecampaign.com/" target="_blank">
              <img src="../../../../assets/frontend/images/service-providers/activecampaign-logo.webp" alt="ActiveCampaign">
              </a>
           </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-2">
           <div class="channel">
              <a href="https://act-on.com/" target="_blank">
              <img src="../../../../assets/frontend/images/service-providers/act-on_logo.webp" alt="Act-On">
              </a>
           </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-2">
           <div class="channel">
              <a href="https://www.adobe.com/in/marketing/campaign.html" target="_blank">
              <img src="../../../../assets/frontend/images/service-providers/adobe-campaign-logo.webp" alt="Adobe Campaign">
              </a> 
           </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-2">
           <div class="channel">
              <a href="https://www.agilecrm.com/" target="_blank">
              <img src="../../../../assets/frontend/images/service-providers/Agile-CRM.webp" alt="Agile CRM">
              </a>
           </div>
        </div>
     </div>
     <!-- row 1 ends here -->
     <!-- row 2 starts here -->
     <div class="row">
        <div class="divider-5 d-none d-lg-block"></div>
        <div class="col-12 col-sm-6 col-lg-2">
           <div class="channel">
              <a href="http://www.agillic.com/" target="_blank">
              <img src="../../../../assets/frontend/images/service-providers/agillic-logo.webp" alt="agillic"></a>
           </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-2">
           <div class="channel">
              <a href="http://www.aprimo.com/" target="_blank">
              <img src="../../../../assets/frontend/images/service-providers/aprimo.webp" alt="aprimo">
              </a>
           </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-2">
           <div class="channel">
              <a href="https://blueshift.com/" target="_blank">
              <img src="../../../../assets/frontend/images/service-providers/blueshift-logo.webp" alt="blueshift">
              </a>
           </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-2">
           <div class="channel">
              <a href="https://bumble.com/en-in/" target="_blank">
              <img src="../../../../assets/frontend/images/service-providers/bumbl_logo.webp" alt="bumbl">
              </a>
           </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-2">
           <div class="channel">
              <a href="https://buyergenomics.com/" target="_blank">
              <img src="../../../../assets/frontend/images/service-providers/buyergenomics.webp" alt="buyergenomics">
              </a> 
           </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-2">
           <div class="channel">
              <a href="http://www.catonetworks.com/" target="_blank">
              <img src="../../../../assets/frontend/images/service-providers/CATO.webp" alt="CATO">
              </a>
           </div>
        </div>
     </div>
     <!-- row 2 ends here -->
     <!-- row 3 starts here -->
     <div class="row">
        <div class="divider-5 d-none d-lg-block"></div>
        <div class="col-12 col-sm-6 col-lg-2">
           <div class="channel">
              <a href="https://www.constantcontact.com/" target="_blank">
              <img src="../../../../assets/frontend/images/service-providers/constant_contact_logo.webp" alt="Constant Contact"></a>
           </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-2">
           <div class="channel">
              <a href="https://customer.io" target="_blank">
              <img src="../../../../assets/frontend/images/service-providers/customer-io.webp" alt="customer.io">
              </a>
           </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-2">
           <div class="channel">
              <a href="https://www.drip.com/" target="_blank">
              <img src="../../../../assets/frontend/images/service-providers/drip_logo.webp" alt="drip">
              </a>
           </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-2">
           <div class="channel">
              <a href="https://www.oracle.com/cx/marketing/" target="_blank">
              <img src="../../../../assets/frontend/images/service-providers/eloqua-logo.webp" alt="eloqua">
              </a> 
           </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-2">
           <div class="channel">
              <a href="https://emarsys.com/" target="_blank">
              <img src="../../../../assets/frontend/images/service-providers/csm_emarsys.webp" alt="emarsys">
              </a>
           </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-2">
           <div class="channel">
              <a href="https://www.engagebay.com/" target="_blank">
              <img src="../../../../assets/frontend/images/service-providers/engagebay.webp" alt="engagebay">
              </a>
           </div>
        </div>
     </div>
     <!-- row 3 ends here -->

  </div>
</section>
<section class="ls s-pt-30 s-pb-30 s-pt-lg-120 s-pb-lg-90 s-pt-xl-80 s-pb-xl-30 c-mb-30 team-page ls tab">
  <div class="container">
     <h4 class="top-title text-center">Distribution Methods</h4>
     <div class="item-content">
        <p>Automatically distribute the personalized videos to thousands of customers on any of the prefered channels and track their responses in real-time.</p>
     </div>
     <div class="divider-60"></div>
     <div class="row">
        <div class="col-lg-3 col-md-6">
           <div class="vertical-item content-padding text-center">
              <div class="icon-media">
                 <img src="../../../../assets/frontend/images/icons/icon-envelop.webp" alt="Email">
              </div>
              <div class="item-content">
                 <p class="small-text color-main4">
                    Email
                 </p>
              </div>
           </div>
        </div>
        <!-- .col-* -->
        <div class="col-lg-3 col-md-6">
           <div class="vertical-item content-padding text-center">
              <div class="icon-media">
                 <img src="../../../../assets/frontend/images/icons/icon-sms.webp" alt="SMS">
              </div>
              <div class="item-content">
                 <p class="small-text color-main4">
                    SMS
                 </p>
              </div>
           </div>
        </div>
        <!-- .col-* -->
        <div class="col-lg-3 col-md-6">
           <div class="vertical-item content-padding text-center">
              <div class="icon-media">
                 <img src="../../../../assets/frontend/images/icons/icon-mobile.webp" alt="Mobile App">
              </div>
              <div class="item-content">
                 <p class="small-text color-main4">
                    Mobile App
                 </p>
              </div>
           </div>
        </div>
        <!-- .col-* -->
        <div class="col-lg-3 col-md-6">
           <div class="vertical-item content-padding text-center">
              <div class="icon-media">
                 <img src="../../../../assets/frontend/images/icons/icon-website.webp" alt="Website">
              </div>
              <div class="item-content">
                 <p class="small-text color-main4">
                    Website
                 </p>
              </div>
           </div>
        </div>
        <!-- .col-* -->
        <div class="col-lg-3 col-md-6">
           <div class="vertical-item content-padding text-center">
              <div class="icon-media">
                 <img src="../../../../assets/frontend/images/icons/icon-facebook.webp" alt="Facebook">
              </div>
              <div class="item-content">
                 <p class="small-text color-main2">
                    Facebook
                 </p>
              </div>
           </div>
        </div>
        <!-- .col-* -->
        <div class="col-lg-3 col-md-6">
           <div class="vertical-item content-padding text-center">
              <div class="icon-media">
                 <img src="../../../../assets/frontend/images/icons/icon-twitter.webp" alt="Twitter">
              </div>
              <div class="item-content">
                 <p class="small-text color-main2">
                    Twitter
                 </p>
              </div>
           </div>
        </div>
        <!-- .col-* -->
        <div class="col-lg-3 col-md-6">
           <div class="vertical-item content-padding text-center">
              <div class="icon-media">
                 <img src="../../../../assets/frontend/images/icons/icon-instagram.webp" alt="Instagram">
              </div>
              <div class="item-content">
                 <p class="small-text color-main2">
                    Instagram
                 </p>
              </div>
           </div>
        </div>
        <!-- .col-* -->
        <div class="col-lg-3 col-md-6">
           <div class="vertical-item content-padding text-center">
              <div class="icon-media">
                 <img src="../../../../assets/frontend/images/icons/icon-youtube.webp" alt="YouTube">
              </div>
              <div class="item-content">
                 <p class="small-text color-main4">
                    YouTube
                 </p>
              </div>
           </div>
        </div>
        <!-- .col-* -->
     </div>
  </div>
</section>
<section class="s-pt-80 s-pb-0 s-pt-lg-160 s-pb-lg-60 s-pt-lg-160 s-pb-lg-60 c-gutter-60  ls pv">
  <div class="container">
     <div class="row">
        <div class="col-lg-8">
           <h4 class="top-title pvt">STAY RELEVANT WITH CUSTOMERS USING PERSONALIZED VIDEOS</h4>
           <p>Let's talk on the next steps!</p>
        </div>
        <div class="col-lg-3 ">
           <div class="d-block d-lg-none divider-30"></div>
           <div class="row" style="padding-top: 50px">
              <div class="col-sm-8 gcv-but">
                 <button  id="rad" h name="rad" class="btn btn-maincolor"><span><a routerLink="/request-a-demo.html">Request a Demo</a></span>
                 </button>
              </div>
              <div class="col-sm-4 gcv-but">
                 <button id="contact-me" name="contact-me" class="btn btn-maincolor "><span><a routerLink="/contact.html">Contact Me</a></span>
                 </button>
              </div>
           </div>
        </div>
     </div>
  </div>
</section>