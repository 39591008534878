<section class="page_title cs s-pt-60 s-pb-10 s-pt-lg-100 s-pb-lg-30 text-center">
  <div class="container-fluid">
    <div class="row">
      <div class="divider-60"></div>
      <div class="col-md-12 text-center">
        <h1>News</h1>
      </div>
      <div class="divider-50"></div>
    </div>
  </div>
  <ol class="breadcrumb full-width">
    <li class="breadcrumb-item">
      <a href="">Home</a>
    </li>
    <li class="breadcrumb-item active">
      News
    </li>
  </ol>
</section>

<section class="ls s-py-80 s-py-lg-120 s-py-xl-160">
  <div class="container">
     <div class="row c-gutter-60 blog_container">
        <main class="col-lg-7 col-xl-8">
           <article class="text-center text-md-left vertical-item content-padding hero-bg post type-post status-publish format-standard has-post-thumbnail sticky rounded rounded-image-top post-color1">
              <div class="item-media post-thumbnail">
                 <img src="../../../../assets/frontend/images/news/meir.webp" alt="">
              </div>
              <!-- .post-thumbnail -->
              <div class="item-content">
                 <header class="entry-header">
                    <div class="post-data bg-maincolor">
                       <a href="javascript:void(0)">
                          <h6>20<span>June</span></h6>
                       </a>
                    </div>
                    <div class="header-content">
                       <h3 class="entry-title">
                            GoCampaignVideos featured in the cover story of Middle East Insurance Review magazine
                       </h3>
                    </div>
                 </header>
                 <!-- .entry-header -->
                 <div class="entry-content">
                    <p>The June 2020 issue of Middle East Insurance Review magazine, carried the cover story highlighting some of the measures taken by leading insurers in the Middle East to ensure that their customer relationships will outlast the pandemic. GoCampaignVideos was featured in the article alongside our customer.</p>
                    <a target="_BLANK" href="../../../../assets/frontend/pdfs/MEIR-Staying connected in a changing environment.pdf">Read full article</a>
                    <div class="post-footer d-md-flex align-items-center justify-content-between">
                    </div>
                 </div>
                 <!-- .entry-content -->
              </div>
              <!-- .item-content -->
           </article>
           <!-- #post-## -->
        </main>
        <aside class="col-lg-5 col-xl-4">
          <div class="widget widget_recent_posts">
             <h5 class="widget-title">Other News</h5>
             <ul class="list-unstyled">
                <li class="media">
                   <a class="media-image" href="javascript:void(0)">
                   <img src="../../../../assets//frontend//images/news/siliconIndia.webp" alt="">
                   </a>
                   <div class="media-body">
                      <h4 style="margin-top: -10px;">
                         <a href="javascript:void(0)" (click)="scroll('siliconIndia')" class="foot-h">Selfanimate adjudged as top Marketing Start-ups 2019 by leading publication SiliconIndia</a>
                      </h4>
                      <p class="item-meta">
                       October 19, 2020
                      </p>
                   </div>
                </li>
                <li class="media">
                   <a class="media-image" href="javascript:void(0)">
                   <img src="../../../../assets//frontend//images/news/meir.webp" alt="">
                   </a>
                   <div class="media-body">
                      <h4 style="margin-top: -10px;">
                         <a href="javascript:void(0)" class="foot-h">GoCampaignVideos featured in the cover story of Middle East Insurance Review magazine</a>
                      </h4>
                      <p class="item-meta">
                         June 20, 2021
                      </p>
                   </div>
                </li>
             </ul>
          </div>
       </aside>
        <main class="col-lg-7 col-xl-8" id="siliconIndia">
          <article class="text-center text-md-left vertical-item content-padding hero-bg post type-post status-publish format-standard has-post-thumbnail sticky rounded rounded-image-top post-color1">
             <div class="item-media post-thumbnail">
                <img src="../../../../assets/frontend/images/news/siliconIndia.webp" alt="">
             </div>
             <!-- .post-thumbnail -->
             <div class="item-content">
                <header class="entry-header">
                   <div class="post-data bg-maincolor">
                      <a href="javascript:void(0)">
                         <h6>19<span>October</span></h6>
                      </a>
                   </div>
                   <div class="header-content">
                      <h3 class="entry-title">
                        Selfanimate adjudged as top Marketing Start-ups 2019 by leading publication SiliconIndia
                      </h3>
                   </div>
                </header>
                <!-- .entry-header -->
                <div class="entry-content">
                   <p>Leading technology publication SiliconIndia and its panel of jury, selected Selfanimate, the parent company of GoCampaignVideos, as one of the most promising start-up's in Chennai, India. This acknowledgement instils confidence in the company and the solutions we offer.</p>
                   <a target="_BLANK" href="../../../../assets/frontend/pdfs/Selfanimate-SiliconIndia-Awards.pdf">Read full article</a>
                   <div class="post-footer d-md-flex align-items-center justify-content-between">
                   </div>
                </div>
                <!-- .entry-content -->
             </div>
             <!-- .item-content -->
          </article>
          <!-- #post-## -->
       </main>

     </div>
  </div>
</section>