import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var FrontendService = /** @class */ (function () {
    function FrontendService(http) {
        this.http = http;
        this.currentPageBehavior = new BehaviorSubject({ currPage: '' });
    }
    /**
     * SET CURRENT PAGE
     * @param behave
     */
    FrontendService.prototype.setCurrentPage = function (behave) {
        this.currentPageBehavior.next(behave);
    };
    /**
     * GET CURRENT PAGE
     */
    FrontendService.prototype.getCurrentPage = function () {
        return this.currentPageBehavior.asObservable();
    };
    /**
     * SUBMIT GET IN TOUCH FORM
     */
    FrontendService.prototype.GetinTouch = function (data) {
        var token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjciLCJ1c2VybmFtZSI6InRvcDJkM2QzQGdtYWlsLmNvbSIsImlhdCI6MTU4MTc1MzAzMSwiZXhwIjoxNTk3MzA1MDMxfQ.17RtGVm8ueRtXwvKa2PDu3ibstu1_DqMHTn91QwEtFA';
        var headers = new HttpHeaders({
            "x-api-key": "tiNnpe6Av8NizXtfYccDzOBdF",
            "token": token,
            "Content-Type": "application/json",
            "Accept": "*/*",
            "Cache-Control": "no-cache"
        });
        var options = { headers: headers };
        return this.http.post("https://www.selfanimate.com/api/index.php/getintouch", data, options)
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
   * SUBMIT REQUEST DEMO FORM
   * @param data
   * @returns
   */
    FrontendService.prototype.requestDemo = function (data) {
        var token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjciLCJ1c2VybmFtZSI6InRvcDJkM2QzQGdtYWlsLmNvbSIsImlhdCI6MTU4MTc1MzAzMSwiZXhwIjoxNTk3MzA1MDMxfQ.17RtGVm8ueRtXwvKa2PDu3ibstu1_DqMHTn91QwEtFA';
        var headers = new HttpHeaders({
            "x-api-key": "tiNnpe6Av8NizXtfYccDzOBdF",
            "token": token,
            "Content-Type": "application/json",
            "Accept": "*/*",
            "Cache-Control": "no-cache"
        });
        var options = { headers: headers };
        return this.http.post("https://www.selfanimate.com/api/index.php/request_demo", data, options)
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     * SUBMIT CONTACT US FORM
     * @param data
     * @returns
     */
    FrontendService.prototype.ContactUs = function (data) {
        var token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjciLCJ1c2VybmFtZSI6InRvcDJkM2QzQGdtYWlsLmNvbSIsImlhdCI6MTU4MTc1MzAzMSwiZXhwIjoxNTk3MzA1MDMxfQ.17RtGVm8ueRtXwvKa2PDu3ibstu1_DqMHTn91QwEtFA';
        var headers = new HttpHeaders({
            "x-api-key": "tiNnpe6Av8NizXtfYccDzOBdF",
            "token": token,
            "Content-Type": "application/json",
            "Accept": "*/*",
            "Cache-Control": "no-cache"
        });
        var options = { headers: headers };
        return this.http.post("https://www.selfanimate.com/api/index.php/contact_us", data, options)
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
     * SUBMIT GET IN TOUCH FORM
     */
    FrontendService.prototype.buynowGetinTouch = function (data) {
        var token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjciLCJ1c2VybmFtZSI6InRvcDJkM2QzQGdtYWlsLmNvbSIsImlhdCI6MTU4MTc1MzAzMSwiZXhwIjoxNTk3MzA1MDMxfQ.17RtGVm8ueRtXwvKa2PDu3ibstu1_DqMHTn91QwEtFA';
        var headers = new HttpHeaders({
            "x-api-key": "tiNnpe6Av8NizXtfYccDzOBdF",
            "token": token,
            "Content-Type": "application/json",
            "Accept": "*/*",
            "Cache-Control": "no-cache"
        });
        var options = { headers: headers };
        return this.http.post("https://www.selfanimate.com/api/index.php/howtobuy_getin_touch", data, options)
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
    * SUBMIT PERSONLIZED VIDEO REQUEST
    */
    FrontendService.prototype.personalised_video_request = function (data) {
        var token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjciLCJ1c2VybmFtZSI6InRvcDJkM2QzQGdtYWlsLmNvbSIsImlhdCI6MTU4MTc1MzAzMSwiZXhwIjoxNTk3MzA1MDMxfQ.17RtGVm8ueRtXwvKa2PDu3ibstu1_DqMHTn91QwEtFA';
        var headers = new HttpHeaders({
            "x-api-key": "tiNnpe6Av8NizXtfYccDzOBdF",
            "token": token,
            "Content-Type": "application/json",
            "Accept": "*/*",
            "Cache-Control": "no-cache"
        });
        var min = 99999;
        var max = 9999999999;
        var video_id = Math.floor(Math.random() * (max - min + 1) + min);
        var formData = { '_vid': video_id, '_name': data.name };
        localStorage.setItem('_vid', btoa(video_id));
        localStorage.setItem('_vs', 'pending');
        var _type = localStorage.getItem("_type");
        console.log('_type', _type);
        var action = (_type === 'cruiseline') ? 'request_cruiseline_video' : 'request_dummy_video';
        var options = { headers: headers };
        return this.http.post("https://www.selfanimate.com/api/index.php/" + action, formData, options)
            .pipe(map(function (data) {
            return data;
        }));
    };
    /**
    * GET BLOG POSTS FROM SELFANIMATE.COM
    * @param data
    */
    FrontendService.prototype.GetBlogPosts = function (pagination_data) {
        var token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjciLCJ1c2VybmFtZSI6InRvcDJkM2QzQGdtYWlsLmNvbSIsImlhdCI6MTU4MTc1MzAzMSwiZXhwIjoxNTk3MzA1MDMxfQ.17RtGVm8ueRtXwvKa2PDu3ibstu1_DqMHTn91QwEtFA';
        var headers = new HttpHeaders({
            "x-api-key": "tiNnpe6Av8NizXtfYccDzOBdF",
            "token": token,
            "Content-Type": "application/json",
            "Accept": "*/*",
            "Cache-Control": "no-cache",
            "limit": "50"
        });
        return this.http.get('https://www.selfanimate.com/api/index.php/blog_data', { headers: headers });
    };
    /**
     * GET POST INFORMATION FROM SELFANIMATE.COM
     * @param data
     */
    FrontendService.prototype.GetPost = function (slug) {
        var token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjciLCJ1c2VybmFtZSI6InRvcDJkM2QzQGdtYWlsLmNvbSIsImlhdCI6MTU4MTc1MzAzMSwiZXhwIjoxNTk3MzA1MDMxfQ.17RtGVm8ueRtXwvKa2PDu3ibstu1_DqMHTn91QwEtFA';
        var headers = new HttpHeaders({
            "x-api-key": "tiNnpe6Av8NizXtfYccDzOBdF",
            "token": token,
            "Content-Type": "application/json",
            "Accept": "*/*",
            "Cache-Control": "no-cache",
            "post_slug": slug
        });
        return this.http.get('https://www.selfanimate.com/api/index.php/blog_data', { headers: headers });
    };
    /**
     * GET RECENT POSTS FROM SELFANIMATE.COM
     * @param data
     */
    FrontendService.prototype.GetRecentPosts = function () {
        var posts_per_page = 3;
        var page = 1;
        //let configUrl='https://www.selfanimate.com/blog/api/get_posts/?posts_per_page='+posts_per_page+'&page='+page;
        var configUrl = 'https://www.selfanimate.com/blog/wp-json/wp/v2/posts?=' + posts_per_page + '&page=' + page;
        // wp-json/wp/v2/posts?per_page=3&page=1
        return this.http.get(configUrl);
    };
    /**
      * GET VIDEO URL
      * @param data
      */
    FrontendService.prototype.get_video_data = function (code) {
        var configUrl = environment.api_base_url + "app_controller.php?action=get_video_url&code=" + code;
        return this.http.get(configUrl);
    };
    /**
    * QUERY FROM LANDING PAGE
    * @param data
    */
    FrontendService.prototype.campaign_query = function (data) {
        var formData = new FormData();
        formData.append('firstname', data.first_name);
        formData.append('company', data.company);
        formData.append('email', data.email);
        formData.append('message', data.message);
        if (data.page) {
            formData.append('page', data.page);
        }
        var options = {};
        return this.http.post("https://www.selfanimate.com/pages/campaign_query", formData, options)
            .pipe(map(function (data) {
            return data;
        }));
    };
    FrontendService.ngInjectableDef = i0.defineInjectable({ factory: function FrontendService_Factory() { return new FrontendService(i0.inject(i1.HttpClient)); }, token: FrontendService, providedIn: "root" });
    return FrontendService;
}());
export { FrontendService };
