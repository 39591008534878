<div class="header_absolute header_layout_1 landing_header">
   <header class="page_header ls justify-nav-end">
      <div class="container-fluid">
         <div class="row align-items-center">
            <div class="col-xl-2 col-lg-4 col-md-5 col-11">
               <a href="" class="logo">
                  <img src="../../../../assets/frontend/images/logo.webp" alt="Selfanimate">
               </a>
            </div>
            <div class="col-xl-10 col-lg-8 col-md-7 col-1">
               <div class="nav-wrap">
               </div>
            </div>
         </div>
      </div>
      <!-- <span class="toggle_menu"><span></span></span> -->
   </header>
   <section class="page_title cs s-pt-30 s-pb-10 s-pt-lg-50 s-pb-lg-30 text-center">
      <ol class="breadcrumb full-width">
         <li class="breadcrumb-item active">
            <p>PERSONALISED VIDEO MESSAGE</p>
         </li>
      </ol>
   </section>
</div>
<section class="ls s-pb-20 s-pb-md-60 s-pb-lg c-mb-md-0">
   <div class="container" *ngIf="hide_error">
       <h4 >You are not authorized to view this page</h4>
   </div>
   <div class="container player_container" style="text-align: center;"  *ngIf="video_url">
       <div *ngIf="landscape">
         <p>Hi {{recipient_name}}, your personalized video created just for you!</p>
         <video _ngcontent-c1="" autoplay="autoplay" controls="" muted="" playsinline="" style="margin-bottom: -10px;" class="per-vid-msg" id="pvid">
            <source _ngcontent-c1="" type="video/mp4" src="{{video_url}}">
            Your browser does not support HTML5 video. 
         </video>
       </div>  
       <div *ngIf="portrait">
           <video style="margin-bottom: -10px;" width="35%" controls="" autoplay="" muted=""  playsinline="">
               <source src="{{video_url}}" type="video/mp4">
               Your browser does not support HTML5 video.
           </video>
       </div>
   </div>
</section>
<section class="s-pt-80 s-pb-0 s-pt-lg-160 s-pb-lg-60 s-pt-lg-160 s-pb-lg-60 c-gutter-60  ls pv">
   <div class="container">
      <div class="row">
         <div class="col-lg-8">
            <h4 class="top-title pvt">STAY RELEVANT WITH CUSTOMERS USING PERSONALIZED VIDEOS</h4>
            <p>Let's talk on the next steps!</p>
         </div>
         <div class="col-lg-3">
            <div class="d-block d-lg-none divider-30"></div>
            <div class="row" style="padding-top: 50px">
               <div class="col-sm-8 gcv-but">
                  <button  id="rad" h name="rad" class="btn btn-maincolor"><span><a routerLink="/request-a-demo.html">Request a Demo</a></span>
                  </button>
               </div>
               <div class="col-sm-4 gcv-but">
                  <button id="contact-me" name="contact-me" class="btn btn-maincolor"><span><a routerLink="/contact.html">Contact Me</a></span>
                  </button>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
<section class="page_copyright ds s-py-10 copyright-bg">
    <div class="container">
       <div class="row align-items-center">
          <div class="divider-15 d-none d-lg-block"></div>
          <div class="col-md-12 text-center">
            <p>© Copyright <span class="copyright_year">2024 </span> Selfanimate Private Limited | All Rights Reserved  | <a routerLink="/cookie-notice.html"><span class="policy">Cookie Notice</span></a></p>
          </div>
          <div class="divider-15 d-none d-lg-block"></div>
       </div>
    </div>
 </section>