<div class="header_absolute header_layout_1">
    <!-- header with two Bootstrap columns - left for logo and right for navigation and includes (search, social icons, additional links and buttons etc -->
    <header class="page_header ls justify-nav-end">
       <div class="container-fluid">
          <div class="row align-items-center">
             <div class="col-xl-2 col-lg-4 col-md-5 col-11">
                <a href="" class="logo">
                <img src="./assets/frontend/images/logo.webp" alt="GoCampaign Videos">
                </a>
             </div>
             <div class="col-xl-10 col-lg-8 col-md-7 col-1">
               <div class="nav-wrap">
                  <!-- main nav start -->
                  <nav class="top-nav">
                     <ul class="nav sf-menu">
                        <li><a href="/"><span routerLinkActive="mactive" class="home">Home</span></a></li>
                        <li>
                           <a href="javascript:void(0)" class="solutions">Solutions</a>
                           <ul>
                              <li (click)="setActive('personalized_dynamic_videos')" class="ullib"><a routerLinkActive="mactive"  class="mbl_menu" routerLink="/personalized-dynamic-videos.html">Personalized Dynamic Videos</a></li>
                              <li (click)="setActive('personalized_video_marketing')" class="ullib"><a routerLinkActive="mactive"  class="mbl_menu" href="/personalized-video-marketing.html">Personalized Video Marketing</a></li>
                              <li (click)="setActive('dynamic-online-videos')" class="ullib"><a routerLinkActive="mactive"  class="mbl_menu" routerLink="/dynamic-online-videos.html">Dynamic online videos</a></li>
                              <li (click)="setActive('personalized_social_media_videos')" class="ullib"><a routerLinkActive="mactive"  class="mbl_menu" routerLink="/personalized-social-media-videos.html">Personalized Social Media Videos</a></li>
                              <li (click)="setActive('pre_made_video_templates')"><a class="mbl_menu" routerLinkActive="mactive" routerLink="/pre-made-video-templates.html">Pre-made video templates</a></li>
                           </ul>
                        </li>
                        <li (click)="setActive('how-it-works')" ><a class="mbl_menu" routerLinkActive="mactive"  routerLink="/how-it-works.html">How it Works</a></li>
                        <li (click)="setActive('how-it-benefits')"><a class="mbl_menu" routerLinkActive="mactive" routerLink="/how-it-benefits.html">How it Benefits</a></li>
                        <li><a href="javascript:void(0)" class="sf-with-ul mbl_menu"><span routerLinkActive="mactive" class="mbl_menu resources">Resources</span></a><span class="sf-menu-item-mobile-toggler"></span>
                          <ul style="display: none;">
                             <li (click)="setActive('news')" class="ullib mbl_menu"><a routerLinkActive="mactive" routerLink="/news.html">News</a></li>
                             <li (click)="setActive('videos')" class="ullib mbl_menu"><a routerLinkActive="mactive" routerLink="/videos.html">Videos</a></li>
                             <li (click)="setActive('case-studies')" class="ullib mbl_menu"><a routerLinkActive="mactive" routerLink="/case-studies/1">Case Studies</a></li>
                             <li (click)="setActive('blog')" class="ullib mbl_menu"><a routerLinkActive="mactive" routerLink="/blog.html">Blogs</a></li>
                          </ul>
                       </li>
                        <li (click)="setActive('contact')"><a class="mbl_menu" routerLinkActive="mactive" routerLink="/contact.html">Contact</a></li>
                        <li (click)="setActive('request_demo')"><a  routerLink="/request-a-demo.html" class="btn btn-maincolor rad reqdemo mbl_menu" routerLinkActive="mactive">Request a Demo</a></li>
                     </ul>
                  </nav>
                  <!-- eof main nav -->
               </div>
            </div>
          </div>
       </div>
       <!-- header toggler -->
       <span class="toggle_menu"><span></span></span>
    </header>
 </div>