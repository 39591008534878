<section class="page_title cs s-pt-60 s-pb-10 s-pt-lg-100 s-pb-lg-30 page_title text-center">
  <div class="container-fluid">
     <div class="row">
        <div class="divider-60"></div>
        <div class="col-md-12 text-center">
           <h1>Dynamic Online Videos</h1>
        </div>
        <div class="divider-50"></div>
     </div>
  </div>
  <ol class="breadcrumb full-width">
     <li class="breadcrumb-item">
        <a href="">Home</a>
     </li>
     <li class="breadcrumb-item">
        <a href="javascript:void(0)">Solutions</a>
     </li>
     <li class="breadcrumb-item active">
        Dynamic Online Videos
     </li>
  </ol>
</section>

<section class="ls s-pt-75 s-pb-80 s-pb-lg-100 about-section text-center text-lg-left">
  <div class="d-none d-lg-block divider-10"></div>
  <div class="container">
     <div class="row align-items-center">
        <div class="col-12 col-lg-6 order-1 order-lg-1">
           <div class="d-none d-lg-block divider-20"></div>
           <div class="item-content">
              <h4 class="top-title text-center">MAINTAIN UP-TO-DATE ONLINE PRODUCT VIDEOS</h4>
              <p class="big">Improve web traffic, increase customer engagement and boost conversions with product videos on your website. GoCampaign dynamic online video solution helps you create automated, dynamic online videos to showcase your product, service or time-based offers in real-time.</p>
              <div class="divider-20"></div>
              <div style="padding-left: 15px"><button id="rad" h="" name="rad" class="btn btn-maincolor" ><span><a routerLink="/contact.html">Get Started</a></span>
                 </button>
              </div>
           </div>
        </div>
        <div class="col-12 col-lg-6 order-2 order-lg-2">
           <div class="dovimagehide">
              <img src="../../../../assets/frontend/images/dynamic-online-videos/dynamic-online-videos.png" alt="Dynamic Online Videos"/> 
           </div>
           <div id="dovcontainer">
              <ul id="dovcontinents1">
                 <li class="dov-1">
                    <a href="javascript:void(0)">
                    <span class="dovimage1">
                    <img src="../../../../assets/frontend/images/dynamic-online-videos/photo.png">
                    </span>
                    </a>
                 </li>
                 <li class="dov-2">
                    <a href="javascript:void(0)">
                    <span class="dovimage2">
                    <img class="img-responsive" src="../../../../assets/frontend/images/dynamic-online-videos/Aa.png">
                    </span>
                    </a>
                 </li>
                 <li class="dov-3">
                    <a href="javascript:void(0)">
                    <span class="dovimage3">
                    <img src="../../../../assets/frontend/images/dynamic-online-videos/palette.png" class="img-responsive" >
                    </span>
                    </a>
                 </li>
              </ul>
           </div>
        </div>
     </div>
  </div>
</section>
<section class="ls s-pb-50 s-pb-lg-60 s-pb-xl-60 c-mb-30 service-page2">
  <div class="container">
     <div class="row">
        <div class="col-md-4 col-12">
           <div class="hovereffect">
              <img class="img-responsive" src="../../../../assets/frontend/images/dynamic-online-videos/real-time-product-videos-online.webp" alt="Real-time product videos online">
              <div class="overlay">
                 <h2>Real-time product videos online</h2>
                 <a class="info" href="javascript:void(0)">Maintain updated product videos on your web store- using automated just-in-time video rendering and auto-scaling features.</a>
              </div>
           </div>
        </div>
        <!-- .col-* -->
        <div class="col-md-4 col-12">
           <div class="hovereffect">
              <img class="img-responsive" src="../../../../assets/frontend/images/dynamic-online-videos/automate-product-video-creation.webp" alt="Automate product video creation ">
              <div class="overlay">
                 <h2>Automate product video creation</h2>
                 <a class="info" href="javascript:void(0)">Automatically build product videos from curated product information. Create and manage multiple product videos simultaneously in real-time.</a>
              </div>
           </div>
        </div>
        <!-- .col-* -->
        <div class="col-md-4 col-12">
           <div class="hovereffect">
              <img class="img-responsive" src="../../../../assets/frontend/images/dynamic-online-videos/deliver-exceptional-experiences.webp" alt="Deliver Exceptional Experiences">
              <div class="overlay">
                 <h2>Deliver Exceptional Experiences</h2>
                 <a class="info" href="javascript:void(0)">Use product videos to deliver an impressivebrand or product experience to users. Move away from static image-based product information that does not engage the user.</a>
              </div>
           </div>
        </div>
        <!-- .col-* -->
     </div>
  </div>
</section>
<!-- Benefits -->
<section class="ls s-pt-50 s-pb-20 s-pt-lg-120 s-pb-lg-20 s-pt-xl-50 c-mb-30 c-mb-lg-50 ls tab">
  <div class="divider-10"></div>
  <div class="container">
     <h4 class="top-title text-center">REAL-TIME ONLINE PRODUCT VIDEOS FOR INSTANT CUSTOMER ENGAGEMENTS</h4>
     <p class="text-center">Eliminate time-consuming and costly options of producing individual product videos. We speed up your creative process by automatically integrating relevant product or service information to auto-generate visually-attractive dynamic videos for each product or service.</p>
     <div class="divider-30"></div>
     <div class="row">
        <div class="col-12">
           <div class="row">
              <div class="col-lg-12">
                 <!-- tabs start -->
                 <ul class="nav nav-tabs" role="tablist" >
                    <li class="nav-item">
                       <a class="nav-link active" id="tab04" data-toggle="tab" href="#tab04_pane" role="tab" aria-controls="tab04_pane" aria-expanded="true">Make product videos dynamic & informative</a>
                    </li>
                    <li class="nav-item">
                       <a class="nav-link" id="tab05" data-toggle="tab" href="#tab05_pane" role="tab" aria-controls="tab05_pane">Raise the bar on marketing & sales</a>
                    </li>
                    <li class="nav-item">
                       <a class="nav-link" id="tab06" data-toggle="tab" href="#tab06_pane" role="tab" aria-controls="tab06_pane">Put data to work</a>
                    </li>
                 </ul>
                 <div class="tab-content tab-media" style="background-color: #fff">
                    <div class="tab-pane fade show active" id="tab04_pane" role="tabpanel" aria-labelledby="tab04">
                       <div class="media">
                          <img src="../../../../assets/frontend/images/dynamic-online-videos/make-product-videos-dynamic-informative.webp" alt="Make product videos dynamic & informative" class="solu-noimg">
                          <div class="media-body">
                             <ul class="list-icon list-icon-check list-icon-colored">
                                <li>
                                   Deliver visually engaging, real-time online product information that customers can use
                                </li>
                                <li>
                                   Meet go-to-market timelines by automating the video creation process, dynamically
                                </li>
                                <li>
                                   Cut through the noise with dynamic online videos that have higher recall and conversion potential
                                </li>
                             </ul>
                          </div>
                       </div>
                    </div>
                    <div class="tab-pane fade" id="tab05_pane" role="tabpanel" aria-labelledby="tab05">
                       <div class="media">
                          <div class="media-body">
                             <ul class="list-icon list-icon-check list-icon-colored">
                                <li>
                                   Improve search ranking and increase organic search traffic on your website
                                </li>
                                <li>
                                   Retain web users on your website. Boost conversion rates using product videos
                                </li>
                                <li>
                                   Help prospects gain better understanding of the products and services with information-rich videos
                                </li>
                             </ul>
                          </div>
                          <img src="../../../../assets/frontend/images/dynamic-online-videos/raise-the-bar-on-marketing-sales.webp" alt="Raise the bar on marketing & sales" class="solu-noimg">
                       </div>
                    </div>
                    <div class="tab-pane fade" id="tab06_pane" role="tabpanel" aria-labelledby="tab06">
                       <div class="media">
                          <img src="../../../../assets/frontend/images/dynamic-online-videos/put-data-to-work.webp" alt="Put data to work" class="solu-noimg">
                          <div class="media-body">
                             <ul class="list-icon list-icon-check list-icon-colored">
                                <li>
                                   Import and merge contextual data or product data into dynamic video template seamlessly
                                </li>
                                <li>
                                   Auto-generate the product videos based on specific data sources and host them online instantly
                                </li>
                                <li>
                                   Track video performance with real-time analytics and optimize your creative output
                                </li>
                             </ul>
                          </div>
                       </div>
                    </div>
                 </div>
                 <!-- tabs end-->
              </div>
           </div>
        </div>
     </div>
  </div>
</section>
<!-- Benefits -->
<!-- Personalized Videos -->
<section class="s-pt-80 s-pb-0 s-pt-lg-160 s-pb-lg-60 s-pt-lg-160 s-pb-lg-60 c-gutter-60  ls pv">
  <div class="container">
     <div class="row">
        <div class="col-lg-8">
           <h4 class="top-title pvt">AUTOMATE ONLINE PRODUCT VIDEOS AND BRIDGE THE INFORMATION GAP</h4>
           <p>Let's talk on the next steps!</p>
        </div>
        <div class="col-lg-3">
           <div class="d-block d-lg-none divider-30"></div>
           <div class="row" style="padding-top: 5px">
              <div class="col-sm-8 gcv-but">
                 <button  id="rad2" h name="rad" class="btn btn-maincolor"><span><a routerLink="request-a-demo.html">Request a Demo</a></span>
                 </button>
              </div>
              <div class="col-sm-4 gcv-but">
                 <button id="contact-me" name="contact-me" class="btn btn-maincolor"><span><a routerLink="contact.html">Contact Me</a></span>
                 </button>
              </div>
           </div>
        </div>
     </div>
  </div>
</section>
<!-- Personalized Videos -->
