import { Component, OnInit, ɵConsole } from '@angular/core';
import { FrontendService } from '../../../../_services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ActivatedRoute } from '@angular/router';
import { Title,Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-social-landing-page',
  templateUrl: './social-landing-page.component.html',
  styleUrls: ['./social-landing-page.component.styl']
})
export class SocialLandingPageComponent implements OnInit {

  landingForm: FormGroup;
  loading = false;
  submitted = false;
  video_url: string;
  hide_error: boolean = false;
  recipient_name: any;
  portrait = false;
  landscape = false;
  page: string;

  constructor(
    private formBuilder: FormBuilder,
    private frontendService: FrontendService,
    public toastr: ToastrManager,
    private router: ActivatedRoute,
    private titleService: Title
  ) { }

  ngOnInit() {
     // CREATE FORM VALIDATION
     this.landingForm = this.formBuilder.group({
          first_name: ['', Validators.required],
          company: ['', Validators.required],
          email: ['', [Validators.required, Validators.email]],
          message:  ['', Validators.required],
          page:  ['','']
      });

      this.page='retail';

      this.titleService.setTitle('Video Landing Page | Go campaign'); //SET PAGE TITLE

      this.router.queryParams.subscribe(params => {
          let code:string=params.id;
          localStorage.setItem('_vc',btoa(code));
      });

      let video_url='https://top2d3ddiag.blob.core.windows.net/selfanimate/videos/website/2020-09-29_Retail sample video.mp4?sp=rl&st=2020-09-29T15:15:21Z&se=2024-10-15T18:18:00Z&sv=2019-12-12&sr=b&sig=ApDX42fRcvVyBVnd%2BDSIlSf5ilxc7dWASYQjlx9VlDg%3D';
      this.video_url=video_url;
      this.landscape=true;
      localStorage.removeItem('_vc'); //REMOVE LOCALSTORAGE


  }

  get f() { return this.landingForm.controls; }

  //ONSUBMIT EVENT 
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.landingForm.invalid) {
        return;
    }
    this.loading = true;
    var data =this.landingForm.value;
    console.log(data);

    var data =this.landingForm.value;
    this.frontendService.campaign_query(data)
    .pipe(first())
    .subscribe(
        data => {
           const myObjStr = JSON.stringify(data);
           console.log(myObjStr);
           var object=JSON.parse(myObjStr);
           var status: string=object.status;
           if(status=='unsuccess'){
                let message="something went wrong please try again";
                this.toastr.errorToastr(message, 'Error', {
                  position: 'top-right'
                });
                this.loading = false;  
           }
           if(status=='success'){ 
                //RESET ALL INPUT FIELD
                this.landingForm.reset();
                let message='Thank you for contacting us. You are very important to us. All information received will always remain confidential. We will contact you as soon as we review your message.';
                this.toastr.successToastr(message, 'Success', {
                    position: 'top-center'
                });
                this.submitted = false;
                this.loading = false; //LOADING REMOVED
           }   
        },
        error => {
            this.loading = false;
        })




  }

}

