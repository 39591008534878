
<!---------LOADER SECTION BEGIN--> 
<div class="preloader" *ngIf="laoding">
   <div class="preloader_image"></div>
</div>
 <!---------LOADER SECTION END--> 
 <section class="page_title cs s-pt-60 s-pb-10 s-pt-lg-100 s-pb-lg-30 text-center">
   <div class="container-fluid">
      <div class="row">
         <div class="divider-60"></div>
         <div class="col-md-12 text-center">
            <h1>Blog</h1>
         </div>
         <div class="divider-50"></div>
      </div>
   </div>
   <ol class="breadcrumb full-width">
      <li class="breadcrumb-item">
         <a href="javascript:void(0)">Home</a>
      </li>
      <li class="breadcrumb-item active">
         Blog
      </li>
   </ol>
</section>

<section class="ls s-pt-80 s-pb-50 s-pt-lg-120 s-pb-lg-90 s-pt-xl-160 s-pb-xl-130 blog-grid">
   <div class="container" *ngIf="post_container">
      <div class="row c-mb-30 blog_container">
         <main class="offset-lg-1 col-lg-10">
            <div class="row isotope-wrapper masonry-layout">
               <div class="col-xl-4 col-md-6" *ngFor="let post of posts;">
                  <article class="vertical-item text-center content-padding padding-small hero-bg rounded rounded-image-top post type-post status-publish format-standard has-post-thumbnail">
                     <div class="item-media post-thumbnail">
                        <a routerLink="/post/{{post.slug}}">
                        <img srcset="{{post.thumbnail_url}}" alt="">
                        </a>
                     </div>
                     <!-- .post-thumbnail -->
                     <div class="post-container">
                           <h4 class="entry-title post-title">
                              <a routerLink="/post/{{post.slug}}" style="line-height: 1.4;" rel="bookmark">
                              {{post.title}}
                              </a>
                           </h4>
                           <div class="entry-meta">
                              <span class="screen-reader-text">Posted on</span>
                              <a routerLink="/post/{{post.slug}}" class="link-main2" rel="bookmark">
                              <time class="entry-date published updated" datetime="2018-03-11T15:15:12+00:00">{{post.date | date:"dd" }} {{post.date | date:"MMM" }}
                              </time>
                              </a>
                           </div>
                           <!-- .entry-meta -->
                        <!-- .entry-header d-block -->
                        <div class="entry-content">
                           <div class="post_text" [innerHTML]='post.description | slice:0:100'></div>
                           <div class="read-more">
                             <a routerLink="/post/{{post.slug}}"  class="">Read More</a>
                          </div>
                        </div>
                        <!-- .entry-content -->
                     </div>
                     <!-- .item-content -->
                  </article>
                  <!-- #post-## -->
               </div>
            </div>
            <div class="row">
               <div class="col-md-12">
                   <!--- BEGIN - PAGINATION SECTION------>
                   <div class="pagination-section">
                         <nav class="navigation pagination" role="navigation">
                               <a *ngIf="previous_pagination_btn" class="prev page-numbers" href="javascript:void(0)"   (click)="previous_pagination(posts_per_page,previous_page)"><svg class="svg-icon" width="22" height="22" aria-hidden="true" role="img" focusable="false" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg> <span class="nav-prev-text">Previous</span></a>
                               <a *ngIf="next_pagination_btn" class="next page-numbers" href="javascript:void(0)"   (click)="next_pagination(posts_per_page,page)">
                                  <span class="nav-next-text">Next</span> 
                                  <svg class="svg-icon" width="22" height="22" aria-hidden="true" role="img" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                     <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                                     <path d="M0 0h24v24H0z" fill="none"></path>
                                  </svg>
                               </a>
                         </nav> 
                   </div>
                   <!--- END - PAGINATION SECTION------>
               </div>
            </div>
            <!-- <nav class="navigation pagination justify-content-center" role="navigation">
               <h2 class="screen-reader-text">Posts navigation</h2>
               <div class="nav-links">
                  <a class="prev page-numbers" href="#">
                  <i class="fa fa-angle-left"></i>
                  <span class="screen-reader-text">Previous page</span>
                  </a>
                  <span class="page-numbers current" >
                  <span class="meta-nav screen-reader-text">Page </span>
                  1
                  </span>
                  <a class="page-numbers" href="#">
                  <span class="meta-nav screen-reader-text">Page </span>
                  2
                  </a>
                  <a class="page-numbers" href="#">
                  <span class="meta-nav screen-reader-text">Page </span>
                  3
                  </a>
                  <a class="next page-numbers" href="#">
                  <span class="screen-reader-text">Next page</span>
                  <i class="fa fa-angle-right"></i>
                  </a>
               </div>
            </nav> -->
         </main>
      </div>
   </div>
</section>