import { OnInit } from '@angular/core';
import { FrontendService } from '../../../_services';
import { FormBuilder, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrManager } from 'ng6-toastr-notifications';
var SiteFooterComponent = /** @class */ (function () {
    function SiteFooterComponent(frontendService, formBuilder, toastr) {
        this.frontendService = frontendService;
        this.formBuilder = formBuilder;
        this.toastr = toastr;
        this.showActions = false;
        this.GetinTouch = false;
    }
    SiteFooterComponent.prototype.ngOnInit = function () {
        var _this = this;
        //GET CURRENT PAGE FROM SUBSCRIBE
        this.frontendService.getCurrentPage().subscribe(function (v) {
            _this.currPage = v['currPage'];
            if (v['currPage'] == '/howtobuy.html') {
                _this.showActions = true;
                _this.GetinTouch = false;
            }
            else if (v['currPage'] == '/whentouse.html') {
                _this.showActions = false;
                _this.GetinTouch = true;
            }
            else if (v['currPage'] == '/wheretouse.html') {
                _this.showActions = false;
                _this.GetinTouch = true;
            }
            else if (v['currPage'] == '/howitworks.html') {
                _this.showActions = false;
                _this.GetinTouch = true;
            }
            else if (v['currPage'] == '/login') {
                _this.showActions = false;
                _this.GetinTouch = false;
            }
            else if (v['currPage'] == '/blog.html') {
                _this.showActions = false;
                _this.GetinTouch = false;
            }
            else if (v['currPage'] == '/post.html') {
                _this.showActions = false;
                _this.GetinTouch = false;
            }
            else {
                _this.showActions = false;
                _this.GetinTouch = true;
            }
        });
        //VALIDATE FORM
        this.GetinTouchForm = this.formBuilder.group({
            name: ['', Validators.required],
            email: ['', Validators.required],
            message: ['', Validators.required]
        });
        //VALIDATE BUY NOW GET IN TOUCH FORM
        this.BuynowGetinTouchForm = this.formBuilder.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            organization: ['', ''],
            phone: ['', ''],
            video_template: ['', Validators.required],
            personalized_videos: ['', Validators.required],
            duration_of_video: ['', Validators.required],
            no_of_campaign: ['', ''],
            custom_no_of_campaign: ['', ''],
            delivery: ['', Validators.required],
            support: ['', Validators.required],
        });
        //SET DEFAULT VALUE TO SELECT OPTION
        this.duration_of_video = '';
        this.personalized_videos = '';
        this.no_of_campaign = '';
        this.custom_no_of_campaign = '';
        this.support = 'yes';
        this.delivery = 'email';
        this.video_template = 'yes';
    };
    Object.defineProperty(SiteFooterComponent.prototype, "p", {
        get: function () {
            return this.GetinTouchForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SiteFooterComponent.prototype, "b", {
        get: function () {
            return this.BuynowGetinTouchForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * SUBMIT BUY
     */
    SiteFooterComponent.prototype.submitBuynowGetinTouch = function () {
        var _this = this;
        this.buynowsubmitted = true; //ENABLE SUBMIT BUTTON
        // stop here if form is invalid
        if (this.BuynowGetinTouchForm.invalid) {
            return;
        }
        this.buynowloading = true; //ENABLE LOADING
        var data = this.BuynowGetinTouchForm.value;
        this.frontendService.buynowGetinTouch(data)
            .pipe(first())
            .subscribe(function (data) {
            var myObjStr = JSON.stringify(data);
            console.log(myObjStr);
            var object = JSON.parse(myObjStr);
            var message = object.message;
            if (message == 'unsuccess') {
                var message_1 = "something went wrong please try again";
                _this.toastr.errorToastr(message_1, 'Error', {
                    position: 'top-right'
                });
                _this.loading = false;
            }
            if (message == 'success') {
                //RESET ALL INPUT FIELD
                _this.BuynowGetinTouchForm.reset();
                _this.support = 'yes';
                _this.delivery = 'email';
                _this.video_template = 'yes';
                var message_2 = 'Thank you for contacting us. You are very important to us. All information received will always remain confidential. We will contact you as soon as we review your message.';
                _this.toastr.successToastr(message_2, 'Success', {
                    position: 'top-center',
                    timeOut: 999999999,
                    extendedTimeOut: 99999
                });
                _this.buynowsubmitted = false; //RESET SUBMIT BUTTON
                _this.buynowloading = false; //RESET .LOADING
            }
        }, function (error) {
            _this.buynowloading = false;
        });
    };
    /**
     * SUBMIT GET IN TOUCH FORM
     */
    SiteFooterComponent.prototype.submitGetinTouch = function () {
        var _this = this;
        this.submitted = true;
        // stop here if form is invalid
        if (this.GetinTouchForm.invalid) {
            return;
        }
        this.loading = true;
        var data = this.GetinTouchForm.value;
        this.frontendService.GetinTouch(data)
            .pipe(first())
            .subscribe(function (data) {
            var myObjStr = JSON.stringify(data);
            console.log(myObjStr);
            var object = JSON.parse(myObjStr);
            var message = object.message;
            if (message == 'unsuccess') {
                var message_3 = "something went wrong please try again";
                _this.toastr.errorToastr(message_3, 'Error', {
                    position: 'top-right'
                });
                _this.loading = false;
            }
            if (message == 'success') {
                //RESET ALL INPUT FIELD
                _this.GetinTouchForm.reset();
                var message_4 = 'Thank you for contacting us. You are very important to us. All information received will always remain confidential. We will contact you as soon as we review your message.';
                _this.toastr.successToastr(message_4, 'Success', {
                    position: 'top-center',
                    timeOut: 999999999,
                    extendedTimeOut: 99999
                });
                _this.submitted = false;
                _this.loading = false; //LOADING REMOVED
            }
        }, function (error) {
            _this.loading = false;
        });
    };
    /**
 * MANAGE ACTIVE AND DEACTIVE MENU
 * @param submenu
 */
    SiteFooterComponent.prototype.setActive = function (submenu) {
        console.log(submenu);
        if (submenu == 'personalized_dynamic_videos' || submenu == 'personalized_video_marketing' || submenu == 'dynamic-online-videos' || submenu == 'personalized_social_media_videos' || submenu == 'pre_made_video_templates') {
            $('.solutions').stop().addClass('mactive');
            $('.resources').stop().removeClass('mactive');
            $('.home').stop().removeClass('mactive');
        }
        else if (submenu == 'news' || submenu == 'videos' || submenu == 'case-studies') {
            $('.resources').stop().addClass('mactive');
            $('.solutions').stop().removeClass('mactive');
            $('.home').stop().removeClass('mactive');
        }
        else {
            $('.solutions').stop().removeClass('mactive');
            $('.resources').stop().removeClass('mactive');
            $('.home').stop().removeClass('mactive');
        }
    };
    return SiteFooterComponent;
}());
export { SiteFooterComponent };
