import { OnInit } from '@angular/core';
import { AlertService, FrontendService } from '../../../_services';
import { FormBuilder, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
var LandingPageComponent = /** @class */ (function () {
    function LandingPageComponent(formBuilder, frontendService, toastr, router, alertService, titleService, meta) {
        this.formBuilder = formBuilder;
        this.frontendService = frontendService;
        this.toastr = toastr;
        this.router = router;
        this.alertService = alertService;
        this.titleService = titleService;
        this.meta = meta;
        this.loading = false;
        this.submitted = false;
        this.hide_error = false;
        this.portrait = false;
        this.landscape = false;
    }
    LandingPageComponent.prototype.ngOnInit = function () {
        var _this = this;
        //REDIRECT HTTP TO HTTPS
        if (environment.production) {
            if (location.protocol === 'http:') {
                window.location.href = location.href.replace('http', 'https');
            }
        }
        // CREATE FORM VALIDATION
        this.landingForm = this.formBuilder.group({
            first_name: ['', Validators.required],
            company: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            message: ['', Validators.required],
        });
        this.titleService.setTitle('Video Landing Page | Go campaign'); //SET PAGE TITLE
        this.router.queryParams.subscribe(function (params) {
            var code = params.id;
            localStorage.setItem('_vc', btoa(code));
        });
        var authentication_error = 'You are not authorized to view this page';
        var video_code = atob(localStorage.getItem('_vc')); //GET VIDEO CODE FROM LOCALSTORAGE
        //GET VIDEO URL
        this.frontendService.get_video_data(video_code)
            .pipe(first())
            .subscribe(function (data) {
            var myObjStr = JSON.stringify(data);
            var object = JSON.parse(myObjStr);
            console.log(object);
            if (object) {
                var status_1 = object.status;
                var video_url = object.video_url;
                var recipient_name = object.recipient_name;
                var template_id = object.template_id;
                if (object.status) {
                    if (status_1 == 'success') {
                        if (video_url != '') {
                            _this.video_url = video_url;
                            _this.recipient_name = recipient_name;
                            if (template_id == '309') {
                                _this.portrait = true; //SHOW PORTRAIT DIV
                            }
                            else {
                                _this.landscape = true; //SHOW LANDSCAPE DIV
                            }
                        }
                        else {
                            _this.alertService.error(authentication_error);
                        }
                    }
                    else {
                        _this.hide_error = true;
                        _this.alertService.error(authentication_error);
                    }
                }
                else {
                    _this.hide_error = true;
                    _this.alertService.error(authentication_error);
                }
            }
            else {
                _this.hide_error = true;
                _this.alertService.error(authentication_error);
            }
        }, function (error) {
            _this.hide_error = true;
            _this.alertService.error(authentication_error);
        });
        localStorage.removeItem('_vc'); //REMOVE LOCALSTORAGE
    };
    Object.defineProperty(LandingPageComponent.prototype, "f", {
        get: function () { return this.landingForm.controls; },
        enumerable: true,
        configurable: true
    });
    //ONSUBMIT EVENT 
    LandingPageComponent.prototype.onSubmit = function () {
        var _this = this;
        this.submitted = true;
        // stop here if form is invalid
        if (this.landingForm.invalid) {
            return;
        }
        this.loading = true;
        var data = this.landingForm.value;
        console.log(data);
        var data = this.landingForm.value;
        this.frontendService.campaign_query(data)
            .pipe(first())
            .subscribe(function (data) {
            var myObjStr = JSON.stringify(data);
            console.log(myObjStr);
            var object = JSON.parse(myObjStr);
            var status = object.status;
            if (status == 'unsuccess') {
                var message = "something went wrong please try again";
                _this.toastr.errorToastr(message, 'Error', {
                    position: 'top-right'
                });
                _this.loading = false;
            }
            if (status == 'success') {
                //RESET ALL INPUT FIELD
                _this.landingForm.reset();
                var message = 'Thank you for contacting us. You are very important to us. All information received will always remain confidential. We will contact you as soon as we review your message.';
                _this.toastr.successToastr(message, 'Success', {
                    position: 'top-center'
                });
                _this.submitted = false;
                _this.loading = false; //LOADING REMOVED
            }
        }, function (error) {
            _this.loading = false;
        });
    };
    return LandingPageComponent;
}());
export { LandingPageComponent };
