import { Component, OnInit } from '@angular/core';
import { FrontendService } from '../../../_services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import {MatDialog} from '@angular/material';
import { PlayvideoComponent } from './playvideo/playvideo.component';  //IMPORT CAMPAIGN RECORD COMPONENT
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.styl']
})

export class LandingComponent implements OnInit {

  GetinTouch: boolean = false; 
  GetinTouchForm: FormGroup;
  PerVideoForm: FormGroup;
  submitted: boolean;
  presubmitted: boolean;
  preloading: boolean;
  loading: boolean;
  name: string;
  title:string;
  email:string;
  phone:any;
  message:string;
  address:string;
  


  constructor(
    private frontendService:FrontendService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public toastr: ToastrManager,
    
  ) { }

  ngOnInit() {


     //SET META TAGE START
    this.frontendService.setCurrentPage({currPage: window.location.pathname});


    //VALIDATE FORM
    this.GetinTouchForm = this.formBuilder.group({
      name: ['', Validators.required],
      title: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      message: ['', Validators.required]
  });


      //VALIDATE FORM
      this.PerVideoForm = this.formBuilder.group({
          name: ['',  [Validators.required, Validators.maxLength(20)]]
      });


  }
  
  get p() 
  { 
    return this.GetinTouchForm.controls; 
  }


  /**
   * SUBMIT GET IN TOUCH FORM
   */


  submitGetinTouch(){

      this.submitted = true;
      // stop here if form is invalid
      if (this.GetinTouchForm.invalid) {
          return;
      }
      this.loading = true;
      var data =this.GetinTouchForm.value;
      this.frontendService.GetinTouch(data)
      .pipe(first())
      .subscribe(
          data => {
             const myObjStr = JSON.stringify(data);
             console.log(myObjStr);
             var object=JSON.parse(myObjStr);
             var message: string=object.message;
             if(message=='unsuccess'){
              this.toastr.errorToastr('Oops... Something went wrong please try again!', '', {
                position: 'top-right'
              });
                  this.loading = false;  
             }
             if(message=='success'){ 
                  //RESET ALL INPUT FIELD
                  this.GetinTouchForm.reset();
                  let toaster_message='Thank you for contacting us. You are very important to us. All information received will always remain confidential. We will contact you as soon as we review your message.';
                  this.toastr.successToastr(toaster_message, 'Success', {
                      position: 'top-center'
                  });
                  this.submitted = false;
                  this.loading = false; //LOADING REMOVED
             }   
          },
          error => {
              this.loading = false;
          })
  
    }



  get b() 
  { 
    return this.PerVideoForm.controls; 
  }


    
  /**
   * SUBMIT CREATE MY VIDEO FORM
   */


  submit_personalization_video_form(){

    this.presubmitted = true;
    // stop here if form is invalid
    if (this.PerVideoForm.invalid) {
        return;
    }
    this.preloading = true;
    var data =this.PerVideoForm.value;

        //DETECT DEVICE
        var ua = navigator.userAgent;
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)){
           var device='mobile';
        }else if(/Chrome/i.test(ua)){
           var device='desktop';
        }else{
           var device='desktop';
        }

        localStorage.setItem('_type','landing');    
    
    this.frontendService.personalised_video_request(data)
    .pipe(first())
    .subscribe(
        data => {
           const myObjStr = JSON.stringify(data);
           console.log(myObjStr);
           var object=JSON.parse(myObjStr);
           var message: string=object.message; 
           var url: string=object.url; // GET VIDEO URL

           if(message=='unsuccess'){
                 //Swal.fire('Oops...', 'Something went wrong please try again!', 'error');
                 alert('Oops... Something went wrong please try again!');
                this.preloading = false;  
           }
           if(message=='success'){ 
                //RESET ALL INPUT FIELD
                this.name = '';
                this.presubmitted = false;
                this.preloading = false; //LOADING REMOVED

                if(url){
                  localStorage.setItem('_url',url);
                }

                if(device=='mobile'){
                  var device_width='80%';
                  var device_height='240px';
                }else{
                  var device_width='40%';
                  var device_height='55%';
                }

                //OPEN DIALOG BOX
                let dialogRef = this.dialog.open(PlayvideoComponent, {
                    height: device_height,
                    width: device_width,
                });
          
                dialogRef.afterClosed().subscribe(result => {
                  console.log(`Dialog result: ${result}`);
                });


           }   
        },
        error => {
            this.preloading = false;
        })

  }


}
