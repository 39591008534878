import { Component, OnInit  } from '@angular/core';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.styl']
})
export class NewsComponent implements OnInit {
  constructor() { }
  ngOnInit() {
  }
  scroll(id) {
    let el = document.getElementById(id);
    el.scrollIntoView();
  }
}
