import { environment } from 'src/environments/environment';
var currentUserSubject = JSON.parse(localStorage.getItem('currentUser'));
if (currentUserSubject) {
    var user_id = currentUserSubject.user_id;
    var url = environment.api_base_url + 'app_controller.php?action=image_upload&uid=' + user_id;
}
var DEFAULT_DROPZONE_CONFIG = {
    // Change this to your upload POST address:
    url: url,
    maxFilesize: 50,
    paramName: 'file',
    acceptedFiles: 'image/*'
};
var ɵ0 = DEFAULT_DROPZONE_CONFIG;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
