import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { first } from 'rxjs/operators';
import { FrontendService } from '../../../_services';
import { ToastrManager } from 'ng6-toastr-notifications';
var RequestADemoComponent = /** @class */ (function () {
    function RequestADemoComponent(frontendService, formBuilder, dialog, toastr) {
        this.frontendService = frontendService;
        this.formBuilder = formBuilder;
        this.dialog = dialog;
        this.toastr = toastr;
        this.requestDemo = false;
    }
    RequestADemoComponent.prototype.ngOnInit = function () {
        //SET META TAGE START
        this.frontendService.setCurrentPage({ currPage: window.location.pathname });
        //VALIDATE FORM
        this.requestDemoForm = this.formBuilder.group({
            fname: ['', Validators.required],
            lname: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            company: ['', Validators.required],
            phone: ['', Validators.required],
            interested_solution: ['', Validators.required],
            date_time: ['', Validators.required]
        });
        this.interested_solution = '';
    };
    Object.defineProperty(RequestADemoComponent.prototype, "p", {
        get: function () {
            return this.requestDemoForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    /**
   * SUBMIT CONTACT US FORM
   */
    RequestADemoComponent.prototype.submitRequestDemo = function () {
        var _this = this;
        this.submitted = true;
        // stop here if form is invalid
        if (this.requestDemoForm.invalid) {
            return;
        }
        this.loading = true;
        var data = this.requestDemoForm.value;
        this.frontendService.requestDemo(data)
            .pipe(first())
            .subscribe(function (data) {
            var myObjStr = JSON.stringify(data);
            console.log(myObjStr);
            var object = JSON.parse(myObjStr);
            var message = object.message;
            if (message == 'unsuccess') {
                _this.toastr.errorToastr('Oops... Something went wrong please try again!', '', {
                    position: 'top-right'
                });
                _this.loading = false;
            }
            if (message == 'success') {
                //RESET ALL INPUT FIELD
                _this.requestDemoForm.reset();
                var toaster_message = 'Thank you for contacting us. You are very important to us. All information received will always remain confidential. We will contact you as soon as we review your message.';
                _this.toastr.successToastr(toaster_message, 'Success', {
                    position: 'top-center'
                });
                _this.submitted = false;
                _this.loading = false; //LOADING REMOVED
            }
        }, function (error) {
            _this.loading = false;
        });
    };
    return RequestADemoComponent;
}());
export { RequestADemoComponent };
