import { Component, OnInit } from '@angular/core';
import { FrontendService } from '../../_services';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.styl']
})
export class PageNotFoundComponent implements OnInit {


  constructor(
    private frontendService: FrontendService
  ) { }

  ngOnInit() {

    const current_url = window.location.pathname; 
    this.frontendService.setCurrentPage({currPage: window.location.pathname});


  }

}
