<section _ngcontent-c5="" class="page_title cs s-pt-60 s-pb-10 s-pt-lg-100 s-pb-lg-30 text-center">
  <div _ngcontent-c5="" class="container-fluid">
     <div _ngcontent-c5="" class="row">
        <div _ngcontent-c5="" class="divider-60"></div>
        <div _ngcontent-c5="" class="col-md-12 text-center">
           <h1 _ngcontent-c5="">Privacy policy</h1>
        </div>
        <div _ngcontent-c5="" class="divider-50"></div>
     </div>
  </div>
  <ol _ngcontent-c5="" class="breadcrumb full-width">
     <li _ngcontent-c5="" class="breadcrumb-item"><a _ngcontent-c5="" href="">Home</a></li>
     <li _ngcontent-c5="" class="breadcrumb-item active"> PRIVACY POLICY</li>
  </ol>
</section>

<section class="ls s-pb-20 s-pb-md-60 s-pb-lg s-pb-xl-100 s-pt-75 c-mb-60 c-mb-md-0">
   <div class="container">
      <div class="row">
         <div class="col-sm-12" data-animation="scaleAppear">
            <div class="divider-60 d-none d-md-block"></div>
            <h4>GoCampaignVideos Privacy policy</h4>
            <p>Last updated 01 July 2021</p>
            <br>
            <p>We are glad you are reading this because this information is the best way to understand how GoCampaignVideos uses and manages your Personal Information. This Privacy Policy sets out what information we collect about you, how we use the information we collect, how we share the information we collect, how we transfer your information internationally, and how you can access and control your information. Please read the following carefully, and if you do not agree to our practices regarding your Personal Information, please do not access or use this Site.
            </p>
            <h5 class="h5pp">Definitions</h5>
            <p>In this Privacy Policy, the following definitions apply:</p>
            <ul>
               <li>Any reference to " GoCampaignVideos", "us", "we" or "our" means &ldquo;GoCampaignVideos&rdquo;, </li>
               <li>GDPR means the General Data Protection Regulation</li>
               <li>Personal Information means any information relating to an identified or identifiable natural person and includes information that you provide to us directly, information that we collect from third parties, and information that we collect from your device</li>
               <li>Site means a site operated by us and includes <a href="http://www.gocampaignvideos.com" class="app">gocampaignvideos.com</a> and any other domain that we may use from time to time</li>
            </ul>
            <h5 class="h5pp">Information We Collect</h5>
            <p>We collect the following types of information about you:</p>
            <h5 class="h5pp">Information that you provide to us directly</h5>
            <p>(A) Signing up</p>
            <p>When you sign up for business inquiry, we collect the following Personal Information from you:</p>
            <ul class="ulpp">
               <li>Name</li>
               <li>Address</li>
               <li>Email ID</li>
            </ul>
            <p>(B) Additional support using the chatbot</p>
            <p>When you use our chatbot services, we may ask you to provide us with additional information. This additional information may include:</p>
            <ul class="ulpp">
               <li>Name</li>
               <li>Company</li>
               <li>Mobile number</li>
               <li>Email ID</li>
            </ul>
            <p>Providing or not providing this information is solely upon your discretion. Either way, your ability to use our Site will not be hindered.</p>
            <h5 class="h5pp">Information that we receive from third parties</h5>
            <p>We may receive information about you (including Personal Information) from third parties. If you access our Site through a third-party connection or log-in, for example, through Facebook or Google, that third party may pass certain information about your use of its service to us. This information could include, but is not limited to, the user ID associated with your account, an access token necessary to access that third-party service, any information that you have permitted the third party to share with us, and any information that you have made public in connection with that third-party service. If you'd like to unlink your third-party account from our Site, you can adjust your settings in the third-party service and we will no longer receive information collected about you in connection with that service.</p>
            <h5 class="h5pp">Information that is collected automatically</h5>
            <p>Like many websites, when you visit our Site, we automatically collect certain information through the use of "cookies", including information about your web browser, IP address, and time zone. Some of this information may be classified as Personal Information. Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site and information about how you interact with the Site.</p>
            <p>You can control or reset your cookies through your web browser and, if you choose to, you can refuse all cookies. However, some of the features of our Site may not function properly if you disable the ability to accept cookies. To learn more about our Cookie Policy, please visit <a href="https://gocampaignvideos.com/cookie-notice.html" class="app">https://gocampaignvideos.com/cookie-notice.html</a></p>
            <h5 class="h5pp">How We Use Your Personal Information</h5>
            <p>We use the information we collect about you for the following purposes:</p>
            <ul class="ulpp">
               <li>Providing our products and services: We use the information that you provide to us to service our products and services information to you. This includes allowing you to browse through the product pages, and recognizing you across different pages on our Site</li>
               <li>Communicating with you: We use your contact information to send communications to you about important changes or updates to our products and services. When in line with the preferences you have shared with us, we also provide you with information or advertising related to our products and services</li>
               <li>Performing data analytics: We use the information about you to help us improve our products and services, including by generating analytics about how our customers browse and interact with the Site and to assess the success of our marketing and advertising campaigns</li>
               <li>Customizing our products and services for you: We use information about you to make sure that the Site and our products and services are customized to your needs</li>
               <li>Keeping our Site secure: We use information about you to screen for potential risk and fraud, and to identify violations of this Privacy Policy or our Cookies Notice</li>
               <li>Where required by law: We will use or disclose your information where we reasonably believe that such action is necessary to: (a) comply with the law and the reasonable requests of law enforcement or a government authority; (b) to protect the security and integrity of our Site; and (c) to exercise or protect the rights, property, or personal safety of our employees, our customers or others</li>
               <li>Where you have given your consent: We may seek your consent to use your information for a particular purpose. Where you give your consent for us to do this, we will use your information in accordance with that purpose. You can withdraw your consent to these uses at any time</li>
            </ul>
            <h5 class="h5pp">Disclosure of Personal Information</h5>
            <p>Outside of authorized personnel employed by us, we only share your Personal Information with third-party service providers that meet the data privacy conditions described in this Privacy Policy. These third-party service providers help us use your Personal Information as described above.</p>
            <p>Apart from third-party service providers, we may also disclose your Personal Information in the following situations:</p>
            <ul class="ulpp">
               <li>Under the specific circumstances where we are authorized or required by law to do so</li>
               <li>To respond to requests regarding a criminal investigation or illegal activity</li>
               <li>To defend our rights or to address financial or reputational risks</li>
            </ul>
            <p>Any disclosure of your Personal Information will be done to protect your or our company's vital interests as required by law or when we believe that disclosure is necessary to protect our rights and/or to comply with a judicial proceeding, court order, or legal process served on us, our subsidiaries, affiliates, employees, or agents.</p>
            <p>Your Personal Information will only be used to protect our legitimate interests and will never be provided to other parties for advertising, marketing, or other commercial uses without your consent.</p>
            <p>If GoCampaignVideos Division is involved in a merger, acquisition, or sale of all or a portion of its assets, we may transfer your Personal Information (where relevant) to the successor of the relevant business, and they will be bound to the same obligations that we were previously bound to. You will be notified via email and/or a prominent notice on our website of any change in ownership, uses of your Personal Information, and choices you may have regarding your Personal Information. We may also disclose your Personal Information to any other third party with your prior consent.</p>
            <h5 class="h5pp">Social Media Features</h5>
            <p>Our Site includes Social Media features, such as the "Share" button or the comments section on our blog pages. These features are hosted by third parties and may collect your Personal Information. Your interactions with these features are governed by the privacy statement of the company providing it.</p>
            <h5 class="h5pp">International Transfer of Personal Information</h5>
            <p>Your Personal Information and files are stored on GoCampaignVideos servers and the servers of our third-party service providers. Your Personal Information may be transferred across national borders because we have servers located in the US, and the service providers we hire to help us provide our products and services to you may be located in different countries around the world.</p>
            <p>The information that we collect from you may be transferred to, and stored in, a country outside your country. It may also be processed by staff operating outside the country who work for us or for one of our suppliers. The laws in some countries may not provide as much legal protection for your information. By submitting your Personal Information, you agree to this transfer, storing, or processing. We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this privacy policy.</p>
            <h5 class="h5pp">Your Access and Control of Your Personal Information</h5>
            <p>You may update certain account information by logging into your account and changing your profile settings. You can also stop receiving promotional email communications from us at any time by clicking on the "unsubscribe link" in the relevant communication.</p>
            <p>You may also access, review, update, rectify, or delete your Personal Information by contacting us. We will respond to all requests within 30 days. Please note that deleting your Personal Information may limit our ability to provide our services to you.</p>
            <p>We may limit or reject your request in certain cases, including without limitation where the burden or expense of providing access would be disproportionate to the risks to your privacy in the case in question, where the rights of other persons would be violated, or as required by law or governance purposes.</p>
            <p>If you need further assistance regarding your access and control of your Personal Information, please contact us at <a href="mailto:info@gocampaignvideos.com"  class="app">info@gocampaignvideos.com</a></p>
            <h5 class="h5pp">Security</h5>
            <p>We follow generally accepted industry standards to protect the Personal Information submitted to us once we receive it (including encryption and password protection). Each of our employees is aware of our security policies, and your information is only available to those employees who need it to perform their jobs.</p>
            <p>However, no method of transmission over the Internet using industry standard technology is 100% secure. Therefore, we cannot guarantee the absolute security of your information.</p>
            <h5 class="h5pp">Period of Data Storage</h5>
            <p>We maintain your Personal Information, and other personal information you have provided, for our records because we need this information to operate the accounts you have with us, to deliver our products and services to you, and for other purposes set out in this Privacy Policy. We will retain this information unless and until you ask us to delete this information.</p>
            <h5 class="h5pp">Privacy of Children</h5>
            <p>We do not request, or knowingly collect, any Personal Information from children under the age of 16. The services or products of our Site are suitable for people in the 16 and above age group. If you are younger than 16 years of age, please don't give us your Personal Information or use our Site without parental supervision. We encourage parents or guardians to monitor their children's use of GoCampaignVideos Site. If you are a parent or a guardian and have become aware that a child under the age of 16 has provided us with his or her Personal Information, please contact us at info@gocampaignvideos.com. We will take appropriate measures to suspend and remove this Personal Information immediately.</p>
            <h5 class="h5pp">Third-Party Links</h5>
            <p>Our Site may allow access to third-party content and services. We are not responsible for the practices employed by websites or services accessible from the Site, including the information or content contained within these websites or services. When you use a link to go from our Site to another website, our Privacy Policy does not apply to third-party websites or services. Your browsing and interaction on any third-party website or service that has a link or advertisement on our Site is subject to that third party's own policies. We encourage you to read the policies of all websites that collect Personal Information about you.</p>
            <h5 class="h5pp">For EU Residents</h5>
            <p>If you are a resident of the European Union for the purposes of the GDPR, then in addition to what is set out above, the following applies to you.</p>
            <p>GoCampaignVideos is a data controller and processor for the purposes of the GDPR and by your consenting to this Privacy Policy, GoCampaignVideos is able to process your Personal Information in accordance with this Privacy Policy.</p>
            <p>In providing our products and services to you, we may make use of a number of automated processes using your Personal Information and your activity on our Site as tracked by us, in order to provide optimized services to you.</p>
            <p>For Personal Information subject to the GDPR, we rely on the following legal bases to process this information:</p>
            <ul  class="ulpp">
               <li>Where you have given your consent for us to use your information</li>
               <li>Where the processing is necessary to perform a contract that we have with you, for example when you make a purchase on our Site</li>
               <li>Our legitimate business interests, such as improving and developing our products and services and marketing new features or products (but only where our legitimate interest isn't overridden by your interest in protecting your Personal Information)</li>
               <li>Where it is necessary for compliance with our legal obligations</li>
            </ul>
            <h5 class="h5pp">Consent</h5>
            <p>GoCampaignVideos will obtain your consent before collecting and processing your Personal Information. And you may withdraw your consent to our use of your Personal Information as described in this Privacy Policy by contacting us at info@gocampaignvideos.com.</p>
            <h5 class="h5pp">Right to Access, Update, and Delete Your Personal Information</h5>
            <p>You may request confirmation from us as to whether or not your Personal Information is being processed, where, and for what purpose. We will provide you a copy of the Personal Information, free of charge, in an electronic format. We may ask for proof of identification to verify your access requests. If your Personal Information that we hold is inaccurate or incomplete, please notify us and we will update the relevant information without undue delay. You may also request deletion of your Personal Information in our possession. In certain circumstances we may need to retain your Personal Information, such as:</p>
            <ol class="olpp" >
               <li>where required by law</li>
               <li>where necessary to continue providing services or</li>
               <li>where the burden or expense of deleting such Personal Information would be disproportionate to the risks to your privacy in the case in question or where the rights of other persons would be violated. For example, in cases where we are not the data controller (such as when the personal data was collected on behalf of a third-party data controller), we may need to refer you or your request to the applicable data controller</li>
            </ol>
            <h5 class="h5pp">Right to be Forgotten</h5>
            <p>If you no longer wish to use our Site and services, you may request an erasure of your Personal Information. We will erase your Personal Information and notify you when the erasure is completed. However, we may reject your request of erasure where necessary for compliance with legal obligations. We may also compare your right to be erased with the public interest in the availability of the data when considering your requests of erasure.</p>
            <h5 class="h5pp">Right to Object</h5>
            <p>You may object to our processing of your Personal Information for direct marketing purposes. If you choose to object, we will no longer process your Personal Information for direct marketing purposes, unless there are compelling legitimate grounds for future processing.</p>
            <h5 class="h5pp">Right to Restrict Processing of Your Information</h5>
            <p>You may choose to restrict us from processing your Personal Information when:</p>
            <ol class="olpp">
               <li>you contest the accuracy of your Personal Information</li>
               <li>you oppose the erasure of your Personal Information when the processing is unlawful</li>
               <li>we no longer need the Personal Information to provide our services but you would like to keep your Personal Information available for establishment, exercise, or defense of legal claims; or</li>
               <li>you object to the processing of the Personal Information</li>
            </ol>
            <p>If you choose to restrict processing of your Personal Information, we will only store and will not further process your Personal Information without your consent. However, we may process your Personal Information for the establishment, exercise, or defense of legal claims, or for the protection of the rights of another natural or legal person, or for reasons of important public interest of the European Union or of a EU Member State. If it is necessary for us to process your Personal Information after you choose to restrict, we will inform you before any data processing starts.</p>
            <h5 class="h5pp">Right to be Notified of Data Breach</h5>
            <p>You will receive data breach notification in accordance to applicable laws if we or our data processors become aware of a data breach which is likely to result in a risk for your rights and freedoms.</p>
            <h5 class="h5pp">Right to Data Portability</h5>
            <p>You may request that we provide you with a copy of the Personal Information we hold about you in an electronic format or to transfer your Personal Information directly to a nominated third party.</p>
            <h5 class="h5pp">Right to Lodge a Complaint</h5>
            <p>European residents who believe their rights have been violated by us under the GDPR may lodge a complaint with their local supervisory authority.</p>
            <h5 class="h5pp">Change to This Privacy Policy</h5>
            <p>We reserve the right to update or modify this Privacy Policy at any time and from time to time without prior notice. If we make any material changes to the way we use or disclose your Personal Information, we will notify you by email, at the email address you have provided to us. If you object to any changes, you must cease using our Site. Please review this Privacy Policy periodically by visiting this webpage. Your continued use of our services or Site after publication of any changes or revisions to this Privacy Policy or receipt of the email indicated herein indicates your acceptance of the terms of the revised Privacy Policy.</p>
            <h5 class="h5pp">Contact Us</h5>
            <p>GoCampaignVideos</p>
            <p>8724 Landover Pl</p>
            <p>Irving, TX, 75063</p>
            <p>USA</p>
            <p>Email: <a href="mailto:info@gocampaignvideos.com" class="app">info@gocampaignvideos.com</a></p>
         </div>
      </div>
   </div>
</section>

