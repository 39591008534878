import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-site-header',
  templateUrl: './site-header.component.html',
  styleUrls: ['./site-header.component.styl']
})
export class SiteHeaderComponent implements OnInit {
  currentUserSubject: any;
  login_menu: boolean = true; 
  dashboard_menu: boolean = false; 
  public href: string = "";

  constructor(private router: Router) { }

  ngOnInit() {

    if(localStorage.getItem('currentUser')){
      this.currentUserSubject = JSON.parse(localStorage.getItem('currentUser'));
      let user_id=this.currentUserSubject.user_id;
      if(user_id){
         this.login_menu=false; 
         this.dashboard_menu=true;
      }else{
         this.dashboard_menu=false; 
         this.login_menu=true;
      }
    }


    this.href = this.router.url;
    //IF PAGE LOAD THEN MANAGE ACTIVE MENU
    if(this.href=='/'){
      $('.home').stop().addClass('mactive');
    }else if(this.href=='/personalized-video-marketing.html'){
      $('.solutions').stop().addClass('mactive');
    }


  }

    /**
   * MANAGE ACTIVE AND DEACTIVE MENU
   * @param submenu 
   */

  setActive(submenu){
      console.log(submenu);
      if(submenu=='personalized_dynamic_videos' || submenu=='personalized_video_marketing' || submenu=='dynamic-online-videos' || submenu=='personalized_social_media_videos' || submenu=='pre_made_video_templates'){
         $('.solutions').stop().addClass('mactive');
         $('.resources').stop().removeClass('mactive');
         $('.home').stop().removeClass('mactive');
      }else if(submenu=='news' || submenu=='videos' || submenu=='case-studies' || submenu=='blog' ){
          $('.resources').stop().addClass('mactive');
          $('.solutions').stop().removeClass('mactive');
          $('.home').stop().removeClass('mactive');
        }
      else{
        $('.solutions').stop().removeClass('mactive');
        $('.resources').stop().removeClass('mactive');
        $('.home').stop().removeClass('mactive');
      }
  }

  navbarOpen = false;
  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }


  /**
   * move to dashboard
   */


  movetodashboad(){
    window.location.href = environment.base_url+'/backend/dashboard';
  }

}
