import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookie-notice',
  templateUrl: './cookie-notice.component.html',
  styleUrls: ['./cookie-notice.component.styl']
})
export class CookieNoticeComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit() {
  
  }

}
