import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dynamicvideos',
  templateUrl: './dynamicvideos.component.html',
  styleUrls: ['./dynamicvideos.component.styl']
})
export class DynamicvideosComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit() {
    
  }

}
