<!-----START POPUP------->
<!-----START CONTENT SECTION -->
<div class="video_cont">
  <video width="100%" class="{{className}}" controls autoplay>
      <source src="{{url}}" type="video/mp4">
      Your browser does not support the video tag.
  </video>
</div>
<!-----HEADER SECTION START-->
<button type="button" mat-dialog-close="" id="matClose" class="btn btn-maincolor pp-close-btn">
  <span >Close</span>
</button>
<!-----HEADER SECTION END-->
<!-------END CONTENT SECTION------->