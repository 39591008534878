import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { PlayvideoComponent } from '../landing/playvideo/playvideo.component'; //IMPORT CAMPAIGN RECORD COMPONENT
import { ToastrManager } from 'ng6-toastr-notifications';
import { FrontendService } from 'src/app/_services';
var CruiselineComponent = /** @class */ (function () {
    function CruiselineComponent(frontendService, formBuilder, dialog, toastr) {
        this.frontendService = frontendService;
        this.formBuilder = formBuilder;
        this.dialog = dialog;
        this.toastr = toastr;
    }
    CruiselineComponent.prototype.ngOnInit = function () {
        //SET META TAGE START
        this.frontendService.setCurrentPage({ currPage: window.location.pathname });
        //VALIDATE FORM
        this.PerVideoForm = this.formBuilder.group({
            name: ['', [Validators.required, Validators.maxLength(20)]]
        });
    };
    Object.defineProperty(CruiselineComponent.prototype, "b", {
        get: function () {
            return this.PerVideoForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Play Demo Video
     */
    CruiselineComponent.prototype.playDemoVideo = function (fileNo) {
        console.log('fileName', fileNo);
        //DETECT DEVICE
        var ua = navigator.userAgent;
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
            var device = 'mobile';
        }
        else if (/Chrome/i.test(ua)) {
            var device = 'desktop';
        }
        else {
            var device = 'desktop';
        }
        var files = { 1: 'involve_me', 2: 'hear_me', 3: 'enable_me', 4: 'dazzle_me', 5: 'remember_me' };
        localStorage.setItem('_type', 'cruiseline');
        localStorage.setItem('_url', 'https://gocampaignvideos.com/assets/frontend/videos/cruiseline/' + files[fileNo] + '.mp4');
        if (device == 'mobile') {
            var device_width = '80%';
            var device_height = '90%';
        }
        else {
            var device_width = '40%';
            var device_height = '90%';
        }
        //OPEN DIALOG BOX
        var dialogRef = this.dialog.open(PlayvideoComponent, {
            height: device_height,
            width: device_width,
        });
        dialogRef.afterClosed().subscribe(function (result) {
            console.log("Dialog result: " + result);
        });
    };
    /**
   * SUBMIT CREATE MY VIDEO FORM
   */
    CruiselineComponent.prototype.submit_personalization_video_form = function () {
        var _this = this;
        this.presubmitted = true;
        // stop here if form is invalid
        if (this.PerVideoForm.invalid) {
            return;
        }
        this.preloading = true;
        var data = this.PerVideoForm.value;
        //DETECT DEVICE
        var ua = navigator.userAgent;
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
            var device = 'mobile';
        }
        else if (/Chrome/i.test(ua)) {
            var device = 'desktop';
        }
        else {
            var device = 'desktop';
        }
        localStorage.setItem('_type', 'cruiseline');
        this.frontendService.personalised_video_request(data)
            .pipe(first())
            .subscribe(function (data) {
            var myObjStr = JSON.stringify(data);
            console.log(myObjStr);
            var object = JSON.parse(myObjStr);
            var message = object.message;
            var url = object.url; // GET VIDEO URL
            if (message == 'unsuccess') {
                //Swal.fire('Oops...', 'Something went wrong please try again!', 'error');
                alert('Oops... Something went wrong please try again!');
                _this.preloading = false;
            }
            if (message == 'success') {
                //RESET ALL INPUT FIELD
                _this.name = '';
                _this.presubmitted = false;
                _this.preloading = false; //LOADING REMOVED
                if (url) {
                    localStorage.setItem('_url', url);
                }
                if (device == 'mobile') {
                    var device_width = '80%';
                    var device_height = '80%';
                }
                else {
                    var device_width = '40%';
                    var device_height = '90%';
                }
                //OPEN DIALOG BOX
                var dialogRef = _this.dialog.open(PlayvideoComponent, {
                    height: device_height,
                    width: device_width,
                });
                dialogRef.afterClosed().subscribe(function (result) {
                    console.log("Dialog result: " + result);
                });
            }
        }, function (error) {
            _this.preloading = false;
        });
    };
    return CruiselineComponent;
}());
export { CruiselineComponent };
