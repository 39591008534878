<footer class="page_footer ls ms s-pt-80 s-pb-70 s-py-lg-120 s-pt-xl-105 s-pb-xl-105 c-gutter-60 text-md-left">
    <div class="container">
        <div class="row footer_row">
            <div class="divider-20 d-none d-xl-block"></div>

            <div class="col-md-6 col-xl-3 order-xl-1 order-md-1 animate" data-animation="fadeInUp">

                <div class="widget widget_text">
                        <h5 class="widget-title">CONTACT</h5>

                        <span class="femail-line"><i class="fa fa-envelope color-main"></i> &nbsp; <a href="mailto:info@gocampaignvideos.com" class="femail">info@gocampaignvideos.com</a></span>

                        <!-- <div class="foot-h">USA &nbsp; <a href="tel:+1 484 885 0553" class="fphone">+1 484 885 0553</a></div>
                        
                        <div class="foot-h">AFRICA  &nbsp; <a href="tel:+254 705 385203" class="fphone">+254 705 385203</a></div>
                        
                        <div class="foot-h">MIDDLE EAST  &nbsp; <a href="tel:+971 4 5148451" class="fphone">+971 4 5148451</a></div> -->
                        
                        <div class="foot-h">INDIA &nbsp; <a href="tel:+91 96218 11222" class="fphone">+91 96218 11222</a></div>
                    </div>
            </div>
            <div class="col-md-6 col-xl-3 order-xl-2 order-md-4 animate" data-animation="fadeInUp">
                <div class="widget links">
                    <h4 class="widget-title">Quick Links</h4>
                    <ul>
                        <li class="cat-item li-line1 " (click)="setActive('personalized_dynamic_videos')" >
                            <a routerLink="/personalized-dynamic-videos.html">Personalized Dynamic Videos</a>
                        </li>
                        <li class="cat-item li-line1" (click)="setActive('personalized_video_marketing')">
                            <a routerLink="/personalized-video-marketing.html">Personalized Video Marketing</a>
                        </li>
                        <li class="cat-item  li-line1 " (click)="setActive('dynamic-online-videos')">
                            <a routerLink="/dynamic-online-videos.html">Dynamic Online Videos</a>
                        </li>
                        <li class="cat-item li-line1 " (click)="setActive('personalized_social_media_videos')">
                            <a routerLink="/personalized-social-media-videos.html">Personalized Social Media Videos</a>
                        </li>
                        <li class="cat-item  li-line1 " (click)="setActive('pre_made_video_templates')">
                            <a routerLink="/pre-made-video-templates.html">Pre-made Video Templates</a>
                        </li>
                       
                    </ul>

                </div>
            </div>
            <div class="col-md-6 col-xl-3 order-xl-3 order-md-3 animate" data-animation="fadeInUp">
                <div class="widget services-links">
                    <h4 class="widget-title">&nbsp;</h4>
                    <ul>
                        <li class="cat-item li-line">
                            <a routerLink="/about-us.html" (click)="setActive('about-us')">About Us</a>
                        </li>
                        <li class="cat-item li-line">
                            <a routerLink="/how-it-works.html" (click)="setActive('how-it-works')">How it Works</a>
                        </li>
                        <li class="cat-item li-line" (click)="setActive('how-it-benefits')">
                            <a routerLink="/how-it-benefits.html">How it Benefits</a>
                        </li>
                        
                        <li class="cat-item li-line" (click)="setActive('contact')">
                            <a routerLink="/contact.html">Contact</a>
                        </li>
                        <li class="cat-item li-line" (click)="setActive('request_demo')">								
                           <a routerLink="/request-a-demo.html">Request a Demo</a>
                        </li>
                        
                    </ul>
                </div>
                <div class="divider-20"></div>
            </div>
            <div class="col-md-6 col-xl-3 order-xl-4 order-md-2 animate animated fadeInUp" data-animation="fadeInUp">
                <div class="widget widget_text">
                    <h4 cass="widget-title">Follow Us</h4>
                    <div class="widget widget_social_buttons">
                        <a href="https://www.linkedin.com/company/gocampaignvideos" class="fa fa-linkedin color-bg-icon rounded-icon" title="linkedin" target="_blank"></a>
                    </div>
                    <div class="divider-20"></div>
                    <button id="contact-me1" name="contact-me" class="btn btn-maincolor "><span><a routerLink="/blog.html" class="fbut">Read Blog</a></span></button>
                    <div class="divider-20"></div>
                    <button id="contact-me1" name="contact-me" class="btn btn-maincolor"><span><a target="_blank" href="../../../../assets/frontend/pdfs/gocampaignvideos_overview.pdf" class="fbut">DOWNLOAD BROCHURE</a></span></button>
                    <div class="divider-20"></div>
                    <!-- <span class="spl">© COPYRIGHT 2023 </span>  <span class="spl">GOCAMPAIGNVIDEOS INC.</span> -->
                    <!-- <span class="spl">A</span> <a href="https://www.selfanimate.com/" target="_blank"> <img src="../../../../assets/frontend/images/selfanimate-logo.png" class="fclog" alt="A Selfanimate Private Limited Division"></a> <span class="spl">Division</span> -->
                
                </div>
                <div class="divider-45 d-none d-md-block d-xlz-none"></div>
            </div>
        </div>
    </div>
</footer>


<section class="page_copyright ds s-py-10 copyright-bg">
    <div class="container">
        <div class="row align-items-center">
            <div class="divider-15 d-none d-lg-block"></div>
            <div class="col-md-12 text-center">
                <p>© Copyright <span class="copyright_year">2024 </span>GOCAMPAIGNVIDEOS INC. | All Rights Reserved  |  <a routerLink="/privacy_policy.html"><span class="policy">Privacy Policy</span></a> | <a routerLink="/cookie-notice.html"><span class="policy">Cookie Notice</span></a></p>
                                        </div>
            <div class="divider-15 d-none d-lg-block"></div>
        </div>
    </div>
</section>