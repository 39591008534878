<!-----HEADER SECTION START-->
<div class="row">
  <div class="col-md-10"></div>
  <div class="col-md-2">
    <button type="button" mat-dialog-close="" id="matClose" class="close mat-button-close" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>
<!-----HEADER SECTION END--> 

<!-----START CONTENT SECTION -->
<div class="video_cont">
     <img src="../../../../../assets/gocampaign/images/popup_image.jpg" />
</div>
<hr>
<h6>“The ‘moment of truth’ we want to create must be personalized while remaining professional…the videos are especially important during this period
   as customers are spending more time at home and performing their transactions online.”
   Mr. Antoine Najib Maalouli, CEO, Al Fujairah National Insurance Company (AFNIC). 
   <a target="_blank" href="https://www.meinsurancereview.com/Magazine/ReadMagazineArticle/aid/43468/Staying-connected-in-a-changing-environment">Read full article</a> 
</h6>
<!-------END CONTENT SECTION------->