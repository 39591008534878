import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { first } from 'rxjs/operators';
import { FrontendService } from '../../../_services';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-request-a-demo',
  templateUrl: './request-a-demo.component.html',
  styleUrls: ['./request-a-demo.component.styl']
})
export class RequestADemoComponent implements OnInit {

  requestDemo: boolean = false; 
  requestDemoForm: FormGroup;
  submitted: boolean;
  loading: boolean;
  interested_solution: string;
  fname:string;
  lname:string;
  email:string;
  company:string;
  phone:string;
  date_time:any;

  constructor(
    private frontendService:FrontendService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public toastr: ToastrManager,
  ) { }

  ngOnInit() {
    //SET META TAGE START
    this.frontendService.setCurrentPage({currPage: window.location.pathname});


     //VALIDATE FORM
     this.requestDemoForm = this.formBuilder.group({
          fname: ['', Validators.required],
          lname: ['', Validators.required],
          email: ['', [Validators.required, Validators.email]],
          company: ['', Validators.required],
          phone: ['', Validators.required],
          interested_solution: ['', Validators.required],
          date_time: ['', Validators.required]
      });

      this.interested_solution = '';

  }


  get p() 
  { 
    return this.requestDemoForm.controls; 
  }


  
    /**
   * SUBMIT CONTACT US FORM
   */

    submitRequestDemo(){

      this.submitted = true;
      // stop here if form is invalid
      if (this.requestDemoForm.invalid) {
          return;
      }
      this.loading = true;
      var data =this.requestDemoForm.value;
      this.frontendService.requestDemo(data)
      .pipe(first())
      .subscribe(
          data => {
             const myObjStr = JSON.stringify(data);
             console.log(myObjStr);
             var object=JSON.parse(myObjStr);
             var message: string=object.message;
             if(message=='unsuccess'){
                  this.toastr.errorToastr('Oops... Something went wrong please try again!', '', {
                    position: 'top-right'
                  });
                  this.loading = false;  
             }
             if(message=='success'){ 
                  //RESET ALL INPUT FIELD
                  this.requestDemoForm.reset();
                  let toaster_message='Thank you for contacting us. You are very important to us. All information received will always remain confidential. We will contact you as soon as we review your message.';
                  this.toastr.successToastr(toaster_message, 'Success', {
                      position: 'top-center'
                  });
                  this.submitted = false;
                  this.loading = false; //LOADING REMOVED
             }   
          },
          error => {
              this.loading = false;
          })
  
    }




}
