import { NgModule }      from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule }    from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { AppComponent }  from './app.component';
import { routing }        from './app-routing.module';
import { AlertComponent } from './_components';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatMenuModule} from '@angular/material/menu';
import { MatIconModule, MatSnackBarModule} from '@angular/material';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatButtonModule} from '@angular/material/button';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { NgxLoadingModule } from 'ngx-loading';
import { ToastrModule } from 'ng6-toastr-notifications';
import { PageNotFoundComponent } from './error/page-not-found/page-not-found.component';
import { DataTablesModule } from 'angular-datatables';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTabsModule} from '@angular/material/tabs';
import { MatPaginatorModule, MatTableModule, MatSortModule } from '@angular/material';
import { SiteFooterComponent } from './_layout/site/site-footer/site-footer.component';
import { SiteHeaderComponent } from './_layout/site/site-header/site-header.component';
import { LandingComponent } from './frontend/pages/landing/landing.component';
import { SiteLayoutComponent } from './_layout/site/site-layout/site-layout.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { environment } from 'src/environments/environment';
import { HowitworksComponent } from './frontend/pages/howitworks/howitworks.component';
import { PlayvideoComponent } from './frontend/pages/landing/playvideo/playvideo.component';
import { BlogComponent } from './frontend/pages/blog/blog.component';
import { PostComponent } from './frontend/pages/blog/post/post.component';
import { LandingPageComponent } from './frontend/pages/landing-page/landing-page.component';
import { BlankLayoutComponent } from './_layout/blank-layout/blank-layout.component';
import { DynamicvideosComponent } from './frontend/pages/dynamicvideos/dynamicvideos.component';
import { PremadetemplatesComponent } from './frontend/pages/premadetemplates/premadetemplates.component';
import { BenefitsComponent } from './frontend/pages/benefits/benefits.component';
import { PopupComponent } from './frontend/pages/landing/popup/popup.component';
import { SocialLandingPageComponent } from './frontend/pages/landing-page/social-landing-page/social-landing-page.component';
import { InsuranceComponent } from './frontend/pages/landing-page/insurance/insurance.component';
import { HotelWelcomeComponent } from './frontend/pages/landing-page/beyontect/hotel-welcome/hotel-welcome.component';
import { BankComponent } from './frontend/pages/landing-page/beyontect/bank/bank.component';
import { NoClaimDiscountOfferEnglishComponent } from './frontend/pages/landing-page/beyontect/no-claim-discount-offer-english/no-claim-discount-offer-english.component';
import { RenewalReminderEnglishComponent } from './frontend/pages/landing-page/beyontect/renewal-reminder-english/renewal-reminder-english.component';
import { LifeRenewalReminderEnglishComponent } from './frontend/pages/landing-page/beyontect/life-renewal-reminder-english/life-renewal-reminder-english.component';
import { OnboardingEnglishComponent } from './frontend/pages/landing-page/beyontect/onboarding-english/onboarding-english.component';
import { PersonalizedDynamicVideosComponent } from './frontend/pages/personalized-dynamic-videos/personalized-dynamic-videos.component';
import { PersonalizedVideoMarketingComponent } from './frontend/pages/personalized-video-marketing/personalized-video-marketing.component';
import { PersonalizedSocialMediaVideosComponent } from './frontend/pages/personalized-social-media-videos/personalized-social-media-videos.component';
import { ContactComponent } from './frontend/pages/contact/contact.component';
import { RequestADemoComponent } from './frontend/pages/request-a-demo/request-a-demo.component';
import { AboutComponent } from './frontend/pages/about/about.component';
import { CookieNoticeComponent } from './frontend/pages/cookie-notice/cookie-notice.component';
import { VideosComponent } from './frontend/pages/videos/videos.component';
import { NewsComponent } from './frontend/pages/news/news.component';
import { CaseStudiesComponent } from './frontend/pages/case-studies/case-studies.component';
import { PrivacyPolicyComponent } from './frontend/pages/privacy-policy/privacy-policy.component';
import { PoliticalCampaignPromotionsComponent } from './frontend/pages/political-campaign-promotions/political-campaign-promotions.component';
import { CruiselineComponent } from './frontend/pages/cruiseline/cruiseline.component';

var currentUserSubject=JSON.parse(localStorage.getItem('currentUser'));
if(currentUserSubject){
  var user_id=currentUserSubject.user_id;
  var url=environment.api_base_url+'app_controller.php?action=image_upload&uid='+user_id;
}

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  // Change this to your upload POST address:
   url: url,              
   maxFilesize: 50,
   paramName : 'file',              
   acceptedFiles: 'image/*'
 };

@NgModule({
  imports: [
        BrowserModule,
        ReactiveFormsModule,
        HttpClientModule,
        routing,
        BrowserAnimationsModule,
        NgbModule,
        MatSlideToggleModule,
        MatMenuModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatDialogModule,
        MatFormFieldModule,
        MatSidenavModule,
        MatGridListModule,
        MatButtonModule,
        MatDatepickerModule,
        MatSnackBarModule,
        NgxLoadingModule.forRoot({}),
        ToastrModule.forRoot(),
        DataTablesModule,
        MatTabsModule,
        MatPaginatorModule,
        MatTableModule,
        MatSortModule,
        NgxDropzoneModule,
        DropzoneModule

  ],
  declarations: [
      AppComponent,
      AlertComponent,
      PageNotFoundComponent,
      SiteFooterComponent,
      SiteHeaderComponent,
      LandingComponent,
      SiteLayoutComponent,
      HowitworksComponent,
      PlayvideoComponent,
      BlogComponent,
      PostComponent,
      LandingPageComponent,
      BlankLayoutComponent,
      DynamicvideosComponent,
      PremadetemplatesComponent,
      BenefitsComponent,
      PopupComponent,
      SocialLandingPageComponent,
      InsuranceComponent,
      HotelWelcomeComponent,
      BankComponent,
      NoClaimDiscountOfferEnglishComponent,
      RenewalReminderEnglishComponent,
      LifeRenewalReminderEnglishComponent,
      OnboardingEnglishComponent,
      PersonalizedDynamicVideosComponent,
      PersonalizedVideoMarketingComponent,
      PersonalizedSocialMediaVideosComponent,
      ContactComponent,
      RequestADemoComponent,
      AboutComponent,
      CookieNoticeComponent,
      VideosComponent,
      NewsComponent,
      CaseStudiesComponent,
      PrivacyPolicyComponent,
      PoliticalCampaignPromotionsComponent,
      CruiselineComponent,
      
      
  ],
  entryComponents: [
    PlayvideoComponent,
    PopupComponent
  ],
  providers: [
      { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
      { provide: DROPZONE_CONFIG, useValue: DEFAULT_DROPZONE_CONFIG }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {


 }