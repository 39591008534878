<section class="page_title cs s-pt-60 s-pb-10 s-pt-lg-100 s-pb-lg-30 text-center">
  <div class="container-fluid">
     <div class="row">
        <div class="divider-60"></div>
        <div class="col-md-12 text-center">
           <h1>Contact</h1>
        </div>
        <div class="divider-50"></div>
     </div>
  </div>
  <ol class="breadcrumb full-width">
     <li class="breadcrumb-item">
        <a href="">Home</a>
     </li>
     <li class="breadcrumb-item active">
        Contact
     </li>
  </ol>
</section>
<div class="c-opt-1 fix_m">
  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3348.9929659765253!2d-96.97513518443704!3d32.92478408374271!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c297d6a372771%3A0x866efe0547522436!2sSelfAnimate!5e0!3m2!1sen!2sin!4v1538716118811" width="100%" height="450" frameborder="0" style="border:0" allowfullscreen></iframe>
</div>
<section class="ls s-pb-20 s-pb-md-60 s-pb-lg s-pb-xl-100 s-pt-75  c-mb-md-0">
  <div class="container">
     <h4 class="top-title">Contact</h4>
     <span class="femail-line"><i class="fa fa-envelope color-main"></i> &nbsp; <a href="mailto:info@gocampaignvideos.com" class="femail">info@gocampaignvideos.com</a></span>
     <div class="row">
        <div class="col-sm-3 " >
           <span>&nbsp;</span>
           <div class="foot-h">INDIA</div>
           <i class="fa fa-phone color-main"></i> &nbsp; <a href="tel:+91 96218 11222" class="fphone">+91 96218 11222</a>
           <!-- <div class="foot-h">AFRICA</div>
           <i class="fa fa-phone color-main"></i> &nbsp; <a href="tel:+254 705 385203" class="fphone">+254 705 385203</a> -->
        </div>
        <div class="col-sm-3 " >
           <!-- <span>&nbsp;</span>
           <div class="foot-h">MIDDLE EAST</div>
           <i class="fa fa-phone color-main"></i> &nbsp; <a href="tel:+971 4 5148451" class="fphone">+971 4 5148451</a>
           <div class="foot-h">INDIA</div>
           <i class="fa fa-phone color-main"></i> &nbsp; <a href="tel:+91 95000 74410" class="fphone">+91 95000 74410</a>
           <div class="divider-40"></div> -->
        </div>
        <div class="col-sm-6" data-animation="scaleAppear">
           <div class="divider-60 d-none d-md-block"></div>
            <form [formGroup]="ContatForm" (ngSubmit)="submitContactUs()" class="contact-form c-gutter-10">   
              <div [formGroup]="ContatForm" class="form-group has-placeholder">
                  <label for="name">Full name<span class="required">*</span></label>
                  <input type="text" formControlName="name" [(ngModel)]="name" maxlength="40" placeholder="Name *" class="form-control" [ngClass]="{ 'is-invalid': submitted && p.name.errors }" />
                  <div *ngIf="submitted && p.name.errors" class="invalid-feedback">
                     <div *ngIf="p.name.errors.required">Full Name is required</div>
                  </div>
               </div>

               <div [formGroup]="ContatForm" class="form-group has-placeholder">
                  <label for="email">Email address<span class="required">*</span></label>
                  <input type="text" formControlName="email" [(ngModel)]="email" maxlength="40" placeholder="Email *" class="form-control" [ngClass]="{ 'is-invalid': submitted && p.email.errors }" />
                  <div *ngIf="submitted && p.email.errors" class="invalid-feedback">
                     <div *ngIf="p.email.errors.required">Email is required</div>
                  </div>
               </div>

               <div [formGroup]="ContatForm" class="form-group has-placeholder">
                  <label for="address">Address<span class="required">*</span></label>
                  <input type="text" formControlName="address" [(ngModel)]="address" maxlength="40" placeholder="Address *" class="form-control" [ngClass]="{ 'is-invalid': submitted && p.address.errors }" />
                  <div *ngIf="submitted && p.address.errors" class="invalid-feedback">
                     <div *ngIf="p.address.errors.required">Address is required</div>
                  </div>
               </div>

               <div [formGroup]="ContatForm" class="form-group has-placeholder">
                  <label for="address">Your message<span class="required">*</span></label>
                  <textarea formControlName="message" [(ngModel)]="message" maxlength="150" class="form-control" placeholder="Your message *" [ngClass]="{ 'is-invalid': submitted && p.message.errors }" rows="3"></textarea>
                  <div *ngIf="submitted && p.message.errors" class="invalid-feedback">
                     <div *ngIf="p.message.errors.required">Message is required</div>
                  </div>
               </div>

              <div class="form-group text-center">
                  <div [formGroup]="ContatForm" class="btn_profile">
                        <button [disabled]="loading" class="btn btn-maincolor" id="contact_form_submit"><span>Submit</span></button>
                        <img *ngIf="loading" class="pl-3" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                  </div>
              </div>
           </form>
        </div>

     </div>
  </div>
</section>