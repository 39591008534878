import { Component, OnInit,SimpleChanges  } from '@angular/core';
import { FrontendService } from '../../../../_services';
import { first } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.styl']
})
export class PostComponent implements OnInit {
  posts: string;
  content: string;
  title: any;
  thumbnail: any;
  public post_container = false;
  public laoding = true;
  date: any;
  safeContent: any;
  post_date: any;
  slug:any;


  constructor(
    private frontendService: FrontendService,
    private sanitizer: DomSanitizer,
    private titleService: Title,
    private activatedRoute: ActivatedRoute
  ) { }

  transform(content) {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }


  ngOnInit() {

    this.frontendService.setCurrentPage({currPage: window.location.pathname});
    let slug= this.activatedRoute.snapshot.params.type;
    console.log(slug);

    /**
     * GET RECENT POSTS
     */
    let pagination_data={posts_per_page:6,page:1}
    this.frontendService.GetBlogPosts(pagination_data)
    .pipe(first())
    .subscribe(
      data => {
        const myObjStr = JSON.stringify(data);
        var object=JSON.parse(myObjStr);
        if(object){
            this.posts=object;
        }else{
          this.posts='';
        }
      },
      error => {
        
      })


    /**
     * GET POST DATA
     */  

   this.frontendService.GetPost(slug)
      .pipe(first())
      .subscribe(
          data => {
            const myObjStr = JSON.stringify(data);
            var object=JSON.parse(myObjStr);
            console.log(object);
            if(object){
                this.post_container = true;
                this.laoding = false;
                let content=object.description;
                this.safeContent =  this.sanitizer.bypassSecurityTrustHtml(content);
                this.title=object.title;
                this.thumbnail= object.thumbnail_url;
                this.post_date= object.date;
                let page_title='Blog | '+this.title;
                this.titleService.setTitle(page_title); //SET PAGE TITLE
                
            }else{
              this.posts='';
            }
          },
          error => {
            
          })



  }


  /**
  * REDIRECT TO POST PAGE
  */

 redirect(post_slug){
      localStorage.setItem("post_slug",btoa(post_slug));
      let postSlug = atob(localStorage.getItem("post_slug")); 
      this.laoding = true;
      this.post_container = false;
      this.frontendService.GetPost(postSlug)
      .pipe(first())
      .subscribe(
          data => {
            const myObjStr = JSON.stringify(data);
            var object=JSON.parse(myObjStr);
            if(object){
                this.post_container = true;
                this.laoding = false;
                let content=object.description;
                this.safeContent =  this.sanitizer.bypassSecurityTrustHtml(content);
                this.title=object.title;
                this.thumbnail= object.thumbnail_url;
                this.post_date= object.date;
                let page_title='Blog | '+this.title;
                this.titleService.setTitle(page_title); //SET PAGE TITLE
            }else{
              this.posts='';
            }
          },
          error => {
        
      })

  }

}
