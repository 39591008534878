/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./site-layout.component.styl.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../site-header/site-header.component.ngfactory";
import * as i3 from "../site-header/site-header.component";
import * as i4 from "@angular/router";
import * as i5 from "../site-footer/site-footer.component.ngfactory";
import * as i6 from "../site-footer/site-footer.component";
import * as i7 from "../../../_services/frontend.service";
import * as i8 from "@angular/forms";
import * as i9 from "ng6-toastr-notifications";
import * as i10 from "./site-layout.component";
var styles_SiteLayoutComponent = [i0.styles];
var RenderType_SiteLayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SiteLayoutComponent, data: {} });
export { RenderType_SiteLayoutComponent as RenderType_SiteLayoutComponent };
export function View_SiteLayoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-site-header", [], null, null, null, i2.View_SiteHeaderComponent_0, i2.RenderType_SiteHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.SiteHeaderComponent, [i4.Router], null, null), (_l()(), i1.ɵeld(2, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(3, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-site-footer", [], null, null, null, i5.View_SiteFooterComponent_0, i5.RenderType_SiteFooterComponent)), i1.ɵdid(5, 114688, null, 0, i6.SiteFooterComponent, [i7.FrontendService, i8.FormBuilder, i9.ToastrManager], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 3, 0); _ck(_v, 5, 0); }, null); }
export function View_SiteLayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-site-layout", [], null, null, null, View_SiteLayoutComponent_0, RenderType_SiteLayoutComponent)), i1.ɵdid(1, 114688, null, 0, i10.SiteLayoutComponent, [i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SiteLayoutComponentNgFactory = i1.ɵccf("app-site-layout", i10.SiteLayoutComponent, View_SiteLayoutComponent_Host_0, {}, {}, []);
export { SiteLayoutComponentNgFactory as SiteLayoutComponentNgFactory };
