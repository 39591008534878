import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { first } from 'rxjs/operators';
import { FrontendService } from '../../../_services';
//import Swal from 'sweetalert2';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.styl']
})
export class ContactComponent implements OnInit {

  ContactUs: boolean = false; 
  ContatForm: FormGroup;
  submitted: boolean;
  loading: boolean;
  name:string;
  email:string;
  address:string;
  message:string;

  constructor(
    private frontendService:FrontendService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public toastr: ToastrManager,
  ) { }

  ngOnInit() {

    //SET META TAGE START
    this.frontendService.setCurrentPage({currPage: window.location.pathname});

     //VALIDATE FORM
     this.ContatForm = this.formBuilder.group({
        name: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        address: ['', Validators.required],
        message: ['', Validators.required]
    });


  }

   
  get p() 
  { 
    return this.ContatForm.controls; 
  }


    /**
   * SUBMIT CONTACT US FORM
   */

    submitContactUs(){

      this.submitted = true;
      // stop here if form is invalid
      if (this.ContatForm.invalid) {
          return;
      }
      this.loading = true;
      var data =this.ContatForm.value;
      this.frontendService.ContactUs(data)
      .pipe(first())
      .subscribe(
          data => {
             const myObjStr = JSON.stringify(data);
             console.log(myObjStr);
             var object=JSON.parse(myObjStr);
             var message: string=object.message;
             if(message=='unsuccess'){
                  this.toastr.errorToastr('Oops... Something went wrong please try again!', '', {
                    position: 'top-right'
                  });
                  this.loading = false;  
             }
             if(message=='success'){ 
                  //RESET ALL INPUT FIELD
                  this.ContatForm.reset();
                  let toaster_message='Thank you for contacting us. You are very important to us. All information received will always remain confidential. We will contact you as soon as we review your message.';
                  this.toastr.successToastr(toaster_message, 'Success', {
                      position: 'top-center'
                  });
                  this.submitted = false;
                  this.loading = false; //LOADING REMOVED
             }   
          },
          error => {
              this.loading = false;
          })
  
    }




}
