<section class="page_title cs s-pt-60 s-pb-10 s-pt-lg-100 s-pb-lg-30 page_title text-center">
  <div class="container-fluid">
     <div class="row">
        <div class="divider-60"></div>
        <div class="col-md-12 text-center">
           <h1>Personalized Dynamic Videos</h1>
        </div>
        <div class="divider-50"></div>
     </div>
  </div>
  <ol class="breadcrumb full-width">
     <li class="breadcrumb-item">
        <a href="">Home</a>
     </li>
     <li class="breadcrumb-item">
        <a href="javascript:void(0)">Solutions</a>
     </li>
     <li class="breadcrumb-item active">
        Personalized Dynamic Videos
     </li>
  </ol>
</section>
<section class="ls s-pt-75 s-pb-80 s-pb-lg-100 about-section text-center text-lg-left">
  <div class="d-none d-lg-block divider-10"></div>
  <div class="container">
     <!--<h4 class="top-title text-center">IMPROVE LONG-TERM CUSTOMER VALUE AND REVENUES</h4>-->
     <div class="row align-items-center">
        <div class="col-12 col-lg-6 order-1 order-lg-1">
           <div class="d-none d-lg-block divider-20"></div>
           <div class="item-content">
              <h4 class="top-title text-center">IMPROVE CUSTOMER VALUE AND REVENUES</h4>
              <p class="big">In a digital-first world, winning customers is only half the battle. Keeping them engaged throughout is how you win the war. GoCampaign’s personalized dynamic videos make it easy for you to stay connected to your customers across their brand journey with you.</p>
           </div>
           <div class="divider-20"></div>
           <div style="padding-left: 15px"><button id="rad" h="" name="rad" class="btn btn-maincolor" ><span><a routerLink="/contact.html">Get Started</a></span>
              </button>
           </div>
        </div>
        <div class="col-12 col-lg-6 order-2 order-lg-2">
           <div class="pdvimagehide"><img src="../../../../assets/frontend/images/personalized-dynamic-videos/pdv-infographic.webp" alt="Personalized Dynamic Videos"  /></div>
           <div id="pdvcontainer">
              <ul id="pdvcontinents1">
                 <li class="pdv-1">
                    <a href="javascript:void(0)">
                    <span class="pdvimage1">
                    <img src="../../../../assets/frontend/images/personalized-dynamic-videos/1.webp">
                    </span>
                    </a>
                 </li>
                 <li class="pdv-2">
                    <a href="javascript:void(0)">
                    <span class="pdvimage2">
                    <img class="img-responsive" src="../../../../assets/frontend/images/personalized-dynamic-videos/2.webp">
                    </span>
                    </a>
                 </li>
                 <li class="pdv-3">
                    <a href="javascript:void(0)">
                    <span class="pdvimage3">
                    <img src="../../../../assets/frontend/images/personalized-dynamic-videos/3.webp" class="img-responsive" >
                    </span>
                    </a>
                 </li>
              </ul>
           </div>
        </div>
     </div>
  </div>
</section>
<section class="ls s-pb-50 s-pb-lg-60 s-pb-xl-60 c-mb-30 service-page2">
  <div class="container">
     <div class="row">
        <div class="col-md-4 col-12">
           <div class="hovereffect">
              <img class="img-responsive" src="../../../../assets/frontend/images/personalized-dynamic-videos/boost-customer-lifetime-value.webp" alt="Boost Customer Lifetime Value">
              <div class="overlay">
                 <h2>Boost customer lifetime value</h2>
                 <a class="info" href="javascript:void(0)">Humanize your brand by using personalized videos that communicate with customers at a 1-1 level on new products or feature updates, reminders and after-sales, or to capture his feedback.</a>
              </div>
           </div>
        </div>
        <!-- .col-* -->
        <div class="col-md-4 col-12">
           <div class="hovereffect">
              <img class="img-responsive" src="../../../../assets/frontend/images/personalized-dynamic-videos/cross-sell-up-sell-products.webp" alt="Cross-sell/ Up-sell products">
              <div class="overlay">
                 <h2>Cross-sell / Up-sell products</h2>
                 <a class="info" href="javascript:void(0)">Create continuous revenue streams and accelerate cross-selling or up-selling opportunities by using impactful personalized videos that inform and convert.</a>
              </div>
           </div>
        </div>
        <!-- .col-* -->
        <div class="col-md-4 col-12">
           <div class="hovereffect">
              <img class="img-responsive" src="../../../../assets/frontend/images/personalized-dynamic-videos/schedule-and-automate-video-based-communications.webp" alt="Schedule and Automate Video-Based Communications">
              <div class="overlay">
                 <h2>Automate video-based communications</h2>
                 <a class="info" href="javascript:void(0)">Never miss the chance to send valuable information to customers. Automate the delivery of data-curated video content that is relevant, persuasive, and always leads to action.</a>
              </div>
           </div>
        </div>
        <!-- .col-* -->
     </div>
  </div>
</section>
<!-- Benefits -->
<section class="ls s-pt-50 s-pb-20 s-pt-lg-120 s-pb-lg-20 s-pt-xl-50 c-mb-30 c-mb-lg-50 ls tab">
  <div class="divider-10"></div>
  <div class="container">
     <h4 class="top-title text-center">MOVE FROM INTERRUPTIVE TO RELEVANT<br> CUSTOMER ENGAGEMENTS</h4>
     <p class="text-center">Serve customers with contextual, relevant and personalized dynamic videos to keep them informed and to influence them into taking actions.</p>
     <div class="divider-30"></div>
     <div class="row">
        <div class="col-12">
           <div class="row">
              <div class="col-lg-12">
                 <!-- tabs start -->
                 <ul class="nav nav-tabs" role="tablist" >
                    <li class="nav-item">
                       <a class="nav-link active" id="tab04" data-toggle="tab" href="#tab04_pane" role="tab" aria-controls="tab04_pane" aria-expanded="true">Make every interaction matter</a>
                    </li>
                    <li class="nav-item">
                       <a class="nav-link" id="tab05" data-toggle="tab" href="#tab05_pane" role="tab" aria-controls="tab05_pane">Strengthen your customer outreach</a>
                    </li>
                    <li class="nav-item">
                       <a class="nav-link" id="tab06" data-toggle="tab" href="#tab06_pane" role="tab" aria-controls="tab06_pane">Automate interactions yet be human</a>
                    </li>
                 </ul>
                 <div class="tab-content tab-media" style="background-color: #fff">
                    <div class="tab-pane fade show active" id="tab04_pane" role="tabpanel" aria-labelledby="tab04">
                       <div class="media">
                          <img src="../../../../assets/frontend/images/personalized-dynamic-videos/personalized_campaigns_1.webp" alt="Make every interaction matter" class="solu-noimg">
                          <div class="media-body">
                             <ul class="list-icon list-icon-check list-icon-colored">
                                <li>
                                   Personalized and timely videos across business processes to improve customer involvement and action
                                </li>
                                <li>
                                   Increase mind-share with targeted, contextual videos suiting your business objectives
                                </li>
                                <li>
                                   Influence customer decisions and actions using persuasive personalized dynamic videos
                                </li>
                             </ul>
                          </div>
                       </div>
                    </div>
                    <div class="tab-pane fade" id="tab05_pane" role="tabpanel" aria-labelledby="tab05">
                       <div class="media">
                          <div class="media-body">
                             <ul class="list-icon list-icon-check list-icon-colored">
                                <li>
                                   Automate and execute several video campaigns across multiple customer touchpoints in your business
                                </li>
                                <li>
                                   Move customers from consideration to decision stage faster with proactive, engaging video campaigns
                                </li>
                                <li>
                                   Increase campaign success and ROI with personalized dynamic video campaigns that have higher visibility and open rates
                                </li>
                             </ul>
                          </div>
                          <img src="../../../../assets/frontend/images/personalized-dynamic-videos/personalized_campaigns_2.webp" alt="Strengthen your customer outreach" class="solu-noimg">
                       </div>
                    </div>
                    <div class="tab-pane fade" id="tab06_pane" role="tabpanel" aria-labelledby="tab06">
                       <div class="media">
                          <img src="../../../../assets/frontend/images/personalized-dynamic-videos/personalized_campaigns_3.webp" alt="Automate interactions yet be human" class="solu-noimg">
                          <div class="media-body">
                             <ul class="list-icon list-icon-check list-icon-colored">
                                <li>
                                   Ensure customer stickiness with insights-driven personalized videos that attract
                                </li>
                                <li>
                                   Integrate CRM or contextual data into videos and convert interest to action by focusing on the customer
                                </li>
                                <li>
                                   Keep customer relationship fresh and memorable for your customer using personalized dynamic videos
                                </li>
                             </ul>
                          </div>
                       </div>
                    </div>
                 </div>
                 <!-- tabs end-->
              </div>
           </div>
        </div>
     </div>
  </div>
</section>
<!-- Benefits -->
<!-- End-to-End Solution -->
<section class="page-section page-section__darkest s-pt-80 s-pb-0 s-pt-lg-160 s-pt-lg-160 ls ms" id="section-work">
  <div class="container">
     <h4 class="top-title text-center">END-TO-END SOLUTIONS</h4>
     <div class="divider-70"></div>
     <!-- Timeline -->
     <div class="timeline row end-to-end">
        <div class="col-md-13 style=background">
           <a href="javascript:void(0)" class="">
              <div class="desc-holder leftDiv" >
                 <span class="desc"><img src="../../../../assets/frontend/images/icons/discovery-phase.webp" alt="Discovery Phase"/></span>
              </div>
              <div class="midDiv" align="center">
                 <h5>Discovery<br>Phase</h5>
              </div>
           </a>
           <div class="rightDiv"><img src="../../../../assets/frontend/images/arrow.png"></div>
        </div>
        <div class="col-md-13">
           <a href="javascript:void(0)" class="item-link">
              <div class="desc-holder leftDiv">
                 <span class="desc "><img src="../../../../assets/frontend/images/icons/video-production.webp" alt="Video Production"/></span>
              </div>
              <div class="midDiv">
                 <h5>Video<br>Production</h5>
              </div>
           </a>
           <div class="rightDiv"><img src="../../../../assets/frontend/images/arrow.png"></div>
        </div>
        <div class="col-md-13">
           <a href="javascript:void(0)" class="item-link">
              <div class="desc-holder leftDiv">
                 <div class="desc"><img src="../../../../assets/frontend/images/icons/video-coding.webp" alt="Video Coding"/></div>
              </div>
              <div class="midDiv">
                 <h5>Video<br>Coding</h5>
              </div>
           </a>
           <div class="rightDiv"><img src="../../../../assets/frontend/images/arrow.png"></div>
        </div>
        <div class="col-md-13">
           <a href="javascript:void(0)" class="item-link">
              <div class="desc-holder leftDiv">
                 <span class="desc"><img src="../../../../assets/frontend/images/icons/data-integration.webp" alt="Data Integration"/></span>
              </div>
              <div class="midDiv">
                 <h5>Data<br>Integration</h5>
              </div>
           </a>
           <div class="rightDiv"><img src="../../../../assets/frontend/images/arrow.png"></div>
        </div>
        <div class="col-md-13">
           <a href="javascript:void(0)" class="item-link">
              <div class="desc-holder leftDiv">
                 <span class="desc"><img src="../../../../assets/frontend/images/icons/video-personalization.webp" alt="Video Personalization"/></span>
              </div>
              <!--<div class="midDiv"><h5>Video<br>Personalization</h5></div>-->
              <div class="midDiv">
                 <h5>Personalized <br>Video</h5>
              </div>
           </a>
           <div class="rightDiv"><img src="../../../../assets/frontend/images/arrow.png"></div>
        </div>
        <div class="col-md-13">
           <a href="javascript:void(0)" class="item-link">
              <div class="desc-holder leftDiv">
                 <span class="desc"><img src="../../../../assets/frontend/images/icons/video-delivery.webp" alt="Video Delivery"/></span>
              </div>
              <div class="midDiv">
                 <h5>Video<br>Delivery</h5>
              </div>
           </a>
           <div class="rightDiv"><img src="../../../../assets/frontend/images/arrow.png"></div>
        </div>
        <div class="col-md-13">
           <a href="javascript:void(0)" class="item-link">
              <div class="desc-holder leftDiv">
                 <span class="desc"><img src="../../../../assets/frontend/images/icons/campaign-analytics.webp" alt="Campaign Analytics"/></span>
              </div>
              <div class="midDiv">
                 <h5>Campaign<br>Analytics</h5>
              </div>
           </a>
        </div>
     </div>
     <!-- Timeline / End -->
  </div>
</section>
<!-- End-to-End Solution -->
<!-- Personalized Videos -->
<section class="s-pt-80 s-pb-0 s-pt-lg-160 s-pb-lg-60 s-pt-lg-160 s-pb-lg-60 c-gutter-60  ls pv">
  <div class="container">
     <div class="row">
        <div class="col-lg-8">
           <h4 class="top-title pvt">STAY RELEVANT WITH CUSTOMERS USING PERSONALIZED DYNAMIC VIDEOS</h4>
           <p>Let's talk on the next steps!</p>
        </div>
        <div class="col-lg-3">
           <div class="d-block d-lg-none divider-30"></div>
           <div class="row" style="padding-top: 50px">
              <div class="col-sm-8 gcv-but">
                 <button  id="rad1" h name="rad" class="btn btn-maincolor"><span><a routerLink="/request-a-demo.html">Request a Demo</a></span>
                 </button>
              </div>
              <div class="col-sm-4 gcv-but">
                 <button id="contact-me" name="contact-me" class="btn btn-maincolor"><span><a routerLink="/contact.html">Contact Me</a></span>
                 </button>
              </div>
           </div>
        </div>
     </div>
  </div>
</section>
<!-- Personalized Videos -->