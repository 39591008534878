import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-personalized-dynamic-videos',
  templateUrl: './personalized-dynamic-videos.component.html',
  styleUrls: ['./personalized-dynamic-videos.component.styl']
})
export class PersonalizedDynamicVideosComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit() {



  }

}
