<!---------LOADER SECTION BEGIN--> 
<div class="preloader" *ngIf="laoding">
   <div class="preloader_image"></div>
</div>
<!---------LOADER SECTION END--> 
<!---------PAGE HEADER BEGIN--> 
<section class="page_title cs s-pt-60 s-pb-10 s-pt-lg-100 s-pb-lg-30 text-center" *ngIf="post_container">
   <div class="container-fluid">
      <div class="row">
         <div class="divider-60"></div>
         <div class="col-md-12 text-center">
            <h1>{{title}}</h1>
         </div>
         <div class="divider-50"></div>
      </div>
   </div>
   <ol class="breadcrumb full-width">
      <li class="breadcrumb-item">
         <a routerLink="/blog.html">Blog</a>
      </li>
      <li class="breadcrumb-item active">
         {{title}}
      </li>
   </ol>
</section>
<!---------PAGE HEADER END--> 

<section class="ls s-py-80 s-py-lg-120 s-py-xl-160" *ngIf="post_container"> 
   <div class="container post_detail_container">
      <div class="row c-gutter-60 blog_container">
         <main class="col-lg-7 col-xl-8">
            <article class="text-center text-md-left vertical-item content-padding hero-bg post type-post status-publish format-standard has-post-thumbnail sticky rounded rounded-image-top post-color1">
               <div class="item-media post-thumbnail">
                  <img src="{{thumbnail}}" alt="" srcset="{{thumbnail}}">
               </div>
               <!-- .post-thumbnail -->
               <div class="item-content">
                  <header class="entry-header">
                     <div class="post-data bg-maincolor">
                        <a href="#">
                           <h6>{{post_date | date:"dd" }}<span>{{post_date | date:"MMM" }}</span></h6>
                        </a>
                     </div>
                     <div class="header-content">
                        <h3 class="entry-title">
                              {{title}}
                        </h3>
                     </div>
                  </header>
                  <!-- .entry-header -->
                  <div class="entry-content">
                     <article [innerHTML]='safeContent'></article>
                     <div class="post-footer d-md-flex align-items-center justify-content-between">
                     </div>
                  </div>
                  <!-- .entry-content -->
               </div>
               <!-- .item-content -->
            </article>
            <!-- #post-## -->
         </main>
         <aside class="col-lg-5 col-xl-4">
            <div class="widget widget_recent_posts">
               <h5 class="widget-title">Recent Posts</h5>
               <ul class="list-unstyled">
                  <li class="media" *ngFor="let post of posts;">
                     <a class="media-image"  routerLink="/post/{{post.slug}}" (click)="redirect(post.slug)"> 
                     <img  src="{{post.thumbnail_url}}" alt="">
                     </a>
                     <div class="media-body">
                        <h4 style="margin-top: -18px;">
                           <a routerLink="/post/{{post.slug}}" (click)="redirect(post.slug)"  class="foot-h">{{post.title}}</a>
                        </h4>
                        <p class="item-meta">
                           {{post.date | date:"dd" }} {{post.date | date:"MMM" }} {{post.date | date:"yyyy" }} 
                        </p>
                     </div>
                  </li>
               </ul>
            </div>
         </aside>
      </div>
   </div>
</section>