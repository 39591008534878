<section class="page_title cs s-pt-60 s-pb-10 s-pt-lg-100 s-pb-lg-30 page_title text-center">
   <div class="container-fluid">
      <div class="row">
         <div class="divider-60"></div>
         <div class="col-md-12 text-center">
            <h1>Political Campaign Promotions</h1>
         </div>
         <div class="divider-50"></div>
      </div>
   </div>
   <ol class="breadcrumb full-width">
      <li class="breadcrumb-item">
         <a href="">Home</a>
      </li>
      <li class="breadcrumb-item">
         <a href="javascript:void(0)">Solutions</a>
      </li>
      <li class="breadcrumb-item active">
         Political Campaign Promotions
      </li>
   </ol>
</section>
<!-- Personalised political video campaigns -->
<section class="ls s-pt-75 s-pb-80 s-pb-lg-100 about-section text-center text-lg-left">
   <div class="d-none d-lg-block divider-10"></div>
   <div class="container">
      <div class="row align-items-center">
         <div class="col-12 col-lg-6 order-1 order-lg-1">
            <div class="d-none d-lg-block divider-20"></div>
            <div class="item-content">
               <h4 class="top-title text-center">Personalised political video campaigns</h4>
               <p class="big">Connect with each voter at a one-to-one level, and influence thousands of voters simultaneously using automated personalised political video campaigns. By integrating voter names into dynamic videos, you can address each voter personally in the video. </p>
            </div>
            <div class="divider-20"></div>
            <div style="padding-left: 15px">
               <ul class="list-icon list-icon-check list-icon-colored">
                  <li>Address youth with the best digital campaign medium</li>
                  <li>Grab voter attention and influence public opinion</li>
                  <li>Connect with all voters in their preferred language</li>
                  <li>Get maximum campaign coverageacross constituencies</li>
                  <li>Track campaign results with real-time data analytics</li>
               </ul>
            </div>
            <div class="divider-20"></div>
            <div style="padding-left: 15px">
               <button id="rad" h="" name="rad" class="btn btn-maincolor" ><span><a href="/request-a-demo.html">Sample Video</a></span></button>
               &nbsp; <button id="rad-1" h="" name="rad" class="btn btn-maincolor" ><span><a href="/contact.html">Contact Me</a></span></button>
            </div>
         </div>
         <div class="col-12 col-lg-6 order-2 order-lg-2">
            <div class="ppvc">
               <img src="../../../../assets/frontend/images/political-campaign-promotions/Video-Mockup.png" alt="Video Mockup"  />
            </div>
         </div>
      </div>
   </div>
</section>
<!-- Personalised political video campaigns -->
<!-- SMS text campaigns based on Zip code -->
<section class="ls s-pt-75 s-pb-80 s-pb-lg-100 about-section text-center text-lg-left">
   <div class="d-none d-lg-block divider-10"></div>
   <div class="container">
      <div class="row align-items-center">
         <div class="col-12 col-lg-6 order-1 order-lg-1">
            <div class="d-none d-lg-block divider-20"></div>
            <div class="item-content">
               <h4 class="top-title text-center">SMS text campaigns based on Zip code</h4>
               <p class="big">Mobile marketing using SMS text campaigns are a great way to campaign for votes and encourage people to exercise their votes in your favour. Engage the public with SMS text message politicalcampaigns for wide coverage and  action.</p>
            </div>
            <div class="divider-20"></div>
            <div style="padding-left: 15px">
               <ul class="list-icon list-icon-check list-icon-colored">
                  <li>Wide coverage and reach of voters through SMS</li>
                  <li>Address both smart phone and feature phone users</li>
                  <li>Cost effective campaign tool with instant delivery </li>
                  <li>Track SMS delivery and link clicks </li>
                  <li>Repeat campaign on an automated basis</li>
               </ul>
            </div>
            <div class="divider-20"></div>
            <div style="padding-left: 15px">
               <button id="rad-2" h="" name="rad" class="btn btn-maincolor" ><span><a href="/request-a-demo.html">Request a Demo</a></span></button>
               &nbsp; <button id="rad-3" h="" name="rad" class="btn btn-maincolor" ><span><a href="/contact.html">Contact Me</a></span></button>
            </div>
         </div>
         <div class="col-12 col-lg-6 order-2 order-lg-2">
            <div class="ppvc">
              <img src="../../../../assets/frontend/images/political-campaign-promotions/SMS-Mockup.png" alt="SMS Mockup"  />
            </div>
         </div>
      </div>
   </div>
</section>
<!-- SMS text campaigns based on Zip code -->
<!-- Whatsapp political campaigns for different audiences -->
<section class="ls s-pt-75 s-pb-80 s-pb-lg-100 about-section text-center text-lg-left">
   <div class="d-none d-lg-block divider-10"></div>
   <div class="container">
      <div class="row align-items-center">
         <div class="col-12 col-lg-6 order-1 order-lg-1">
            <div class="d-none d-lg-block divider-20"></div>
            <div class="item-content">
               <h4 class="top-title text-center">Whatsapp political campaigns for different audiences</h4>
               <p class="big">Whatsapp has become an important tool in the political environment. Create connections between yourself and voters onWhatsappand leave a lasting impact on the audience.</p>
            </div>
            <div class="divider-20"></div>
            <div style="padding-left: 15px">
               <ul class="list-icon list-icon-check list-icon-colored">
                  <li>Launch person-to-person or party-to-person campaigns</li>
                  <li>Achieve 99% read rate on your campaigns</li>
                  <li>As the candidate is the ‘product’, humanize the message</li>
                  <li>Cut through the noise, guide voters, and gain trust</li>
                  <li>Share videos, voice/text messages, images, documents etc...</li>
                  <li>Run poll surveys to understand voter feedback</li>
               </ul>
            </div>
            <div class="divider-20"></div>
            <div style="padding-left: 15px">
               <button id="rad-4" h="" name="rad" class="btn btn-maincolor" ><span><a href="/request-a-demo.html">Request a Demo</a></span></button>
               &nbsp; <button id="rad-5" h="" name="rad" class="btn btn-maincolor" ><span><a href="/contact.html">Contact Me</a></span></button>

            </div>
         </div>
         <div class="col-12 col-lg-6 order-2 order-lg-2">
            <div class="ppvc">
               <img src="../../../../assets/frontend/images/political-campaign-promotions/Whatsapp-Mockup.png" alt="Whatsapp Mockup"  />
            </div>
         </div>
      </div>
   </div>
</section>
<!-- Whatsapp political campaigns for different audiences -->  
<!-- Bulk voice calls political campaigns -->
<section class="ls s-pt-75 s-pb-80 s-pb-lg-100 about-section text-center text-lg-left">
   <div class="d-none d-lg-block divider-10"></div>
   <div class="container">
      <div class="row align-items-center">
         <div class="col-12 col-lg-6 order-1 order-lg-1">
            <div class="d-none d-lg-block divider-20"></div>
            <div class="item-content">
               <h4 class="top-title text-center">Bulk voice calls political campaigns</h4>
               <p class="big">In general, a phone call is the ideal form of communication, but when you have to reach thousands of voters, you need an automated bulk voice messaging service. </p>
            </div>
            <div class="divider-20"></div>
            <div style="padding-left: 15px">
               <ul class="list-icon list-icon-check list-icon-colored">
                  <li>Automated voice call service delivers political messages to specific audienceusing toll-free numbers</li>
                  <li>Record and deliver voice messages directly to voter’s phone</li>
                  <li>Add a personal touch with candidate’s or the party’s political message</li>
                  <li>Proven technology in previous campaigns</li>
               </ul>
            </div>
            <div class="divider-20"></div>
            <div style="padding-left: 15px">
               <button id="rad-6" h="" name="rad" class="btn btn-maincolor" ><span><a href="/request-a-demo.html">Request a Demo</a></span></button>
               &nbsp; <button id="rad-7" h="" name="rad" class="btn btn-maincolor" ><span><a href="/contact.html">Contact Me</a></span></button>
            </div>
         </div>
         <div class="col-12 col-lg-6 order-2 order-lg-2">
            <div class="ppvc">
               <img src="../../../../assets/frontend/images/political-campaign-promotions/Phone-Call-Mockup.png" alt="Phone-Call Mockup"  />
            </div>
         </div>
      </div>
   </div>
</section>
<!-- Bulk voice calls political campaigns -->   
<!-- Political social media videos for targeting online voters -->
<section class="ls s-pt-75 s-pb-80 s-pb-lg-100 about-section text-center text-lg-left">
   <div class="d-none d-lg-block divider-10"></div>
   <div class="container">
      <div class="row align-items-center">
         <div class="col-12 col-lg-6 order-1 order-lg-1">
            <div class="d-none d-lg-block divider-20"></div>
            <div class="item-content">
               <h4 class="top-title text-center">Political social media videos for targeting online voters</h4>
               <p class="big">Reach and inspire action from a wide range of online voters using short and engaging videos. Video is an easy-to-understand, persuasive, and effective way to communicate with your online audience, in a visually engaging way.</p>
            </div>
            <div class="divider-20"></div>
            <div style="padding-left: 15px">
               <ul class="list-icon list-icon-check list-icon-colored">
                  <li>Create your video campaign message for social media marketing</li>
                  <li>Convert the video campaigns to achieve maximum results</li>
                  <li>Create professional video messages with live shoot or animated videos</li>
                  <li>GoCampaignVideos offers end-to-end video production services</li>
               </ul>
            </div>
            <div class="divider-20"></div>
            <div style="padding-left: 15px">
               <button id="rad-8" h="" name="rad" class="btn btn-maincolor" ><span><a href="/request-a-demo.html">Request a Demo</a></span></button>
               &nbsp; <button id="rad-9" h="" name="rad" class="btn btn-maincolor" ><span><a href="/contact.html">Contact Me</a></span></button>
            </div>
         </div>
         <div class="col-12 col-lg-6 order-2 order-lg-2">
            <div class="ppvc">
               <img src="../../../../assets/frontend/images/political-campaign-promotions/Facebook-Mockup.png" alt="Facebook Mockup"  />
            </div>
         </div>
      </div>
   </div>
</section>
<!-- Political social media videos for targeting online voters -->      
<!-- Database to target your ideal audience -->
<section class="ls s-pt-75 s-pb-80 s-pb-lg-100 about-section text-center text-lg-left">
   <div class="d-none d-lg-block divider-10"></div>
   <div class="container">
      <div class="row align-items-center">
         <div class="col-12 col-lg-12 order-1 order-lg-1">
            <div class="d-none d-lg-block divider-20"></div>
            <div class="item-content">
               <h4 class="top-title text-center">Database to target your ideal audience</h4>
               <p class="big">Target your most valuable voters or constituency with reliable voter data. GoCampaignVideos, working with database management partners, will provideyou with accurate voter data that can make the difference to your election campaign.</p>
            </div>
            <div class="divider-20"></div>
            <div style="padding-left: 15px">
               <ul class="list-icon list-icon-check list-icon-colored">
                  <li>Voter data profile based on pin code/ voter demographics</li>
                  <li>Voter data profile based on telecom operators</li>
               </ul>
            </div>
            <div class="divider-20"></div>
            <div style="padding-left: 15px">
               <button id="rad-10" h="" name="rad" class="btn btn-maincolor" ><span><a href="/request-a-demo.html">Request a Demo</a></span></button>
               &nbsp; <button id="rad-11" h="" name="rad" class="btn btn-maincolor" ><span><a href="/contact.html">Contact Me</a></span></button>
            </div>
         </div>
      </div>
   </div>
</section>
<!-- Database to target your ideal audience --> 
<!-- Database to target your ideal audience --> 
<section class="s-pt-80 s-pb-0 s-pt-lg-160 s-pb-lg-60 s-pt-lg-160 s-pb-lg-60 c-gutter-60  ls pv">
   <div class="container">
      <div class="row">
         <div class="col-lg-8">
            <h4 class="top-title pvt">Looking for fresh ideas to differentiate your political campaign? We have the right technology and resources to make you win!  </h4>
            <p>Let's talk on the next steps!</p>
         </div>
         <div class="col-lg-3 animate" data-animation="scaleAppear">
            <div class="d-block d-lg-none divider-30"></div>
            <div class="row" style="padding-top: 50px">
               <div class="col-sm-8 gcv-but">
                  <button  id="rad1" h name="rad" class="btn btn-maincolor"><span><a href="/request-a-demo.html">Request a Demo</a></span>
                  </button>
               </div>
               <div class="col-sm-4 gcv-but">
                  <button id="contact-me" name="contact-me" class="btn btn-maincolor"><span><a href="/contact.html">Contact Me</a></span>
                  </button>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
<!-- Personalized Videos -->