/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./case-studies.component.styl.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./case-studies.component";
import * as i3 from "@angular/platform-browser";
import * as i4 from "@angular/router";
var styles_CaseStudiesComponent = [i0.styles];
var RenderType_CaseStudiesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CaseStudiesComponent, data: {} });
export { RenderType_CaseStudiesComponent as RenderType_CaseStudiesComponent };
export function View_CaseStudiesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "section", [["class", "page_title cs s-pt-60 s-pb-10 s-pt-lg-100 s-pb-lg-30 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "divider-60"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "col-md-12 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Case Studies"])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["class", "divider-50"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 5, "ol", [["class", "breadcrumb full-width"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "li", [["class", "breadcrumb-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "a", [["href", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Home"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "li", [["class", "breadcrumb-item active"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Case Studies "])), (_l()(), i1.ɵeld(14, 0, null, null, 0, "article", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["s"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.safeContent; _ck(_v, 14, 0, currVal_0); }); }
export function View_CaseStudiesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-case-studies", [], null, null, null, View_CaseStudiesComponent_0, RenderType_CaseStudiesComponent)), i1.ɵdid(1, 114688, null, 0, i2.CaseStudiesComponent, [i3.DomSanitizer, i4.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CaseStudiesComponentNgFactory = i1.ɵccf("app-case-studies", i2.CaseStudiesComponent, View_CaseStudiesComponent_Host_0, {}, {}, []);
export { CaseStudiesComponentNgFactory as CaseStudiesComponentNgFactory };
