<section class="page_slider">
				<!--<div id="particles-js"></div>-->
				<div class="flexslider" data-nav="false">
					<ul class="slides">
						<li class="ds">
							<img src="../../../../assets/frontend/images/slider01-m.webp" class="sliderm-noimg" alt="Personalized Videos">
							<img src="../../../../assets/frontend/images/slider01.webp" class="slider-noimg" alt="Build, automate & Deliver personalized Videos">
							<div class="container">
								<div class="row">
									<div class="col-md-12">
										<div class="intro_layers_wrapper">
											<div class="intro_layers">
												<div class="intro_layer" data-animation="fadeInRight">
													<h1 class="intro_featured_word">Build,<br>automate &<br>Deliver<br>personalized<br>Videos</h1>
												</div>
												<div class="intro_layer" data-animation="fadeInUp">
														<div id="light">
														  <a class="boxclose" id="boxclose" onclick="lightbox_close();"></a>
														  <video id="VisaChipCardVideo" width="600" controls>
															  <source src="../../../../assets/frontend/videos/home_video.mp4" type="video/mp4">
															</video>
														</div>
														<div>
															<p class="text-uppercase intro_after_featured_word"><a href="../../../../assets/frontend/pdfs/gocampaignvideos_overview.pdf" target="_blank">DOWNLOAD BROCHURE</a></p>
													</div>
												</div>
											</div>
											<div id="fade" onClick="lightbox_close();"></div>
											<!-- eof .intro_layers -->
										</div> <!-- eof .intro_layers_wrapper -->
									</div> <!-- eof .col-* -->
								</div><!-- eof .row -->
							</div><!-- eof .container -->
						</li>

            <li class="ds slide2">
							<img src="../../../../assets/frontend/images/slider02-m.webp" alt="Customer Experience" class="sliderm-noimg">
							<img src="../../../../assets/frontend/images/slider02.webp" alt="Video - Powered Customer Experience Delivered" class="slider-noimg" >
							<div class="container">
								<div class="row">
									<div class="col-md-12">
										<div class="intro_layers_wrapper">
											<div class="intro_layers">
												<div class="intro_layer" data-animation="fadeInRight">
													<h1 class="slide2 intro_featured_word">Video -<br>Powered<br>Customer<br>Experience<br>Delivered</h1>
												</div>
												<div class="intro_layer" data-animation="fadeInUp">
													<div id="light2">
														  <a class="boxclose2" id="boxclose2" onclick="lightbox_close2();"></a>
														  <video id="VisaChipCardVideo2" width="600" controls>
															  <source src="../../../../assets/frontend/videos/home_video.mp4" type="video/mp4">
															</video>
														</div>
														<div id="fade2" onClick="lightbox_close2();"></div>
														<div>
															<p class="text-uppercase intro_after_featured_word"><a href="../../../../assets/frontend/pdfs/gocampaignvideos_overview.pdf" target="_blank">DOWNLOAD BROCHURE</a></p>
												    </div>
											</div> 
										</div> 
									</div>
								</div>
							</div>
             </div><!-- eof .container -->
						</li>

            <li class="ds slide3">
							<img src="../../../../assets/frontend/images/slider03-m.webp" alt="Customers Closer" class="sliderm-noimg">
							<img src="../../../../assets/frontend/images/slider03.webp" alt="Bring Your Brand And Customers Closer" class="slider-noimg">
							<div class="container">
								<div class="row">
									<div class="col-md-12">
										<div class="intro_layers_wrapper">
											<div class="intro_layers">
												<div class="intro_layer" data-animation="fadeInRight">
													<h1 class="slide3 intro_featured_word">Bring<br>Your<br>Brand And<br>Customers<br>Closer</h1>
												</div>
												<div class="intro_layer" data-animation="fadeInUp">
													<div id="light3">
														  <a class="boxclose3" id="boxclose3" onclick="lightbox_close3();"></a>
														  <video id="VisaChipCardVideo3" width="600" controls>
															  <source src="../../../../assets/frontend/videos/home_video.mp4" type="video/mp4">
															  <!--Browser does not support <video> tag -->
															</video>
														</div>
														<div id="fade3" onClick="lightbox_close3();"></div>
														<div>
														  <p class="text-uppercase intro_after_featured_word"><a href="../../../../assets/frontend/pdfs/gocampaignvideos_overview.pdf" target="_blank">DOWNLOAD BROCHURE</a></p>
															<!--<p class="text-uppercase intro_after_featured_word"><a href="#" onclick="lightbox_open3();">Watch video</a></p>-->
												    </div>
                          </div>     
											</div> <!-- eof .intro_layers -->
										</div> <!-- eof .intro_layers_wrapper -->
									</div> <!-- eof .col-* -->
								</div><!-- eof .row -->
							</div><!-- eof .container -->
						</li>
					</ul>
				</div> <!-- eof flexslider -->
				
				<div class="arrow">
					<img src="../../../../assets/frontend/images/bottom.png" alt="bottom arrow">
				</div>
				<div class="animated-arrow">
					<a href="#about">
						<div class="mouse_scroll">
							<span class=""><i class="color-main fa fa-angle-down unu"></i></span>
							<span class=""><i class="color-main fa fa-angle-down doi"></i></span>
							<span class=""><i class="color-main fa fa-angle-down trei"></i></span>
						</div>
					</a>
				</div>
			</section>


      <section class="s-pt-80 s-pb-0 s-pt-lg-160 s-pb-lg-60 s-pt-lg-160 s-pb-lg-60 c-gutter-60 c-mb-50" style="background-color: #f7f6fb">
				<!--<div class="divider-55 d-block"></div>-->
				<div class="container">
				<div class="row">

						<div class="divider-60 d-none d-md-block"></div>
						
					<div class="col-lg-6" id="about">
						<p class="big">As your business grows, so does the importance of staying relevant with your customers. </p>
							<p class="big">
								GoCampaign is an automated video personalization platform that empowers you to build higher customer engagements, drive conversions, and improve brand loyalty by tapping into the power of customer data integrated with dynamic videos. </p>
					</div>

						<div class="col-lg-6 exvideo">
							<h4 class="top-title">Explore video personalization now!</h4>
							<p>Enter your name below and generate your own personalized video</p>
							<form [formGroup]="PerVideoForm" (ngSubmit)="submit_personalization_video_form()" class="form-horizontal service-form"> 
								<div class="row c-mb-10">
									<div class="col-sm-6 gcv-but">
										<div [formGroup]="PerVideoForm" class="form-group has-placeholder">
											<input type="text" [(ngModel)]="name" formControlName="name" maxlength="20" class="form-control" class="form-control" [ngClass]="{ 'is-invalid': presubmitted && b.name.errors }" placeholder="Your name" >
											<div *ngIf="submit_personalization_video_form && b.name.errors" class="invalid-feedback">
											  <div *ngIf="b.name.errors.required">Name is required</div>
											</div>
										  </div>
									</div>
									<div class="col-sm-3 gcv-but">
										<div [formGroup]="PerVideoForm" class="form-group">
											<button [disabled]="preloading" class="btn btn-maincolor" id="madeVideoBtn"><span>CREATE MY VIDEO</span></button>
											<img *ngIf="preloading" class="pl-3" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
										  </div>
									</div>
								</div>
							</form>
						</div>
						<!--.col-* -->
					</div>
				</div>
			</section>


			<section class="ls s-pt-80 s-pb-0 s-pt-lg-160 s-pb-lg-60 s-pt-lg-160 s-pb-lg-60 c-gutter-60 c-mb-30 service-page3">
				<div class="container">
				   <div class="row">
					  <div class="col-lg-12" >
						 <h5 class="special-heading text-center"></h5>
						 <h4 class="text-center top-title">Revolutionize the way you engage with customers and prospects</h4>
						 <p class="text-center">GoCampaign is an innovation-first, data-driven platform to create, deliver and track interactive personalized video experiences. We put intuitive and impactful videos at the heart of your competitive differentiation to engage and influence your customers’ path-to-purchase. Create hundreds or thousands of unique and compelling hyper-personalized videos at one go.</p>
						 <h1 class="text-center big divider-30">Please select your industry</h1>
					  </div>
					  <div class="d-none d-lg-block divider-20"></div>
				   </div>
				   <div class="row">
					  <div class="col-md-6">
						 <div id="accordion01" role="tablist"  aria-multiselectable="true">
							<div class="card">
							   <div class="card-header" role="tab" id="collapse01_header">
								  <h5>
									 <a class="collapsed"  data-toggle="collapse" href="#collapse01" aria-expanded="false" aria-controls="collapse01">
									 Banking
									 </a>
								  </h5>
							   </div>
							   <div id="collapse01" class="collapse" role="tabpanel" aria-labelledby="collapse01_header" data-parent="#accordion01">
								  <div class="card-body">
									 <div class="media">
										<div class="media-body">
										   <div class="wrapper">
											  <div class="youtube" data-embed="fhilYaEjufs">
												 <div class="play-button"></div>
											  </div>
										   </div>
										</div>
									 </div>
								  </div>
							   </div>
							</div>
							<div class="card" onClick="stopVideo()">
							   <div class="card-header" role="tab" id="collapse02_header" >
								  <h5>
									 <a class="collapsed" data-toggle="collapse" href="#collapse02" aria-expanded="false" aria-controls="collapse02" >
									 Insurance
									 </a>
								  </h5>
							   </div>
							   <div id="collapse02" class="collapse" role="tabpanel" aria-labelledby="collapse02_header" data-parent="#accordion01">
								  <div class="card-body">
									 <div class="media">
										<div class="media-body">
										   <div class="wrapper">
											  <div class="youtube" data-embed="yl-KcKA5N8M">
												 <div class="play-button"></div>
											  </div>
										   </div>
										</div>
									 </div>
								  </div>
							   </div>
							</div>
							<div class="card">
							   <div class="card-header" role="tab" id="collapse03_header">
								  <h5>
									 <a class="collapsed" data-toggle="collapse" href="#collapse03" aria-expanded="false" aria-controls="collapse03">
									 Retail (online & In-store)
									 </a>
								  </h5>
							   </div>
							   <div id="collapse03" class="collapse" role="tabpanel" aria-labelledby="collapse03_header" data-parent="#accordion01">
								  <div class="card-body">
									 <div class="media">
										<div class="media-body">
										   <div class="wrapper">
											  <div class="youtube" data-embed="RiJtdHZ8aIo">
												 <div class="play-button"></div>
											  </div>
										   </div>
										</div>
									 </div>
								  </div>
							   </div>
							</div>
							<div class="card">
							   <div class="card-header" role="tab" id="collapse04_header">
								  <h5>
									 <a class="collapsed" data-toggle="collapse" href="#collapse04" aria-expanded="false" aria-controls="collapse04">
									 Healthcare
									 </a>
								  </h5>
							   </div>
							   <div id="collapse04" class="collapse" role="tabpanel" aria-labelledby="collapse04_header" data-parent="#accordion01">
								  <div class="card-body">
									 <div class="media">
										<div class="media-body">
										   <div class="wrapper">
											  <div class="youtube" data-embed="TNvTAn0qzGc">
												 <div class="play-button"></div>
											  </div>
										   </div>
										</div>
									 </div>
								  </div>
							   </div>
							</div>
						 </div>
					  </div>
					  <div class="col-md-6">
						 <div id="accordion02" role="tablist">
							<div class="card">
							   <div class="card-header" role="tab" id="collapse05_header">
								  <h5>
									 <a class="collapsed" data-toggle="collapse" href="#collapse05" aria-expanded="false" aria-controls="collapse05">
									 Manufacturing
									 </a>
								  </h5>
							   </div>
							   <div id="collapse05" class="collapse" role="tabpanel" aria-labelledby="collapse05_header" data-parent="#accordion02">
								  <div class="card-body">
									 <div class="media">
										<div class="media-left">
										</div>
										<div class="media-body">
										   <div class="wrapper">
											  <div class="youtube" data-embed="S-M-S27eZMM">
												 <div class="play-button"></div>
											  </div>
										   </div>
										</div>
									 </div>
								  </div>
							   </div>
							</div>
							<div class="card">
							   <div class="card-header" role="tab" id="collapse06_header">
								  <h5>
									 <a class="collapsed" data-toggle="collapse" href="#collapse06" aria-expanded="false" aria-controls="collapse06">
									 University/Education
									 </a>
								  </h5>
							   </div>
							   <div id="collapse06" class="collapse" role="tabpanel" aria-labelledby="collapse06_header" data-parent="#accordion02">
								  <div class="card-body">
									 <div class="media">
										<div class="media-left">
										</div>
										<div class="media-body">
										   <div class="wrapper">
											  <div class="youtube" data-embed="vSvtyJRc0lU">
												 <div class="play-button"></div>
											  </div>
										   </div>
										</div>
									 </div>
								  </div>
							   </div>
							</div>
							<div class="card">
							   <div class="card-header" role="tab" id="collapse07_header">
								  <h5>
									 <a class="collapsed" data-toggle="collapse" href="#collapse07" aria-expanded="false" aria-controls="collapse07">
									 Telecom
									 </a>
								  </h5>
							   </div>
							   <div id="collapse07" class="collapse" role="tabpanel" aria-labelledby="collapse07_header" data-parent="#accordion02">
								  <div class="card-body">
									 <div class="media">
										<div class="media-left">
										</div>
										<div class="media-body">
										   <div class="wrapper">
											  <div class="youtube" data-embed="NM_P5ay137A">
												 <div class="play-button"></div>
											  </div>
										   </div>
										</div>
									 </div>
								  </div>
							   </div>
							</div>
							<div class="card">
							   <div class="card-header" role="tab" id="collapse08_header">
								  <h5>
									 <a class="collapsed" data-toggle="collapse" href="#collapse08" aria-expanded="false" aria-controls="collapse08">
										Others
									 </a>
								  </h5>
							   </div>
							   <div id="collapse08" class="collapse" role="tabpanel" aria-labelledby="collapse05_header" data-parent="#accordion02">
								  <div class="card-body">
									 <div class="media">
										<div class="media-left">
										</div>
										<div class="media-body">
											<div class="wrapper">
											   <div class="youtube" data-embed="Tg2XcBL-6Es">
												  <div class="play-button"></div>
											   </div>
											</div>
										 </div>
									 </div>
								  </div>
							   </div>
							</div>
						 </div>
					  </div>
					  <div class="d-none d-lg-block divider-20"></div>
				   </div>
				</div>
			 </section>


      
			<section class="ds s-pt-80 s-pb-0 s-pt-lg-160 s-pb-lg-60 s-pt-lg-160 s-pb-lg-60 c-gutter-60 c-mb-50 background-gradient advantage-section cover-background" id="advantage">
				<div class="container">
					<div class="row">
						<div class="col-lg-11 offset-lg-0">
							
							<h4 class="top-title text-center">Deliver exceptional experiences<br> and measurable ROI</h4>
						</div>
					</div>
					<div class="row">
						
						<div class="col-xl-4 col-md-6 animate" data-animation="fadeInUp">
							<div class="icon-box text-center">
								<div class="image-styled ibox">
									<img src="../../../../assets/frontend/images/icons/saas-platform.png" alt="SaaS PLATFORM">
								</div>
								<h5><a href="#">SaaS PLATFORM</a></h5>

								<p>
									Integrates with any IT environment using APIs
								</p>


							</div>
						</div><!-- .col-* -->
						<div class="col-xl-4 col-md-6 animate" data-animation="fadeInUp">
							<div class="icon-box text-center">
								<div class="image-styled ibox">
									<img  src="../../../../assets/frontend/images/icons/data-driven-videos.png" alt="Data-Driven Videos">
								</div>

								<h5>
									<a href="#">DATA-DRIVEN VIDEOS</a>
								</h5>

								<p>
									Personalize each video using specific customer data
								</p>
							</div>
						</div><!-- .col-* -->


						<div class="col-xl-4 col-md-6 animate" data-animation="fadeInUp">
							<div class="icon-box text-center">
								<div class="image-styled ibox">
									<img  src="../../../../assets/frontend/images/icons/multiple-videos-at-once.png" alt="Multiple Videos at Once">
								</div>

								<h5>
									<a href="#">AUTOMATED VIDEOS</a>
								</h5>

								<p>
									Work-flow driven video generation and personalization
								</p>


							</div>
						</div><!-- .col-* -->
						<div class="col-xl-4 col-md-6 animate" data-animation="fadeInUp">
							<div class="icon-box text-center">
								<div class="image-styled ibox">
									<img  src="../../../../assets/frontend/images/icons/language-management.png" alt="Language Management">
								</div>

								<h5>
									<a href="#">LANGUAGE MANAGEMENT</a>
								</h5>

								<p>
									Multiple language options for precision targeting
								</p>


							</div>
						</div><!-- .col-* -->
						<div class="col-xl-4 col-md-6 animate" data-animation="fadeInUp">
							<div class="icon-box text-center">
								<div class="image-styled ibox">
									<img  src="../../../../assets/frontend/images/icons/campaign_analytics.png" alt="Campaign Analytics">
								</div>
								<h5>
									<a href="#">CAMPAIGN ANALYTICS</a>
								</h5>

								<p>
									Track campaign results using rich, real-time analytics
								</p>
							</div>


						</div><!-- .col-* -->
						<div class="col-xl-4 col-md-6 animate" data-animation="fadeInUp">
							<div class="icon-box text-center">
								<div class="image-styled ibox" >
									<img  src="../../../../assets/frontend/images/icons/higher-campaign-roi.png" alt="Higher Campaign ROI">
								</div>

								<h5>
									<a href="#">HIGHER CAMPAIGN ROI</a>
								</h5>

								<p>
									Get a 3X increase in conversions
								</p>
							</div>
						</div><!-- .col-* -->
						<div class="d-none d-lg-block divider-small"></div>

					</div>
				</div>
			</section>

      <section class="s-pt-80 s-pb-0 s-pt-lg-160 s-pb-lg-60 s-pt-lg-160 s-pb-lg-60 c-gutter-60 c-mb-50 ls ms" id="testimonials">
				<div class="container">
					<div class="row">
						<div class="col-lg-10 offset-lg-1">
							<h4 class="top-title text-center">What our customers have to say</h4>
							<div class="d-none d-lg-block divider-small"></div>
						</div>
						<div class="col-md-12">
							<div class="testimonials-slider owl-carousel" data-autoplay="false" data-loop="false" data-responsive-lg="1" data-responsive-md="1" data-responsive-sm="1" data-nav="false" data-dots="true">
								<div class="quote-item">
									<div class="quote-image">
										<img src="../../../../assets/frontend/images/team/testimonials_01.webp" alt="Mr. Antoine Najib Maalouli">
									</div>
									<div class="quote-inner">
										<p class="small-text-h quote-meta">
											Mr. Antoine Najib Maalouli 
										</p>
										<p class="small-text quote-meta">
											CEO, Al Fujairah National Insurance Company (AFNIC). 
										</p>
										<p class="quote-content">
											“The ‘moment of truth’ we want to create must be personalized while remaining professional…the videos are especially important during this period as customers are spending more time at home and performing their transactions online.”
										</p>
									
									</div>
								</div>
							</div><!-- .testimonials-slider -->

						</div>
					</div>
				</div>
			</section>


            <section class="s-pt-80 s-pb-0 s-pt-lg-160 s-pb-lg-60 s-pt-lg-160 s-pb-lg-60 c-gutter-60  ls">
				<div class="container">
				 <div class="row">
					<div class="col-lg-6">
						<h4 class="top-title">Get best results with video personalization</h4>
						<p>Individualize your customer engagements with dynamic personalized videos. Let's talk on the next steps!</p>					
					</div>
						<div class="col-lg-6 animate" data-animation="scaleAppear">
							<div class="d-block d-lg-none divider-30"></div>
								<form [formGroup]="GetinTouchForm" (ngSubmit)="submitGetinTouch()" class="contact-form c-mb-20 c-gutter-20">    	
									<div class="row">
										<div class="col-sm-5">
											<div [formGroup]="GetinTouchForm" class="form-group has-placeholder">
												<label for="name">Name</label>
												<i class="fa fa-user color-main"></i>
												<input type="text" formControlName="name" [(ngModel)]="name" maxlength="40" placeholder="Name *" class="form-control" [ngClass]="{ 'is-invalid': submitted && p.name.errors }" />
												<div *ngIf="submitted && p.name.errors" class="invalid-feedback">
													<div *ngIf="p.name.errors.required">Name is required</div>
												</div>
											</div>
										</div>
										<div class="col-sm-5">
											<div [formGroup]="GetinTouchForm" class="form-group has-placeholder">
												<label for="name">Title</label>
												<i class="fa fa-user color-main"></i>
												<input type="text" formControlName="title" [(ngModel)]="title" maxlength="40" placeholder="Title *" class="form-control" [ngClass]="{ 'is-invalid': submitted && p.title.errors }" />
												<div *ngIf="submitted && p.title.errors" class="invalid-feedback">
													<div *ngIf="p.title.errors.required">Title is required</div>
												</div>
											</div>
										</div>
									</div>
									<div class="row">
									<div class="col-sm-5">
											<div [formGroup]="GetinTouchForm" class="form-group has-placeholder">
												<label for="email">Email</label>
												<i class="fa fa-envelope color-main"></i>
												<input type="text" formControlName="email" [(ngModel)]="email" maxlength="40" placeholder="Email *" class="form-control" [ngClass]="{ 'is-invalid': submitted && p.email.errors }" />
												<div *ngIf="submitted && p.email.errors" class="invalid-feedback">
													<div *ngIf="p.email.errors.required">Email is required</div>
												</div>
											</div>
										</div>
										<div class="col-sm-5">
											<div [formGroup]="GetinTouchForm" class="form-group has-placeholder">
												<label for="phone">Phone</label>
												<i class="fa fa-phone color-main"></i>
												<input type="text" formControlName="phone" [(ngModel)]="phone" maxlength="40" placeholder="Phone *" class="form-control" [ngClass]="{ 'is-invalid': submitted && p.phone.errors }" />
												<div *ngIf="submitted && p.phone.errors" class="invalid-feedback">
													<div *ngIf="p.phone.errors.required">Phone is required</div>
												</div>
											</div>
										</div>
									</div>
									<div class="row">
									<div class="col-sm-10">
											<div [formGroup]="GetinTouchForm" class="form-group has-placeholder">
												<label for="phone">Subject</label>
												<i class="fa fa-edit color-main"></i>
												<textarea formControlName="message" [(ngModel)]="message" maxlength="150" class="form-control" placeholder="Your message *" [ngClass]="{ 'is-invalid': submitted && p.message.errors }" rows="3"></textarea>
												<div *ngIf="submitted && p.message.errors" class="invalid-feedback">
													<div *ngIf="p.message.errors.required">Message is required</div>
												</div>
											</div>
										</div>
									</div>
									
									<div class="row">
										<div class="col-sm-12 text-center text-md-left ">
											<div [formGroup]="GetinTouchForm" class="btn_profile">
													<button [disabled]="loading" class="btn btn-maincolor" id="contact_form_submit">Submit</button>
													<img *ngIf="loading" class="pl-3" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
											</div>
										</div>
									</div>
								</form>
								<img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=3377084&conversionId=4504596&fmt=gif" />
								<div class="divider-20"></div>
						</div>
						<!--.col-* -->
					</div>
        </div>  
			</section>
			