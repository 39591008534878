<section class="page_title cs s-pt-60 s-pb-10 s-pt-lg-100 s-pb-lg-30 page_title text-center">
  <div class="container-fluid">
     <div class="row">
        <div class="divider-60"></div>
        <div class="col-md-12 text-center">
           <h1>404 Error Page</h1>
        </div>
        <div class="divider-50"></div>
     </div>
  </div>
  <ol class="breadcrumb full-width">
     <li class="breadcrumb-item">
        <a href="">Home</a>
     </li>
     <li class="breadcrumb-item active">
      404 Error Page
     </li>
  </ol>
</section>

<section class="ls s-pt-75 s-pb-80 s-pb-lg-100 about-section text-center">
  <div class="d-none d-lg-block divider-10"></div>
  <div class="container ">
     <h4 class="top-title text-center">Oops! Page not found.</h4>
     <div class="row align-items-center">
        <div class="col-12 col-lg-12 order-1 order-lg-1">
           <div class="d-none d-lg-block divider-20"></div>
           <div class="item-content">
              <p class="big"> We could not find the page you were looking for.Meanwhile, you may <a style="color: #007bff;"  href="">return to home</a></p>
           </div>
        </div>
     </div>
  </div>
</section>