<section class="page_title cs s-pt-60 s-pb-10 s-pt-lg-100 s-pb-lg-30 text-center">
  <div class="container-fluid">
     <div class="row">
        <div class="divider-60"></div>
        <div class="col-md-12 text-center">
           <h1>Request a Demo</h1>
        </div>
        <div class="divider-50"></div>
     </div>
  </div>
  <ol class="breadcrumb full-width">
     <li class="breadcrumb-item">
        <a href="">Home</a>
     </li>
     <li class="breadcrumb-item active">
        Request a Demo
     </li>
  </ol>
</section>
<section class="ls s-pb-20 s-pb-md-60 s-pb-lg s-pb-xl-100 s-pt-75 c-mb-60 c-mb-md-0">
  <div class="container">
      <form [formGroup]="requestDemoForm" (ngSubmit)="submitRequestDemo()" class="contact-form c-gutter-10">      
        <div class="row">
           <p class="big align-items-center">See how GoCampaign transforms customer engagements using personalized videos. Schedule your demo today!</p>
           <div class="col-sm-5" data-animation="scaleAppear">
              <div class="divider-60 d-none d-md-block"></div>
              <h6>Your Personal Details </h6>
              <div [formGroup]="requestDemoForm" class="form-group has-placeholder">
                  <label for="fname">First Name <span class="required">*</span></label>
                  <input type="text" formControlName="fname" [(ngModel)]="fname" maxlength="40" pattern="[a-zA-Z ]*" placeholder="First Name *" class="form-control" [ngClass]="{ 'is-invalid': submitted && p.fname.errors }" />
                  <div *ngIf="submitted && p.fname.errors" class="invalid-feedback">
                     <div *ngIf="p.fname.errors.required">Full Name is required</div>
                  </div>
              </div>
              <div [formGroup]="requestDemoForm" class="form-group has-placeholder">
                  <label for="lname">Last Name <span class="required">*</span></label>
                  <input type="text" formControlName="lname" [(ngModel)]="lname" maxlength="40" pattern="[a-zA-Z ]*" placeholder="Last Name *" class="form-control" [ngClass]="{ 'is-invalid': submitted && p.lname.errors }" />
                  <div *ngIf="submitted && p.lname.errors" class="invalid-feedback">
                     <div *ngIf="p.lname.errors.required">Last Name is required</div>
                  </div>
              </div>

              <div [formGroup]="requestDemoForm" class="form-group has-placeholder">
                  <label for="email">Business Email <span class="required">*</span></label>
                  <input type="email" formControlName="email" [(ngModel)]="email" maxlength="40" [email]="true" placeholder="Business Email Name *" class="form-control" [ngClass]="{ 'is-invalid': submitted && p.email.errors }" />
                  <div *ngIf="submitted && p.email.errors" class="invalid-feedback">
                     <div *ngIf="p.email.errors.required">Email is required</div>
                     <div *ngIf="p.email.errors.email">Email must be a valid email address</div>
                  </div>
              </div>

              <div [formGroup]="requestDemoForm" class="form-group has-placeholder">
                  <label for="company">Company <span class="required">*</span></label>
                  <input type="text" formControlName="company" [(ngModel)]="company" maxlength="40" placeholder="Company Name *" class="form-control" [ngClass]="{ 'is-invalid': submitted && p.company.errors }" />
                  <div *ngIf="submitted && p.company.errors" class="invalid-feedback">
                     <div *ngIf="p.company.errors.required">Company is required</div>
                  </div>
              </div>

              <div [formGroup]="requestDemoForm" class="form-group has-placeholder">
                  <label for="phone">Phone number <span class="required">*</span></label>
                  <input type="text" formControlName="phone" [(ngModel)]="phone" maxlength="40" placeholder="Phone Number *" class="form-control" [ngClass]="{ 'is-invalid': submitted && p.phone.errors }" />
                  <div *ngIf="submitted && p.phone.errors" class="invalid-feedback">
                     <div *ngIf="p.phone.errors.required">Phone number is required</div>
                  </div>
              </div>
             
           </div>
           <div class="col-sm-1"></div>
           <div class="col-sm-5">
              <div class="divider-60 d-none d-md-block"></div>
              <div [formGroup]="requestDemoForm" class="form-group has-placeholder">
                  <h6>Interested solution</h6>
                  <select class="form-control" formControlName="interested_solution" [(ngModel)]="interested_solution" [ngClass]="{ 'is-invalid': submitted && p.interested_solution.errors }">
                     <option value="" >Select Interested solution</option>
                     <option value="Personalized dynamic videos">Personalized dynamic videos</option>
                     <option value="Personalized video marketing"> Personalized video marketing</option>
                     <option value="Dynamic online videos">Dynamic online videos</option>
                     <option value="Personalized social media videos">Personalized social media videos</option>
                     <option value="Pre-made video templates">Pre-made video templates</option>
               </select>
               <div *ngIf="submitted && p.interested_solution.errors" class="invalid-feedback">
                  <div *ngIf="p.interested_solution.errors.required">Duration of video is required</div>
               </div>
            </div>

              <div [formGroup]="requestDemoForm" class="form-group has-placeholder">
                  <label for="date-time">Enter preferred date and time for the demo <span class="required">*</span></label>
                  <input type="datetime-local" formControlName="date_time" [(ngModel)]="date_time" maxlength="40" placeholder="Date Time *" id="date-time" class="form-control" [ngClass]="{ 'is-invalid': submitted && p.date_time.errors }" />
                  <div *ngIf="submitted && p.date_time.errors" class="invalid-feedback">
                     <div *ngIf="p.date_time.errors.required">date and time is required</div>
                  </div>
            </div>

           </div>
           <div class="divider-20"></div>
            <div class="form-group text-center" style="padding-left: 5px">
               <div [formGroup]="requestDemoForm" >
                  <button [disabled]="loading"  type="submit" class="btn btn-maincolor" id="contact_form_submit"><span>Request a Demo </span></button>
                  <img *ngIf="loading" class="pl-3" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
               </div>
            </div>
        </div>
     </form>
     <div class="divider-20"></div>
  </div>
</section>