<section class="page_title cs s-pt-60 s-pb-10 s-pt-lg-100 s-pb-lg-30 text-center">
  <div class="container-fluid">
     <div class="row">
        <div class="divider-60"></div>
        <div class="col-md-12 text-center">
           <h1>Case Studies</h1>
        </div>
        <div class="divider-50"></div>
     </div>
  </div>
  <ol class="breadcrumb full-width">
     <li class="breadcrumb-item">
        <a href="">Home</a>
     </li>
     <li class="breadcrumb-item active">
        Case Studies
     </li>
  </ol>
</section>
<article [innerHTML]='safeContent'></article>s