import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-site-layout',
  templateUrl: './site-layout.component.html',
  styleUrls: ['./site-layout.component.styl']
})
export class SiteLayoutComponent implements OnInit {
  public href: string = "";

  constructor(private router: Router) {

    this.href = this.router.url;
    console.log(this.router.url);

   }

  ngOnInit() {
   
    // //REDIRECT HTTP TO HTTPS
    // if (environment.production) {
    //   if (location.protocol === 'http:') {
    //       window.location.href = location.href.replace('http', 'https');
    //     }
    // }

  }

}
