import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-premadetemplates',
  templateUrl: './premadetemplates.component.html',
  styleUrls: ['./premadetemplates.component.styl']
})
export class PremadetemplatesComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit() {
    
  }

}
