import { Component, OnInit, ɵConsole } from '@angular/core';
import { AlertService,FrontendService } from '../../../_services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ActivatedRoute } from '@angular/router';
import { Title,Meta } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.styl']
})
export class LandingPageComponent implements OnInit {

  landingForm: FormGroup;
  loading = false;
  submitted = false;
  video_url: string;
  hide_error: boolean = false;
  recipient_name: any;
  portrait = false;
  landscape = false;

  constructor(
    private formBuilder: FormBuilder,
    private frontendService: FrontendService,
    public toastr: ToastrManager,
    private router: ActivatedRoute,
    private alertService : AlertService,
    private titleService: Title,
    private meta : Meta
  ) { }

  ngOnInit() {


    //REDIRECT HTTP TO HTTPS
    if (environment.production) {
        if (location.protocol === 'http:') {
          window.location.href = location.href.replace('http', 'https');
        }
    }

     // CREATE FORM VALIDATION
     this.landingForm = this.formBuilder.group({
          first_name: ['', Validators.required],
          company: ['', Validators.required],
          email: ['', [Validators.required, Validators.email]],
          message:  ['', Validators.required],
      });

      this.titleService.setTitle('Video Landing Page | Go campaign'); //SET PAGE TITLE

      this.router.queryParams.subscribe(params => {
          let code:string=params.id;
          localStorage.setItem('_vc',btoa(code));
      });

      let authentication_error='You are not authorized to view this page';
      var video_code=atob(localStorage.getItem('_vc')); //GET VIDEO CODE FROM LOCALSTORAGE
      //GET VIDEO URL
      this.frontendService.get_video_data(video_code)
      .pipe(first())
      .subscribe(
        data => {
          const myObjStr = JSON.stringify(data);
          var object=JSON.parse(myObjStr);
          console.log(object);
          if(object){
            let status=object.status;
            let video_url=object.video_url;
            let recipient_name=object.recipient_name;
            let template_id=object.template_id;
            if(object.status){
              if(status=='success'){
                if(video_url!=''){
                  this.video_url=video_url;
                  this.recipient_name=recipient_name;
                  if(template_id=='309'){
                    this.portrait=true; //SHOW PORTRAIT DIV
                  }else{
                    this.landscape=true; //SHOW LANDSCAPE DIV
                  }
                  
                }else{
                  this.alertService.error(authentication_error);
                }  
              }else{
                this.hide_error = true;
                this.alertService.error(authentication_error);
              }
            }else{
              this.hide_error = true;
              this.alertService.error(authentication_error);
            }      
          }else{
            this.hide_error = true;
            this.alertService.error(authentication_error);
          }
        
        },
        error => {
            this.hide_error = true;
            this.alertService.error(authentication_error);
        });

        localStorage.removeItem('_vc'); //REMOVE LOCALSTORAGE


  }

  get f() { return this.landingForm.controls; }

  //ONSUBMIT EVENT 
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.landingForm.invalid) {
        return;
    }
    this.loading = true;
    var data =this.landingForm.value;
    console.log(data);

    var data =this.landingForm.value;
    this.frontendService.campaign_query(data)
    .pipe(first())
    .subscribe(
        data => {
           const myObjStr = JSON.stringify(data);
           console.log(myObjStr);
           var object=JSON.parse(myObjStr);
           var status: string=object.status;
           if(status=='unsuccess'){
                let message="something went wrong please try again";
                this.toastr.errorToastr(message, 'Error', {
                  position: 'top-right'
                });
                this.loading = false;  
           }
           if(status=='success'){ 
                //RESET ALL INPUT FIELD
                this.landingForm.reset();
                let message='Thank you for contacting us. You are very important to us. All information received will always remain confidential. We will contact you as soon as we review your message.';
                this.toastr.successToastr(message, 'Success', {
                    position: 'top-center'
                });
                this.submitted = false;
                this.loading = false; //LOADING REMOVED
           }   
        },
        error => {
            this.loading = false;
        })




  }




}
