import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-personalized-video-marketing',
  templateUrl: './personalized-video-marketing.component.html',
  styleUrls: ['./personalized-video-marketing.component.styl']
})
export class PersonalizedVideoMarketingComponent implements OnInit {
 

  constructor(
  ) { }

  ngOnInit() {

    
  }

}
