import { OnInit } from '@angular/core';
import { FrontendService } from '../../../_services';
import { FormBuilder, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { PlayvideoComponent } from './playvideo/playvideo.component'; //IMPORT CAMPAIGN RECORD COMPONENT
import { ToastrManager } from 'ng6-toastr-notifications';
var LandingComponent = /** @class */ (function () {
    function LandingComponent(frontendService, formBuilder, dialog, toastr) {
        this.frontendService = frontendService;
        this.formBuilder = formBuilder;
        this.dialog = dialog;
        this.toastr = toastr;
        this.GetinTouch = false;
    }
    LandingComponent.prototype.ngOnInit = function () {
        //SET META TAGE START
        this.frontendService.setCurrentPage({ currPage: window.location.pathname });
        //VALIDATE FORM
        this.GetinTouchForm = this.formBuilder.group({
            name: ['', Validators.required],
            title: ['', Validators.required],
            phone: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            message: ['', Validators.required]
        });
        //VALIDATE FORM
        this.PerVideoForm = this.formBuilder.group({
            name: ['', [Validators.required, Validators.maxLength(20)]]
        });
    };
    Object.defineProperty(LandingComponent.prototype, "p", {
        get: function () {
            return this.GetinTouchForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * SUBMIT GET IN TOUCH FORM
     */
    LandingComponent.prototype.submitGetinTouch = function () {
        var _this = this;
        this.submitted = true;
        // stop here if form is invalid
        if (this.GetinTouchForm.invalid) {
            return;
        }
        this.loading = true;
        var data = this.GetinTouchForm.value;
        this.frontendService.GetinTouch(data)
            .pipe(first())
            .subscribe(function (data) {
            var myObjStr = JSON.stringify(data);
            console.log(myObjStr);
            var object = JSON.parse(myObjStr);
            var message = object.message;
            if (message == 'unsuccess') {
                _this.toastr.errorToastr('Oops... Something went wrong please try again!', '', {
                    position: 'top-right'
                });
                _this.loading = false;
            }
            if (message == 'success') {
                //RESET ALL INPUT FIELD
                _this.GetinTouchForm.reset();
                var toaster_message = 'Thank you for contacting us. You are very important to us. All information received will always remain confidential. We will contact you as soon as we review your message.';
                _this.toastr.successToastr(toaster_message, 'Success', {
                    position: 'top-center'
                });
                _this.submitted = false;
                _this.loading = false; //LOADING REMOVED
            }
        }, function (error) {
            _this.loading = false;
        });
    };
    Object.defineProperty(LandingComponent.prototype, "b", {
        get: function () {
            return this.PerVideoForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * SUBMIT CREATE MY VIDEO FORM
     */
    LandingComponent.prototype.submit_personalization_video_form = function () {
        var _this = this;
        this.presubmitted = true;
        // stop here if form is invalid
        if (this.PerVideoForm.invalid) {
            return;
        }
        this.preloading = true;
        var data = this.PerVideoForm.value;
        //DETECT DEVICE
        var ua = navigator.userAgent;
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
            var device = 'mobile';
        }
        else if (/Chrome/i.test(ua)) {
            var device = 'desktop';
        }
        else {
            var device = 'desktop';
        }
        localStorage.setItem('_type', 'landing');
        this.frontendService.personalised_video_request(data)
            .pipe(first())
            .subscribe(function (data) {
            var myObjStr = JSON.stringify(data);
            console.log(myObjStr);
            var object = JSON.parse(myObjStr);
            var message = object.message;
            var url = object.url; // GET VIDEO URL
            if (message == 'unsuccess') {
                //Swal.fire('Oops...', 'Something went wrong please try again!', 'error');
                alert('Oops... Something went wrong please try again!');
                _this.preloading = false;
            }
            if (message == 'success') {
                //RESET ALL INPUT FIELD
                _this.name = '';
                _this.presubmitted = false;
                _this.preloading = false; //LOADING REMOVED
                if (url) {
                    localStorage.setItem('_url', url);
                }
                if (device == 'mobile') {
                    var device_width = '80%';
                    var device_height = '240px';
                }
                else {
                    var device_width = '40%';
                    var device_height = '55%';
                }
                //OPEN DIALOG BOX
                var dialogRef = _this.dialog.open(PlayvideoComponent, {
                    height: device_height,
                    width: device_width,
                });
                dialogRef.afterClosed().subscribe(function (result) {
                    console.log("Dialog result: " + result);
                });
            }
        }, function (error) {
            _this.preloading = false;
        });
    };
    return LandingComponent;
}());
export { LandingComponent };
