import { Component, OnInit } from '@angular/core';
import { FrontendService } from '../../../_services';
import { first } from 'rxjs/operators';
import {Router} from '@angular/router';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.styl']
})
export class BlogComponent implements OnInit {
  [x: string]: any;
  posts: any;
  public post_container = false;
  public laoding = true;
  public previous_pagination_btn = false;
  public next_pagination_btn = false;
  public recent_posts_container = false;
  next_pagination_link: any;
  next_pagination_param: { posts_per_page: string; page: string; };
  posts_per_page: any;
  page: number;
  _bp : any;
  _bppg  :any;
  next_page: any;
  previous_page: number;
  recent_posts: any;
  constructor(
    private frontendService: FrontendService,
    private router: Router
  ) { 

  }

  ngOnInit() {


      localStorage.setItem("_bppg",'50'); //SET DEFAULT POST PER PAGE
      localStorage.setItem("_bp",btoa('1')); //DEFAULT PAGE IS ONE
      this.frontendService.setCurrentPage({currPage: window.location.pathname});

      
      /**
      * GET BLOG POST DATA
      */

      var _bppg = localStorage.getItem("_bppg"); //GET POSTS PER PAGE
      var _bp = atob(localStorage.getItem("_bp"));

      let pagination_data={posts_per_page:_bppg,page:_bp}
      this.frontendService.GetBlogPosts(pagination_data)
      .pipe(first())
      .subscribe(
          data => {
            const myObjStr = JSON.stringify(data);
            var object=JSON.parse(myObjStr);
            console.log(object);
            if(object){
                if(_bp=='1'){
                  this.next_pagination_btn=false;
                }
                if(object.pages>_bp){
                  this.next_pagination_btn=true;
                  this.posts_per_page=_bppg;
                  var page=parseInt(_bp)+1;
                  this.page=page;;
                }
                this.posts=object;
                this.post_container = true;
                this.laoding = false;
            }else{
              this.posts='';
            }
          },
          error => {
            
          })


          /**
           * GET RECENT POSTS
           */


          this.frontendService.GetRecentPosts()
          .pipe(first())
          .subscribe(
              data => {
                const myObjStr = JSON.stringify(data);
                var object=JSON.parse(myObjStr);
                console.log(object);
                if(object){
                  if(object.status=='ok'){
                    console.log(object);
                    this.recent_posts_container=true;
                    //this.recent_posts=object.posts;
                    this.recent_posts=object;
                  }
                }else{
                  this.posts='';
                }
              },
              error => {
                
              })

    }


    /**
     * MOVE TO NEXT PAGE
     */


    next_pagination(posts_per_page,page){

          this.laoding = true;
          this.post_container = false;
          var _bppg = posts_per_page; // POSTS PER PAGE
          var _bp :number = page;
          let pagination_data={posts_per_page:_bppg,page:_bp}
          this.frontendService.GetBlogPosts(pagination_data)
          .pipe(first())
          .subscribe(
              data => {
                const myObjStr = JSON.stringify(data);
                var object=JSON.parse(myObjStr);
                if(object){
                  if(object.status=='ok'){
                    console.log(object.pages+'total page');
                    console.log(_bp+' page');
                    if(object.pages>_bp){
                      this.next_pagination_btn=true; //ENABLE NEXT PAGINATION BUTTON
                      this.previous_pagination_btn=true;//ENABLE PREVIOUS PAGINATION BUTTON
                    }else{
                      this.next_pagination_btn=false; //DESABLE NEXT PAGINATION BUTTON
                    }
                    this.posts_per_page=_bppg;
                    let next_page=Number(_bp)+1; //NEXT PAGE
                    this.page=next_page; //SET ON VIEW
                    this.previous_page=_bp-1; //PREVIOUS PAGE 
                    this.posts=object.posts; //POST DATA
                    this.post_container = true; //SHOW POST CONTAINER
                    this.laoding = false; //DESABLE LOADER
                  }
                }else{
                  this.posts='';
                }
              },
              error => {
                
              })

    }


     /**
     * MOVE TO PREVIOUS  PAGE
     */


    previous_pagination(posts_per_page,page){

        this.laoding = true; // LOADER ENABLE
        this.post_container = false; //HIDE POST CONTAINER 
        var _bppg = posts_per_page; // POSTS PER PAGE
        var _bp :number = page; //PAGE NUMBER
        let pagination_data={posts_per_page:_bppg,page:_bp}
        this.frontendService.GetBlogPosts(pagination_data)
        .pipe(first())
        .subscribe(
            data => {
              const myObjStr = JSON.stringify(data);
              var object=JSON.parse(myObjStr);
              if(object){
                if(object.status=='ok'){
                    if(object.pages>_bp){
                      this.next_pagination_btn=true;//ENABLE NEXT PAGINATION BUTTON
                      this.previous_pagination_btn=true;//ENABLE PREVIOUS PAGINATION BUTTON
                    }else{
                      this.next_pagination_btn=false;//ENABLE NEXT PAGINATION BUTTON
                    }
                    this.posts_per_page=_bppg;
                    let next_page=Number(_bp)+1; //NEXT PAGE
                    this.page=next_page; //SET ON VIEW
                    this.previous_page=Number(_bp)-1;  //PREVIOUS PAGE 
                    if(this.previous_page<1){
                      this.previous_pagination_btn=false;
                    }
                    this.posts=object.posts; //POST DATA
                    this.post_container = true;//SHOW POST CONTAINER
                    this.laoding = false;//DESABLE LOADER
                }
              }else{
                this.posts='';
              }
            },
            error => {
              
            })

       } 


       /**
        * REDIRECT TO POST PAGE
        */

       redirect(post_id){
          localStorage.setItem("post_id",btoa(post_id));
          this.router.navigate(['/post.html']);
       }



}