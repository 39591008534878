<section class="page_title cs s-pt-60 s-pb-10 s-pt-lg-100 s-pb-lg-30 text-center">
   <div class="container-fluid">
      <div class="row">
         <div class="divider-60"></div>
         <div class="col-md-12 text-center">
            <h1>Videos</h1>
         </div>
         <div class="divider-50"></div>
      </div>
   </div>
   <ol class="breadcrumb full-width">
      <li class="breadcrumb-item">
         <a href="">Home</a>
      </li>
      <li class="breadcrumb-item active">
         Videos
      </li>
   </ol>
 </section>
 
 <section class="ls s-py-80 s-py-lg-120 s-py-xl-80">
   <div class="container">
      <div class="row">
         <div class="divider-20"></div>
         <div class="col-lg-12">
            <div class="row c-gutter-60 isotope-wrapper masonry-layout c-mb-30 ">
               <div class="col-xl-4 col-sm-6 video-1" *ngFor="let video of videos">
                  <div class="text-center  gallery-item">
                     <div class="media">
                        <div class="media-body">
                           <video width="100%" height="240" controls contols poster="{{site_url}}assets/frontend/images/thumbnails/{{video.poster}}">
                               <source src="https://gocampaignvideos.com/assets/frontend/videos/{{video.url}}" type="video/mp4">
                               Your browser does not support the video tag.
                            </video>
                         </div>
                     </div>
                     <div class="item-content1">
                        <h5 class="video-title" [innerHTML]='video.title | slice:0:100'></h5>
                        <p>{{video.description}}</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
 </section>