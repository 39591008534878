import { RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './error/page-not-found/page-not-found.component';
import { SiteLayoutComponent } from './_layout/site/site-layout/site-layout.component';
import { BlankLayoutComponent } from './_layout/blank-layout/blank-layout.component';
import { LandingComponent } from './frontend/pages/landing/landing.component';
import { HowitworksComponent } from './frontend/pages/howitworks/howitworks.component';
import { BlogComponent } from './frontend/pages/blog/blog.component';
import { PostComponent } from './frontend/pages/blog/post/post.component';
import { LandingPageComponent } from './frontend/pages/landing-page/landing-page.component';
import { DynamicvideosComponent } from './frontend/pages/dynamicvideos/dynamicvideos.component';
import { PremadetemplatesComponent } from './frontend/pages/premadetemplates/premadetemplates.component';
import { BenefitsComponent } from './frontend/pages/benefits/benefits.component';
import { SocialLandingPageComponent } from './frontend/pages/landing-page/social-landing-page/social-landing-page.component';
import { InsuranceComponent } from './frontend/pages/landing-page/insurance/insurance.component';
import { OnboardingEnglishComponent } from './frontend/pages/landing-page/beyontect/onboarding-english/onboarding-english.component';
import { LifeRenewalReminderEnglishComponent } from './frontend/pages/landing-page/beyontect/life-renewal-reminder-english/life-renewal-reminder-english.component';
import { RenewalReminderEnglishComponent } from './frontend/pages/landing-page/beyontect/renewal-reminder-english/renewal-reminder-english.component';
import { NoClaimDiscountOfferEnglishComponent } from './frontend/pages/landing-page/beyontect/no-claim-discount-offer-english/no-claim-discount-offer-english.component';
import { BankComponent } from './frontend/pages/landing-page/beyontect/bank/bank.component';
import { HotelWelcomeComponent } from './frontend/pages/landing-page/beyontect/hotel-welcome/hotel-welcome.component';
import { PersonalizedDynamicVideosComponent } from './frontend/pages/personalized-dynamic-videos/personalized-dynamic-videos.component';
import { PersonalizedVideoMarketingComponent } from './frontend/pages/personalized-video-marketing/personalized-video-marketing.component';
import { PersonalizedSocialMediaVideosComponent } from './frontend/pages/personalized-social-media-videos/personalized-social-media-videos.component';
import { ContactComponent } from './frontend/pages/contact/contact.component';
import { RequestADemoComponent } from './frontend/pages/request-a-demo/request-a-demo.component';
import { AboutComponent } from './frontend/pages/about/about.component';
import { CookieNoticeComponent } from './frontend/pages/cookie-notice/cookie-notice.component';
import { VideosComponent } from './frontend/pages/videos/videos.component';
import { NewsComponent } from './frontend/pages/news/news.component';
import { CaseStudiesComponent } from './frontend/pages/case-studies/case-studies.component';
import { PrivacyPolicyComponent } from './frontend/pages/privacy-policy/privacy-policy.component';
import { PoliticalCampaignPromotionsComponent } from './frontend/pages/political-campaign-promotions/political-campaign-promotions.component';
import { CruiselineComponent } from './frontend/pages/cruiseline/cruiseline.component';
var ɵ0 = {
    title: 'Transform customer engagements with automated personalized videos',
    descrption: 'Deliver personalised customer experiences tailored for each customer by curating data and engaging videos',
    ogTitle: 'GoCampaignVideos | Transform customer engagements with automated personalized videos',
    ogDescription: 'Deliver personalised customer experiences tailored for each customer by curating data and engaging videos',
    ogKeywords: 'create video, customize data, personalize video, user engagement, personalised video, customer engagement, video templates, video engagement'
}, ɵ1 = {
    title: 'Political Campaign Promotions',
    descrption: 'GoCampaign personalized dynamic video solution makes it easy for customers to stay connected to your brand across their journey with you.',
    ogTitle: 'GoCampaignVideos | Political Campaign Promotions',
    ogDescription: 'GoCampaign personalized dynamic video solution makes it easy for customers to stay connected to your brand across their journey with you.',
    ogKeywords: 'personalized dynamic,dynamic videos,customer engagement,pre-made video,video marketing,data-driven video solutions,marketing dynamic video, hyper-personalized campaigns,video marketing ads, personalized dynamic videos,customer engagements,end-to-end solutions'
}, ɵ2 = {
    title: 'GoCampaign | Blog',
    descrption: '',
    ogTitle: 'GoCampaign | Blog',
    ogDescription: '',
    robots: 'noindex,noarchive,noimageindex, nofollow, nosnippet'
}, ɵ3 = {
    title: 'GoCampaign | Blog',
    descrption: '',
    ogTitle: '',
    ogDescription: '',
    robots: 'noindex,noarchive,noimageindex, nofollow, nosnippet'
}, ɵ4 = {
    title: 'Increase website traffic and conversions with automated product videos',
    descrption: 'GoCampaignVideos integrates product videos on websites to increase customer engagement and boost sales',
    ogTitle: 'GoCampaignVideos | Increase website traffic and conversions with automated product videos',
    ogDescription: 'GoCampaignVideos integrates product videos on websites to increase customer engagement and boost sales',
    ogKeywords: 'improve web traffic, customer engagement, boost conversions, dynamic online videos, real-time product videos, information-rich videos, automate product videos,attractive dynamic videos'
}, ɵ5 = {
    title: 'Affordable, pre-made video templates for marketing campaigns',
    descrption: 'Use GoCampaignVideos ready-made slideshow video templates to create awesome business videos',
    ogTitle: 'GoCampaignVideos | Affordable, pre-made video templates for marketing campaigns',
    ogDescription: 'Use GoCampaignVideos ready-made slideshow video templates to create awesome business videos',
    ogKeywords: 'slideshow video maker, professional videos, video templates, DIY videos, online video maker, make free videos, Intro video maker'
}, ɵ6 = {
    title: 'Catapult customer experience and loyalty to the next level ',
    descrption: 'GoCampaignVideos cloud-based personalized video solutions keep the customer relationship feeling human',
    ogTitle: 'GoCampaign Videos |  Catapult customer engagement, experience and loyalty to the next level with hyper-personalized videos',
    ogDescription: 'GoCampaignVideos cloud-based personalized video solutions keep the customer relationship feeling human',
    ogKeywords: 'Pre-made Video Templates, Automated Dynamic Videos, Personalized Video Marketing, Improve Customer experience, Premade Videos, Video Personalization, Multi-channel Personalized Video, Customer Engagement, Contextual Messages '
}, ɵ7 = {
    title: 'GoCampaign Videos | Page Not Found',
    descrption: '',
    ogTitle: 'GoCampaign Videos | Page Not Found',
    ogDescription: ''
}, ɵ8 = {
    title: 'Improve customer intimacy with personalized video interactions ',
    descrption: 'Use GoCampaignVideos automated, hyper-personalized videos for superior customer engagements.',
    ogTitle: 'GoCampaignVideos | Improve customer intimacy with personalized video interactions ',
    ogDescription: 'Use GoCampaignVideos automated, hyper-personalized videos for superior customer engagements',
    ogKeywords: 'personalized dynamic,dynamic videos,customer engagement,pre-made video,video marketing,data-driven video solutions,marketing dynamic video, hyper-personalized campaigns,video marketing ads,personalized dynamic videos,customer engagements,end-to-end solutions'
}, ɵ9 = {
    title: 'Automated, hyper-targeted personalized video marketing campaigns',
    descrption: 'GoCampaignVideos personalized video marketing solution helps you hyper-target, automate and optimize your marketing campaigns, for maximum user engagement and conversion.',
    ogTitle: 'GoCampaignVideos | Automated, hyper-targeted personalized video marketing campaigns',
    ogDescription: 'GoCampaignVideos personalized video marketing solution helps you hyper-target, automate and optimize your marketing campaigns, for maximum user engagement and conversion.',
    ogKeywords: 'marketing video, personalized video, marketing campaigns, marketing solution, personalized video marketing, dynamic online videos, video marketing solution, audience engagement, marketing video campaigns, visually-appealing video, pre-made video templates'
}, ɵ10 = {
    title: 'Launch geo-targeted personalized social media video campaigns',
    descrption: 'Execute impactful, social media video campaigns for higher reach and virality with GoCampaignVideos',
    ogTitle: 'GoCampaignVideos | Launch geo-targeted personalized social media video campaigns',
    ogDescription: 'Execute impactful, social media video campaigns for higher reach and virality with GoCampaignVideos',
    ogKeywords: 'social media videos, marketing, personalized videos, social engagements, Youtube marketing, LinkedIn marketing, Twitter marketing, facebook marketing, instagram marketing, geo-targeted traffic'
}, ɵ11 = {
    title: 'Connect with us for end-to-end personalised video marketing solutions',
    descrption: 'Contact us for transforming your customer engagements using personalised video marketing solutions',
    ogTitle: 'GoCampaignVideos | Contact us for end-to-end automated personalised video marketing solutions',
    ogDescription: 'Contact us for transforming your customer engagements using personalised video marketing solutions',
    ogImage: 'https://top2d3ddiag.blob.core.windows.net/videocampaign/video4/assets/unparalleled_customer_engagement.png?sp=rl&st=2020-02-15T05:34:33Z&se=2035-06-12T05:34:00Z&sv=2019-02-02&sr=b&sig=gK7R2BZidvjW1QXxGsqNkOusWL9ZWmeOsxNl1vsZlhY%3D',
    ogKeywords: 'video marketing, personalised, personalization, social media, youtube, digital transformation, cloud, AI-based '
}, ɵ12 = {
    title: 'Request a GoCampaignVideos platform demo',
    descrption: 'Request a demo to understand the automated bulk personalised video creation process',
    ogTitle: 'Request a GoCampaignVideos platform demo',
    ogDescription: 'Request a demo to understand the automated bulk personalised video creation process',
    ogKeywords: 'video marketing, personalised, personalization, socialmedia, youtube, digitaltransformation, cloud, AI-based'
}, ɵ13 = {
    title: 'A transformative digital technology platform for personalized video solutions',
    descrption: 'GoCampaignVideos is an automated video personalization platform that builds superior customer engagements and experiences',
    ogTitle: 'GoCampaignVideos | A transformative digital technology platform for personalized video solutions',
    ogDescription: 'GoCampaignVideos is an automated video personalization platform that builds superior customer engagements and experiences',
    ogKeywords: 'video marketing, personalised, personalization, social media, youtube, digital transformation, cloud, AI-based '
}, ɵ14 = {
    title: 'GoCampaignVideos Cookie Policy',
    descrption: 'Learn more about GoCampaignVideos CCPA and GDPR compliance',
    ogTitle: 'GoCampaignVideos | Cookie Policy',
    ogDescription: 'Learn more about GoCampaignVideos CCPA and GDPR compliance',
    ogKeywords: 'video marketing, personalised, personalization, social media, youtube, digital transformation, cloud, AI-based '
}, ɵ15 = {
    title: 'Fully automated cloud-based video personalization platform',
    descrption: 'GoCampaignVideos delivers AI-powered, personalized video marketing solutions for superior customer experiences',
    ogTitle: 'GoCampaignVideos | Fully automated cloud-based video personalization platform',
    ogImage: 'https://top2d3ddiag.blob.core.windows.net/videocampaign/video4/assets/unparalleled_customer_engagement.png?sp=rl&st=2020-02-15T05:34:33Z&se=2035-06-12T05:34:00Z&sv=2019-02-02&sr=b&sig=gK7R2BZidvjW1QXxGsqNkOusWL9ZWmeOsxNl1vsZlhY%3D',
    ogDescription: 'GoCampaignVideos delivers AI-powered, personalized video marketing solutions for superior customer experiences',
    ogKeywords: 'video marketing, personalised, personalization, social media, youtube, digital transformation, cloud, AI-based '
}, ɵ16 = {
    title: 'Videos | GoCampaign Videos',
    descrption: '',
    ogTitle: 'Videos | GoCampaign Videos',
    ogDescription: '',
    ogKeywords: ''
}, ɵ17 = {
    title: 'News | GoCampaign Videos',
    descrption: 'GoCampaign Videos creates the granular level of personalization in your customer communications by integrating data and videos.',
    ogTitle: 'News | GoCampaign Videos',
    ogDescription: 'GoCampaign Videos creates the granular level of personalization in your customer communications by integrating data and videos.',
    ogKeywords: ''
}, ɵ18 = {
    title: 'Privacy Policy | GoCampaign Videos',
    descrption: '',
    ogTitle: 'Privacy Policy | GoCampaign Video',
    ogDescription: '',
    ogKeywords: ''
}, ɵ19 = {
    title: 'Case Studies | GoCampaign Videos',
    descrption: 'GoCampaign Videos creates the granular level of personalization in your customer communications by integrating data and videos.',
    ogTitle: 'Case Studies | GoCampaign Videos',
    ogDescription: 'GoCampaign Videos creates the granular level of personalization in your customer communications by integrating data and videos.',
    ogKeywords: ''
}, ɵ20 = {
    title: 'Cruiseline | GoCampaign Videos',
    descrption: '',
    ogTitle: 'Cruiseline | GoCampaign Video',
    ogDescription: '',
    ogKeywords: ''
};
var routes = [
    {
        path: 'page',
        component: BlankLayoutComponent,
        children: [
            { path: 'landing', component: LandingPageComponent },
            { path: 'dynamic_retail_video', component: SocialLandingPageComponent },
            { path: 'insurance_video', component: InsuranceComponent },
            { path: 'abc_motor', component: RenewalReminderEnglishComponent },
            { path: 'abc_onboarding', component: OnboardingEnglishComponent },
            { path: 'abc_renewal', component: LifeRenewalReminderEnglishComponent },
            { path: 'abc_noclaim', component: NoClaimDiscountOfferEnglishComponent },
            { path: 'abc_banking', component: BankComponent },
            { path: 'hotel_welcome', component: HotelWelcomeComponent },
        ]
    },
    //Site routes goes here 
    {
        path: '',
        component: SiteLayoutComponent,
        children: [
            { path: 'how-it-works.html', component: HowitworksComponent,
                data: ɵ0
            }, { path: 'political-campaign-promotions', component: PoliticalCampaignPromotionsComponent,
                data: ɵ1
            },
            { path: 'blog.html', component: BlogComponent,
                data: ɵ2
            },
            { path: 'post/:type', component: PostComponent,
                data: ɵ3
            },
            { path: 'dynamic-online-videos.html', component: DynamicvideosComponent,
                data: ɵ4
            },
            { path: 'pre-made-video-templates.html', component: PremadetemplatesComponent,
                data: ɵ5
            },
            { path: 'how-it-benefits.html', component: BenefitsComponent,
                data: ɵ6
            },
            { path: 'page-not-found.html', component: PageNotFoundComponent,
                data: ɵ7
            },
            { path: 'personalized-dynamic-videos.html', component: PersonalizedDynamicVideosComponent,
                data: ɵ8
            },
            { path: 'personalized-video-marketing.html', component: PersonalizedVideoMarketingComponent,
                data: ɵ9
            },
            { path: 'personalized-social-media-videos.html', component: PersonalizedSocialMediaVideosComponent,
                data: ɵ10
            },
            { path: 'contact.html', component: ContactComponent,
                data: ɵ11
            },
            { path: 'request-a-demo.html', component: RequestADemoComponent,
                data: ɵ12
            },
            { path: 'about-us.html', component: AboutComponent,
                data: ɵ13
            },
            { path: 'cookie-notice.html', component: CookieNoticeComponent,
                data: ɵ14
            },
            { path: '', component: LandingComponent,
                data: ɵ15
            },
            { path: 'videos.html', component: VideosComponent,
                data: ɵ16
            },
            {
                path: 'news.html', component: NewsComponent,
                data: ɵ17
            },
            { path: 'privacy_policy.html', component: PrivacyPolicyComponent,
                data: ɵ18
            },
            {
                path: 'case-studies/:id', component: CaseStudiesComponent,
                data: ɵ19
            },
            { path: 'cruiseline', component: CruiselineComponent,
                data: ɵ20
            }
        ]
    },
    //no layout routes
    { path: 'personalized-campaigns.html', redirectTo: 'personalized-dynamic-videos.html' },
    { path: 'personalized-ads.html', redirectTo: 'dynamic-online-videos.html' },
    { path: 'dynamic-videos.html', redirectTo: 'personalized-video-marketing.html' },
    { path: 'pre-made-templates.html', redirectTo: 'pre-made-video-templates.html' },
    { path: 'howitworks.html', redirectTo: 'how-it-works.html' },
    { path: 'how-benefits.html', redirectTo: 'how-it-benefits.html' },
    { path: 'whentouse.html', redirectTo: '' },
    { path: '**', redirectTo: 'page-not-found.html' }
];
export var routing = RouterModule.forRoot(routes);
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20 };
