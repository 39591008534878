<section class="page_title cs s-pt-60 s-pb-10 s-pt-lg-100 s-pb-lg-30 page_title text-center about_us_header">
   <div class="container-fluid">
      <div class="row">
         <div class="divider-60"></div>
         <div class="col-md-12 text-center">
            <h1>About Us</h1>
         </div>
         <div class="divider-50"></div>
      </div>
   </div>
   <!-- <ol class="breadcrumb full-width">
      <li class="breadcrumb-item">
         <a href="">Home</a>
      </li>
      <li class="breadcrumb-item active">
         About Us
      </li>
   </ol> -->
 </section>

<section class="ls s-pt-75 s-pb-80 s-pb-lg-100 about-section text-center text-lg-left">
  <div class="d-none d-lg-block divider-10"></div>
  <div class="container about_container">
     <!--<h4 class="top-title text-center">DATA-DRIVEN CUSTOMER EXPERIENCES THAT MATTER</h4>-->
     <div class="row align-items-center">
        <div class="col-12 col-lg-12 order-1 order-lg-1">
           <div class="d-none d-lg-block divider-20"></div>
           <div class="item-content">
              <p class="big">Founded in 2019 as the MarTech arm of Selfanimate Private Limited, GoCampaign has grown to become a transformative digital technology platform for AI-driven video solutions. GoCampaign is uniquely positioned to offer highly affordable dynamic video solutions catering to the requirements of the global business community. Using automated, hyper-personalized video solutions from GoCampaign, businesses across industries can now capture the clients’ attention, increase customer engagements and improve brand loyalty, by providing visually engaging, automated video-based communications.</p>
           </div>
        </div>
     </div>
     <div class="divider-60"></div>
     <div class="myGrid" id="abt-pc1">
        <div class="grid-item  abt-text">
           <div class="grid-item-border Gtext">At the core of our services lies our clients and their needs. Every creative solution offered is uniquely tailored to fit the standards of our clients and the various digital initiatives of their business. GoCampaign was conceived and developed based on our client’s need for building higher customer connections. It is scalable and is fully automated to deliver a seamless performance while offering unique, relevant experiences and measurable ROI. GoCampaign as a SaaS model is highly affordable, requiring minimal initial investments or changes to existing systems. </div>
        </div>
        <div class="grid-item ">
           <div class="grid-item-border Gleft"><img src="../../../../assets/frontend/images/about-us/about-l.png" class="Gimg"  alt=""/></div>
        </div>
     </div>
     <div class="myGrid" id="abt-pc2">
        <div class="grid-item ">
           <div class="grid-item-border Gright "><img src="../../../../assets/frontend/images/about-us/about-r.png" alt=""/> </div>
        </div>
        <div class="grid-item abt-text">
           <div class="grid-item-border Gtextr">GoCampaign is backed by a diverse group of professionals with creative and technical expertise, having over 140 years of total experience in core marketing and leading video technologies. We understand the importance and relevance of video marketing and the influence they hold over the end-user decisions. GoCampaign with its capability in video production, marketing strategy and digital technology, is uniquely positioned to offer end-to-end video marketing solutions.</div>
        </div>
     </div>
     <div class="myGrid" id="abt-mob1">
        <div class="grid-item ">
           <div class="grid-item-border Gleft"><img src="../../../../assets/frontend/images/about-us/about-l.png" class="Gimg"  alt=""/></div>
        </div>
        <div class="grid-item ">
           <div class="grid-item-border Gtext">At the core of our services lies our clients and their needs. Every creative solution offered is uniquely tailored to fit the standards of our clients and the various digital initiatives of their business. GoCampaign was conceived and developed based on our client’s need for building higher customer connections. It is scalable and is fully automated to deliver a seamless performance while offering unique, relevant experiences and measurable ROI. GoCampaign as a SaaS model is highly affordable, requiring minimal initial investments or changes to existing systems. </div>
        </div>
     </div>
     <div class="myGrid" id="abt-mob2">
        <div class="grid-item ">
           <div class="grid-item-border Gright "><img src="../../../../assets/frontend/images/about-us/about-r.png" alt=""/> </div>
        </div>
        <div class="grid-item ">
           <div class="grid-item-border Gtextr">GoCampaign is backed by a diverse group of professionals with creative and technical expertise, having over 140 years of total experience in core marketing and leading video technologies. We understand the importance and relevance of video marketing and the influence they hold over the end-user decisions. GoCampaign with its capability in video production, marketing strategy and digital technology, is uniquely positioned to offer end-to-end video marketing solutions.</div>
        </div>
     </div>
  </div>
</section>
<section class="ls ms s-pt-80 s-pb-50 s-pt-lg-90 s-pb-lg-100 s-pt-xl-60 s-pb-xl-130 c-gutter-30 c-mb-30">
  <div class="container">
     <div class="divider-60"></div>
     <h4 class="top-title text-center">OUR MISSION</h4>
     <div class="item-content">
        <p class="our-mission abt-text">At GoCampaign, our mission is to improve customer experiences using innovation and intelligent data. We understand the importance of customer loyalty and its significance on the business. With a wide portfolio of video solutions and established project management methods, our goal is to delight the end-customers at every interaction with solutions that exceed expectations.
        </p>
     </div>
  </div>
</section>
<!-- ******************** creative possibilities section starts here ******************** -->	
<section class="ls background-gradient  s-pt-75 about-section text-center text-lg-left ">
  <div class="d-none d-lg-block divider-10"></div>
  <div class="container">
     <h4 class="top-title text-center cpw">OUR PEOPLE</h4>
     <div class="row align-items-center">
        <div class="col-12 col-lg-12 order-1 order-lg-1">
           <div class="d-none d-lg-block divider-20"></div>
           <div class="item-content">
              <p class="cpw abt-text">While the videos are the end result, the path towards them is more complicated. Behind every video is a diverse team of dedicated professionals working to render unique solutions for unique client needs.</p>
           </div>
        </div>
     </div>
     <div class="divider-60"></div>
     <div class="row">
        <div class="col-md-3 col-12">
           <div class="hovereffect1">
              <img class="img-responsive" src="../../../../assets/frontend/images/about-us/passionate.jpg" alt="Passionate">
              <div class="overlay">
                 <h2>Passionate</h2>
                 <a class="info1 op-1" href="javascript:void(0)" > To create, sustain and soar, every company needs passionate individuals working behind the curtain. Motivated and highly driven, GoCampaign is composed of many such individuals who make things happen, every day.</a>
              </div>
           </div>
        </div>
        <!-- .col-* -->
        <div class="col-md-3 col-12">
           <div class="hovereffect1">
              <img class="img-responsive" src="../../../../assets/frontend/images/about-us/growth-oriented.jpg" alt="Growth-oriented">
              <div class="overlay">
                 <h2>Growth-oriented</h2>
                 <a class="info1 op-2" href="javascript:void(0)">At GoCampaign, we are constantly learning, ever curious about the new and unknown. Committed to excellence, we strive to better our capabilities at every turn.</a>
              </div>
           </div>
        </div>
        <!-- .col-* -->
        <div class="col-md-3 col-12">
           <div class="hovereffect1">
              <img class="img-responsive" src="../../../../assets/frontend/images/about-us/open-culture.jpg" alt="Open culture">
              <div class="overlay">
                 <h2>Open culture</h2>
                 <a class="info1 op-3" href="javascript:void(0)">We recognize the importance of honest free-flowing communication. That is why we practice an open and inclusive culture, encouraging open dialogue and appreciation. Not only does it make collaboration smoother but also keep things transparent.</a>
              </div>
           </div>
        </div>
        <!-- .col-* -->
        <div class="col-md-3 col-12">
           <div class="hovereffect1">
              <img class="img-responsive" src="../../../../assets/frontend/images/about-us/customer-oriented.jpg" alt="Customer-oriented">
              <div class="overlay">
                 <h2>Customer-oriented</h2>
                 <a class="info1 op-4" href="javascript:void(0)">All communications and decisions are made keeping the customers in mind. It is their needs and expectations that are at the heart of our services.</a>
              </div>
           </div>
        </div>
        <!-- .col-* -->
     </div>
     <div class="divider-30"></div>
     <div class="col-12 col-lg-12 order-1 order-lg-1">
        <div class="d-none d-lg-block divider-20"></div>
        <div class="item-content">
           <p class="cpw abt-text">All video solutions offered are developed using the latest cutting-edge technology and infrastructure, to provide the best customer experience possible. We are constantly upgrading our technology not just to be prepared for change but to stay ahead of the curve.  </p>
           <br>
         </div>
     </div>
  </div>
  <!-- <div class="divider-60"></div> -->
</section>
<!-- creative possibilities section ends here -->	
<!-- integrations section starts here -->
<section class="ls s-pt-80 s-pb-50 s-pt-lg-90 s-pb-lg-100 s-pt-xl-60 s-pb-xl-130 c-gutter-30 c-mb-30 community-bg">
  <div class="container">
     <div class="divider-60"></div>
     <h4 class="top-title text-center comuunity-text ">OUR COMMUNITY</h4>
     <div class="item-content">
        <p class="comuunity-text text-center">Though our quality of life has improved considerably over the last couple of decades, there is still a lack of true community development around us. Constant changes and uncertainties can limit and hinder one’s true potential. Be it in our society or the environment. GoCampaign takes efforts to tackle and improve quality of life in any way possible. We believe in giving back to the community and have participated in various community development activities, including beach clean-ups, tree plantings, book donations to schools, and contributions to charitable organizations. We believe a little a day goes a long way!</p>
        <p class="comuunity-text text-center">	
           We extend the same helping hand to our employees as well. We encourage them to keep learning and growing, staying with them through thick and thin. Our employee welfare programs aid employees dealing with short-term financial stress and troubles so that they can focus on the work. We realize that only as a collective team can we hope to keep moving forward.
        </p>
     </div>
  </div>
</section>
<section class="ls s-pt-30 s-pb-30 s-pt-lg-120 s-pb-lg-90 s-pt-xl-80 s-pb-xl-30 c-mb-30 team-page">
  <div class="container">
     <h4 class="top-title text-center">PARTNERSHIPS</h4>
     <div class="item-content">
        <p class="text-center">At GoCampaign, we are constantly looking for new opportunities to expand our business and our capabilities. There awaits countless new territories yet to be explored and we firmly believe in creating the right business partnerships that matter. Currently we are partnered with like-minded partners across Asia, Africa, the United States and the Middle East to build and grow the business. Our motto, ‘We Grow Together’ is the shared value inscribed into the fabric of our business and is followed by our partners. </p>
     </div>
     <div class="divider-20"></div>
     <p>Our Partners</p>
     <div class="divider-20"></div>
     <div class="row">
        <div class="col-md-3 col-12 ">
           <a href="https://beyontec.ae/" target="_blank"><img src="../../../../assets/frontend/images/beyontec.png" alt="beyontec"/></a>
        </div>
        <div class="col-md-3 col-12 ">
           <a href="https://imexolutions.co.ke/" target="_blank"><img src="../../../../assets/frontend/images/imex.png" alt="imexolutions"/></a>
        </div>
        <div class="col-md-4 col-12">
        </div>
     </div>
     <p>Interested in partnering with us? Write to us at <a href="mailto:info@selfanimate.com"> <b>info@selfanimate.com</b></a>
     </p>
     <div class="divider-60"></div>
  </div>
</section>
<section class="s-pt-80 s-pb-0 s-pt-lg-160 s-pb-lg-60 s-pt-lg-160 s-pb-lg-60 c-gutter-60  ls pv">
  <div class="container">
     <div class="row">
        <div class="col-lg-8">
           <h4 class="top-title pvt">STAY RELEVANT WITH CUSTOMERS USING PERSONALIZED VIDEOS</h4>
           <p>Let's talk on the next steps!</p>
        </div>
        <div class="col-lg-3">
           <div class="d-block d-lg-none divider-30"></div>
           <div class="row" style="padding-top: 50px">
              <div class="col-sm-8 gcv-but">
                 <button  id="rad" h name="rad" class="btn btn-maincolor"><span><a routerLink="/request-a-demo.html">Request a Demo</a></span>
                 </button>
              </div>
              <div class="col-sm-4 gcv-but">
                 <button id="contact-me" name="contact-me" class="btn btn-maincolor"><span><a routerLink="/contact.html">Contact Me</a></span>
                 </button>
              </div>
           </div>
        </div>
     </div>
  </div>
</section>