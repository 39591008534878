import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.styl']
})
export class VideosComponent implements OnInit {
  videos: { title: string; description: string; url: string; }[];
  site_url: string;

  constructor() { }

  ngOnInit() {

    var base_url=environment.base_url;
    this.site_url=base_url;

    //VIDEOS ARRAY
       //VIDEOS ARRAY
       var videos = [
        {'title':'<span>Banking</span> : Introduction to personalized videos in the BFS industry','poster':'Banking_Introduction_to_personalized_videos_in_the_industry.webp','description':'Explore how personalized video marketing can help you build superior customer connections and interactions.','url':'selfanimate_gocampagin_for_banking.mp4'},
        {'title':'<span>Banking</span> : Video introducing a new relationship manager to the customer','poster':'Banking_Video_introducing_a_new_relationship_manager_to_the_customer.webp','description':'Build communication and trust, and increase business from loyal customers or HNIs using personalized videos to update them on developments at the bank.','url':'dynamic_video_personalisation_for_bank.mp4'},
        {'title':'<span>Banking</span> : Personalized videos to build customer loyalty with retail investors','poster':'abc_broking_ltd_img.webp','description':'Send personalised video-based portfolio updates to retail investors that are visually rich and engaging. Gain his loyalty and business.','url':'abc_broking_Ltd.mp4'},
        {'title':'<span>Insurance</span> : Introduction to personalized videos in the Insurance industry','poster':'Retail_Introduction_to_personalized_videos_in_the_industry.webp','description':'Explore how personalized video marketing can help you build superior customer connections and interactions.','url':'selfanimate_gocampagin_for_insurance.mp4'},
        {'title':'<span>Insurance</span> : Personalized policy reminder video - Arabic','poster':'Insurance_Personalized_policy_reminder_video-Arabic.webp','description':'This video illustrates an effective way insurance companies can use hyper-personalized videos to remind policy holders on upcoming renewals.','url':'abc_renewal_arabic.mp4'},
        {'title':'<span>Insurance</span> : Personalized policy reminder video - English','poster':'Insurance_Personalized_policy_reminder_video-English.webp','description':'This video illustrates an effective way insurance companies can use hyper-personalized videos to remind policy holders on upcoming renewals.','url':'abc_renewal_english.mp4'},
        {'title':'<span>Insurance</span> : Personalized birthday video wish - Arabic','poster':'Insurance_Personalized_birthday_video_wish-Arabic.webp','description':'This video illustrates an effective way insurance companies can use personalized videos to increase customer loyalty and trust with customers. ','url':"abc_birthday_arabic.mp4"},
        {'title':'<span>Insurance</span> : Personalized birthday video wish - English','poster':'Insurance_Personalized_birthday_video_wish-English.webp','description':'This video illustrates an effective way insurance companies can use personalized videos to increase customer loyalty and trust with customers.','url':"abc_birthday_english.mp4"},
        {'title':'<span>Insurance</span> : Personalized new customer onboarding video','poster':'Insurance_Personalized_new_customer_onboarding_video.webp','description':'Welcome new customers by sending them automated onboarding videos with highly contextual information on the policy taken.','url':'onbond_welcome.mp4'},
        {'title':'<span>Insurance</span> : Personalized upsell promotional video','poster':'Insurance_Personalized_upsell_promotional_video.webp','description':'Reduce switch by bulding stroger customer experience using automated promotional videos with offers based on past behaviour.','url':'no_claim_discount.mp4'},
        {'title':'<span>Insurance</span> : Personalized video on Orange Card issuance','poster':'Insurance_Personalized_video_on_Orange_Card_issuance.webp','description':'Send automated, personalized confirmations to users on issuance of orange card for inter-country road travel insurance.','url':'orange_card_issuance_video.mp4'},
        {'title':'<span>Insurance</span> : Personalized reminder video for Life Insurance renewal ','poster':'Insurance_Personalized_reminder_video_for_Life_Insurance_renewal.webp','description':'Use hyper-personalized videos to remind Life Insurance policy holders on upcoming renewals and improve recurring revenues.','url':'Life_insurance_renewal.mp4'},
        {'title':'<span>Hospitality</span> : Personalized videos to promote hotel facilities and the mobile App','poster':'hospital.webp','description':'Welcome hotel guests with a personalized pre check-in video, letting them know about the amenities and how to use the hotel App.','url':'abc_hotel_prebooking.mp4'},
        {'title':'<span>Hospitality</span> : Personalized videos to boost room occupancy rates','poster':'Hospitality_Personalized_videos_to_welcome_back_loyal_customers.webp','description':'Restart reservations and welcome back  loyal customers using hyper-personalized and contextualized videos that connect at an individual level.','url':'hotel_welcome_personalised_video.mp4'},
        {'title':'<span>Airline</span> : Personalized pre-boarding video with travel checklist','poster':'Airline_Personalized_pre-boarding_videoalert_with_travel_checklist.webp','description':'Automated and personalized pre-boarding video to all confirmed travellers, to update them on new flying protocols. ','url':'air_europia_airline_demo.mp4'},
        {'title':'<span>Airline</span> : Promote airline miles program using personalized videos','poster':'airline.webp','description':'Use personalized videos to build deeper customer trust and engagements, and to promote the airline loyalty programs.','url':'abc_airlines_miles_clean.mp4'},
        {'title':'<span>Retail:</span> Introduction to personalized videos in the retail industry','poster':'Retail_Introduction_to_personalized_videos_in_the_industry.webp','description':'Explore how personalized video marketing can help you build superior customer connections and interactions.','url':'selfanimate_gocampagin_for_retail_online_instore.mp4'},
        {'title':'<span>Retail:</span> : personalized hyper-targeted video promotion for products','poster':'Retail_personalized_hyper-targeted_video_promotion_for_products.webp','description':'Promote exiting products or launch new arrivals by sending hyper-personalized product promotional videos to customers.','url':'retail_sample_video.mp4'},
        {'title':'<span>Manufacturing</span>: Introduction to personalized videos in the manufacturing industry','poster':'Manufacturing_Introduction_to_personalized_videos_in_the_industry.webp','description':'Explore how personalized video marketing can help you build superior customer, and partner connections.','url':'selfanimate_gocampagin_for_manufacturing.mp4'},
        {'title':'<span>Communications</span>: Introduction to personalized videos in the Telecom industry','poster':'Telecom_Introduction_to_personalized_videos_in_the_industry.webp','description':'Explore how personalized video marketing can help you build superior customer connections and interactions','url':'selfanimate_gocampagin_for_telecom.mp4'},
        {'title':'<span>Pharmaceuticals</span> : Personalized new product information video','poster':'Pharmaceuticals_Personalized_new_product_information_video.webp','description':'Share information and get the attention of the doctors with a personalized video brochure of your latest product. ','url':'new_earth_pharma.webm'},
        {'title':'<span>Healthcare</span>: Introduction to personalized videos in the Medical/Healthcare industry','poster':'Healthcare_Introduction_to_personalized_videos_in_the_industry.webp','description':'Explore how personalized video marketing can help you build superior customer connections and interactions.','url':'selfanimate_gocampagin_for_healthcare.mp4'},
        {'title':'<span>Education</span> : Introduction to personalized videos in Universities/ Colleges','poster':'Universities_Introduction_to_personalized_videos_in_the_industry.webp','description':'Explore how personalized video marketing can help you build superior student connections and interactions.','url':'selfanimate_gocampagin_for_universities_and_schools.mp4'},
        {'title':'<span>Human Resources</span>: Personalized video interactions with employees','poster':'Human_Resources_Personalized_video_interactions_with_employees.webp','description':'Build stronger bonding with your employees using personalized videos across various interactions with him.','url':'selfanimate_gocampagin_for_corporate_hr.mp4'},
        {'title':'<span>Event Management</span> : Personalized event  confirmation video','poster':'Event_Management_Personalized_event_registration_confirmation_video.webp','description':'Send personalized video confirmations to every attendee registered to attend your event and ensure maximum participation.','url':'personalized_event_confirmation_video.mp4'},
       ]; 

      this.videos=videos;
  }

}
