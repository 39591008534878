import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FrontendService {
  
  private currentPageBehavior = new BehaviorSubject<Object>({currPage: ''});
  
  constructor(
    private http: HttpClient
    ) { 

  }

   /**
    * SET CURRENT PAGE
    * @param behave 
    */

   setCurrentPage(behave: Object) { 
        this.currentPageBehavior.next(behave); 
    } 

    /**
     * GET CURRENT PAGE
     */

    getCurrentPage(): Observable<any> { 
        return this.currentPageBehavior.asObservable(); 
    }

    /**
     * SUBMIT GET IN TOUCH FORM
     */

    GetinTouch(data){

      let token='eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjciLCJ1c2VybmFtZSI6InRvcDJkM2QzQGdtYWlsLmNvbSIsImlhdCI6MTU4MTc1MzAzMSwiZXhwIjoxNTk3MzA1MDMxfQ.17RtGVm8ueRtXwvKa2PDu3ibstu1_DqMHTn91QwEtFA';
      let headers = new HttpHeaders({
            "x-api-key": "tiNnpe6Av8NizXtfYccDzOBdF",
            "token" : token,
        "Content-Type": "application/json",
        "Accept": "*/*",
        "Cache-Control": "no-cache"
        });

        let options={headers:headers};
        return this.http.post<any>(`https://www.selfanimate.com/api/index.php/getintouch`, data,options)
        .pipe(map(data => {
            return data;
        }));

    }


      /**
     * SUBMIT REQUEST DEMO FORM
     * @param data 
     * @returns 
     */


      requestDemo(data){

          let token='eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjciLCJ1c2VybmFtZSI6InRvcDJkM2QzQGdtYWlsLmNvbSIsImlhdCI6MTU4MTc1MzAzMSwiZXhwIjoxNTk3MzA1MDMxfQ.17RtGVm8ueRtXwvKa2PDu3ibstu1_DqMHTn91QwEtFA';
          let headers = new HttpHeaders({
                "x-api-key": "tiNnpe6Av8NizXtfYccDzOBdF",
                "token" : token,
            "Content-Type": "application/json",
            "Accept": "*/*",
            "Cache-Control": "no-cache"
            });
    
            let options={headers:headers};
            return this.http.post<any>(`https://www.selfanimate.com/api/index.php/request_demo`, data,options)
            .pipe(map(data => {
                return data;
            }));
    
        }


    /**
     * SUBMIT CONTACT US FORM
     * @param data 
     * @returns 
     */


    ContactUs(data){

      let token='eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjciLCJ1c2VybmFtZSI6InRvcDJkM2QzQGdtYWlsLmNvbSIsImlhdCI6MTU4MTc1MzAzMSwiZXhwIjoxNTk3MzA1MDMxfQ.17RtGVm8ueRtXwvKa2PDu3ibstu1_DqMHTn91QwEtFA';
      let headers = new HttpHeaders({
            "x-api-key": "tiNnpe6Av8NizXtfYccDzOBdF",
            "token" : token,
        "Content-Type": "application/json",
        "Accept": "*/*",
        "Cache-Control": "no-cache"
        });

        let options={headers:headers};
        return this.http.post<any>(`https://www.selfanimate.com/api/index.php/contact_us`, data,options)
        .pipe(map(data => {
            return data;
        }));

    }


    
    /**
     * SUBMIT GET IN TOUCH FORM
     */

    buynowGetinTouch(data){

      let token='eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjciLCJ1c2VybmFtZSI6InRvcDJkM2QzQGdtYWlsLmNvbSIsImlhdCI6MTU4MTc1MzAzMSwiZXhwIjoxNTk3MzA1MDMxfQ.17RtGVm8ueRtXwvKa2PDu3ibstu1_DqMHTn91QwEtFA';
      let headers = new HttpHeaders({
            "x-api-key": "tiNnpe6Av8NizXtfYccDzOBdF",
            "token" : token,
        "Content-Type": "application/json",
        "Accept": "*/*",
        "Cache-Control": "no-cache"
        });

        let options={headers:headers};
        return this.http.post<any>(`https://www.selfanimate.com/api/index.php/howtobuy_getin_touch`, data,options)
        .pipe(map(data => {
            return data;
        }));

    }




     /**
     * SUBMIT PERSONLIZED VIDEO REQUEST
     */

    personalised_video_request(data){

       let token='eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjciLCJ1c2VybmFtZSI6InRvcDJkM2QzQGdtYWlsLmNvbSIsImlhdCI6MTU4MTc1MzAzMSwiZXhwIjoxNTk3MzA1MDMxfQ.17RtGVm8ueRtXwvKa2PDu3ibstu1_DqMHTn91QwEtFA';
       let headers = new HttpHeaders({
            "x-api-key": "tiNnpe6Av8NizXtfYccDzOBdF",
            "token" : token,
            "Content-Type": "application/json",
            "Accept": "*/*",
            "Cache-Control": "no-cache"
        });
        let min=99999;
        let max=9999999999;
        let video_id:any = Math.floor(Math.random() * (max - min + 1) + min);
        let formData = {'_vid':video_id,'_name':data.name};
        localStorage.setItem('_vid',btoa(video_id));
        localStorage.setItem('_vs','pending');
        const _type = localStorage.getItem("_type");
        console.log('_type',_type);
        const action=(_type==='cruiseline')?'request_cruiseline_video':'request_dummy_video';

        let options={headers:headers};
        return this.http.post<any>(`https://www.selfanimate.com/api/index.php/`+action, formData,options)
        .pipe(map(data => {
            return data;
        }));
        

    }



     /**
     * GET BLOG POSTS FROM SELFANIMATE.COM
     * @param data 
     */



    GetBlogPosts(pagination_data){
        let token='eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjciLCJ1c2VybmFtZSI6InRvcDJkM2QzQGdtYWlsLmNvbSIsImlhdCI6MTU4MTc1MzAzMSwiZXhwIjoxNTk3MzA1MDMxfQ.17RtGVm8ueRtXwvKa2PDu3ibstu1_DqMHTn91QwEtFA';
        let headers = new HttpHeaders({
            "x-api-key": "tiNnpe6Av8NizXtfYccDzOBdF",
            "token" : token,
            "Content-Type": "application/json",
            "Accept": "*/*",
            "Cache-Control": "no-cache",
            "limit": "50"

        });
        return this.http.get<any>('https://www.selfanimate.com/api/index.php/blog_data', { headers });
    }


    
    /**
     * GET POST INFORMATION FROM SELFANIMATE.COM
     * @param data 
     */


     GetPost(slug){
      let token='eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjciLCJ1c2VybmFtZSI6InRvcDJkM2QzQGdtYWlsLmNvbSIsImlhdCI6MTU4MTc1MzAzMSwiZXhwIjoxNTk3MzA1MDMxfQ.17RtGVm8ueRtXwvKa2PDu3ibstu1_DqMHTn91QwEtFA';
      let headers = new HttpHeaders({
          "x-api-key": "tiNnpe6Av8NizXtfYccDzOBdF",
          "token" : token,
          "Content-Type": "application/json",
          "Accept": "*/*",
          "Cache-Control": "no-cache",
          "post_slug": slug
      });
      return this.http.get<any>('https://www.selfanimate.com/api/index.php/blog_data', { headers });
  }


    /**
     * GET RECENT POSTS FROM SELFANIMATE.COM
     * @param data 
     */

    GetRecentPosts(){
      let posts_per_page=3;
      let page=1;
      //let configUrl='https://www.selfanimate.com/blog/api/get_posts/?posts_per_page='+posts_per_page+'&page='+page;
      let configUrl='https://www.selfanimate.com/blog/wp-json/wp/v2/posts?='+posts_per_page+'&page='+page;
     // wp-json/wp/v2/posts?per_page=3&page=1
      return this.http.get(configUrl);
  }


   /**
     * GET VIDEO URL
     * @param data 
     */


    get_video_data(code){
      let configUrl=`${environment.api_base_url}app_controller.php?action=get_video_url&code=`+code;
      return this.http.get(configUrl);
    }


     /**
     * QUERY FROM LANDING PAGE
     * @param data 
     */

    campaign_query(data){
        let formData = new FormData();
        formData.append('firstname', data.first_name);
        formData.append('company', data.company);
        formData.append('email', data.email);
        formData.append('message', data.message);
        if(data.page){
          formData.append('page', data.page);
        }
        let options={};
        return this.http.post<any>(`https://www.selfanimate.com/pages/campaign_query`, formData,options)
        .pipe(map(data => {
            return data;
        }));
    }






    


}
