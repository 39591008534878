import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FrontendService } from '../../../_services';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-site-footer',
  templateUrl: './site-footer.component.html',
  styleUrls: ['./site-footer.component.styl']
})
export class SiteFooterComponent implements OnInit {

  current_page: '';
  text_value: any;
  currPage: any;
  showActions: boolean = false; 
  GetinTouch: boolean = false; 
  GetinTouchForm: FormGroup;
  submitted: boolean;
  loading: boolean;
  BuynowGetinTouchForm: FormGroup;
  buynowsubmitted: boolean;
  buynowloading: boolean;
  duration_of_video: any;
  personalized_videos: string;
  custom_no_of_campaign: string;
  no_of_campaign: string;
  support: string;
  delivery: string;
  video_template: string;
  name: string;
  email: string;
  phone: string;
  organization: string;
  message: string;


  constructor(
    private frontendService: FrontendService,
    private formBuilder: FormBuilder,
    public toastr: ToastrManager

  ) {
    
   }

  ngOnInit() {

      //GET CURRENT PAGE FROM SUBSCRIBE
      this.frontendService.getCurrentPage().subscribe(v => {
           this.currPage=v['currPage'];
           if(v['currPage']=='/howtobuy.html'){
              this.showActions= true; 
              this.GetinTouch= false;
           }else if(v['currPage']=='/whentouse.html'){
            this.showActions= false; 
            this.GetinTouch= true;
           }else if(v['currPage']=='/wheretouse.html'){
            this.showActions= false; 
            this.GetinTouch= true;
           }else if(v['currPage']=='/howitworks.html'){
            this.showActions= false; 
            this.GetinTouch= true;
           }else if(v['currPage']=='/login'){
            this.showActions= false; 
            this.GetinTouch= false;
           }else if(v['currPage']=='/blog.html'){
            this.showActions= false; 
            this.GetinTouch= false;
           }else if(v['currPage']=='/post.html'){
            this.showActions= false; 
            this.GetinTouch= false;
           }else{
            this.showActions= false; 
            this.GetinTouch= true;
           }
      })


      //VALIDATE FORM
      this.GetinTouchForm = this.formBuilder.group({
          name: ['', Validators.required],
          email: ['', Validators.required],
          message: ['', Validators.required]
      });

      //VALIDATE BUY NOW GET IN TOUCH FORM
      this.BuynowGetinTouchForm= this.formBuilder.group({
                name: ['', Validators.required],
                email: ['', [Validators.required, Validators.email]],
                organization: ['',  ''],
                phone : ['', ''],
                video_template: ['', Validators.required],
                personalized_videos: ['', Validators.required],
                duration_of_video: ['', Validators.required],
                no_of_campaign: ['', ''],
                custom_no_of_campaign: ['', ''],
                delivery: ['', Validators.required],
                support: ['', Validators.required],
      })


      //SET DEFAULT VALUE TO SELECT OPTION

      this.duration_of_video = '';
      this.personalized_videos = '';
      this.no_of_campaign='';
      this.custom_no_of_campaign = '';
      this.support='yes';
      this.delivery='email';
      this.video_template='yes';

  }


  get p() 
  { 
    return this.GetinTouchForm.controls; 
  }


  get b() 
  { 
    return this.BuynowGetinTouchForm.controls; 
  }


  /**
   * SUBMIT BUY
   */

   submitBuynowGetinTouch(){

    this.buynowsubmitted = true; //ENABLE SUBMIT BUTTON
    // stop here if form is invalid
    if (this.BuynowGetinTouchForm.invalid) {
        return;
    }
    this.buynowloading = true; //ENABLE LOADING
    var data =this.BuynowGetinTouchForm.value;
    this.frontendService.buynowGetinTouch(data)
    .pipe(first())
    .subscribe(
        data => {
           const myObjStr = JSON.stringify(data);
           console.log(myObjStr);
           var object=JSON.parse(myObjStr);
           var message: string=object.message;
           if(message=='unsuccess'){
                let message="something went wrong please try again";
                this.toastr.errorToastr(message, 'Error', {
                  position: 'top-right'
                });
                this.loading = false;  
           }
           if(message=='success'){ 

                //RESET ALL INPUT FIELD
                 this.BuynowGetinTouchForm.reset();
                 this.support='yes';
                 this.delivery='email';
                 this.video_template='yes';

                let message='Thank you for contacting us. You are very important to us. All information received will always remain confidential. We will contact you as soon as we review your message.';
                this.toastr.successToastr(message, 'Success', {
                    position: 'top-center',
                    timeOut: 999999999,
                    extendedTimeOut : 99999
                });

                this.buynowsubmitted = false; //RESET SUBMIT BUTTON
                this.buynowloading = false; //RESET .LOADING
           }   
        },
        error => {
            this.buynowloading = false;
        })



   }


  /**
   * SUBMIT GET IN TOUCH FORM
   */


  submitGetinTouch(){

    this.submitted = true;
    // stop here if form is invalid
    if (this.GetinTouchForm.invalid) {
        return;
    }
    this.loading = true;
    var data =this.GetinTouchForm.value;
    this.frontendService.GetinTouch(data)
    .pipe(first())
    .subscribe(
        data => {
           const myObjStr = JSON.stringify(data);
           console.log(myObjStr);
           var object=JSON.parse(myObjStr);
           var message: string=object.message;
           if(message=='unsuccess'){
                let message="something went wrong please try again";
                this.toastr.errorToastr(message, 'Error', {
                  position: 'top-right'
                });
                this.loading = false;  
           }
           if(message=='success'){ 
                //RESET ALL INPUT FIELD
                this.GetinTouchForm.reset();
                let message='Thank you for contacting us. You are very important to us. All information received will always remain confidential. We will contact you as soon as we review your message.';
                this.toastr.successToastr(message, 'Success', {
                    position: 'top-center',
                    timeOut: 999999999,
                    extendedTimeOut : 99999
                });
                this.submitted = false;
                this.loading = false; //LOADING REMOVED
           }   
        },
        error => {
            this.loading = false;
        })

  }


      /**
   * MANAGE ACTIVE AND DEACTIVE MENU
   * @param submenu 
   */

       setActive(submenu){
        console.log(submenu);
        if(submenu=='personalized_dynamic_videos' || submenu=='personalized_video_marketing' || submenu=='dynamic-online-videos' || submenu=='personalized_social_media_videos' || submenu=='pre_made_video_templates'){
           $('.solutions').stop().addClass('mactive');
           $('.resources').stop().removeClass('mactive');
           $('.home').stop().removeClass('mactive');
        }else if(submenu=='news' || submenu=='videos' || submenu=='case-studies' ){
            $('.resources').stop().addClass('mactive');
            $('.solutions').stop().removeClass('mactive');
            $('.home').stop().removeClass('mactive');
          }
        else{
          $('.solutions').stop().removeClass('mactive');
          $('.resources').stop().removeClass('mactive');
          $('.home').stop().removeClass('mactive');
        }
    }





}
