/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./playvideo.component.styl.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "./playvideo.component";
var styles_PlayvideoComponent = [i0.styles];
var RenderType_PlayvideoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PlayvideoComponent, data: {} });
export { RenderType_PlayvideoComponent as RenderType_PlayvideoComponent };
export function View_PlayvideoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "video_cont"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "video", [["autoplay", ""], ["controls", ""], ["width", "100%"]], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "source", [["type", "video/mp4"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Your browser does not support the video tag. "])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "button", [["class", "btn btn-maincolor pp-close-btn"], ["id", "matClose"], ["mat-dialog-close", ""], ["type", "button"]], [[1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).dialogRef.close(i1.ɵnov(_v, 5).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"]))], function (_ck, _v) { var currVal_3 = ""; _ck(_v, 5, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.className, ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.url, ""); _ck(_v, 2, 0, currVal_1); var currVal_2 = (i1.ɵnov(_v, 5).ariaLabel || null); _ck(_v, 4, 0, currVal_2); }); }
export function View_PlayvideoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-playvideo", [], null, null, null, View_PlayvideoComponent_0, RenderType_PlayvideoComponent)), i1.ɵdid(1, 114688, null, 0, i3.PlayvideoComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PlayvideoComponentNgFactory = i1.ɵccf("app-playvideo", i3.PlayvideoComponent, View_PlayvideoComponent_Host_0, {}, {}, []);
export { PlayvideoComponentNgFactory as PlayvideoComponentNgFactory };
