<section class="page_title cs s-pt-60 s-pb-10 s-pt-lg-100 s-pb-lg-30 text-center">
  <div class="container-fluid">
     <div class="row">
        <div class="divider-60"></div>
        <div class="col-md-12 text-center">
           <h1>How It Benefits</h1>
        </div>
        <div class="divider-50"></div>
     </div>
  </div>
  <ol class="breadcrumb full-width">
     <li class="breadcrumb-item">
        <a href="">Home</a>
     </li>
     <li class="breadcrumb-item active">
        How It Benefits
     </li>
  </ol>
</section>

<section class="ls s-pt-75 s-pb-70 s-pb-lg-80">
  <div class="container">
     <div class="row">
        <div class="col-12">
           <h4 class="top-title text-center">Why consider video personalization ?</h4>
           <p class="text-center">Specific touchpoints can only operate within their own silos of customer experience. Create a customer-centric journey that is more applicable to the business outcomes than any touchpoints alone. Automated dynamic personalized videos can easily fill in the expectation gaps, which other informational experiences cannot effectively address. Delight your customer with personalized updates every time he connects with you and make their experience unique and relevant.</p>
           <!--<div class="divider-50"></div>-->
        </div>
     </div>
  </div>
</section>
<!-- Fast Facts Starts here -->
<section class="s-pt-80 s-pb-0 s-pt-lg-160 s-pb-lg-60 s-pt-lg-160 s-pb-lg-60 c-gutter-60  ls hib">
  <div class="container">
     <div class="col-lg-8">
        <div class="row">
           <div class="text-box box-2 ">
              <h3>3x</h3>
              <p>Increase in CTR</p>
           </div>
           <div class="text-box box-2 " data-animation="slideUp" data-delay="350">
              <h3>30%</h3>
              <p>Sales Conversion</p>
           </div>
           <div class="text-box box-2 " data-animation="slideLeft" data-delay="550">
              <h3>+3x</h3>
              <p>Marketing RoI</p>
           </div>
        </div>
        <div class="row">
           <div class="text-box box-2 " data-animation="slideRight" data-delay="650">
              <h3>-25%</h3>
              <p>Customer Service Costs</p>
           </div>
           <div class="text-box box-2 " data-animation="slideLeft" data-delay="850">
              <h3>+30%</h3>
              <p>Digital Adoption</p>
           </div>
        </div>
        <div class="col-lg-3 " data-animation="scaleAppear">
        </div>
     </div>
  </div>
</section>
<!-- Fast Facts Ends here-->
<!--                --->
<!--END Our latest work section-->
<!-- class=" --->
<!-- Benefits  Starts Here -->
<section class="ls s-pt-80 s-pb-50 s-pt-lg-120 s-pb-lg-70 s-pt-xl-130 s-pb-xl-70 c-gutter-30 c-mb-30 service-page">
  <div class="container">
     <div class="row">
        <div class="col-lg-12 offset-lg-0">
           <h4 class="top-title text-center">Revolutionize the way you engage with your customers</h4>
           <p class="text-center">GoCampaign is an innovation-first, data-driven platform to create, manage, and deliver impactful multi-channel personalized video marketing experiences. We put intuitive and impactful videos at the heart of your competitive differentiation to influence your customers’ path-to-purchase.</p>
        </div>
        <div class="divider-20"></div>
        <div class="col-lg-12 col-sm-12">
           <div class="vertical-item rounded-image-top content-padding padding-small " >
              <div class="col-lg-6 col-sm-6 item-media grow rotate img-hover-zoom img-hover-zoom--zoom-n-rotate sibling-hover hibl" >
                 <img src="../../../../assets/frontend/images/get-unparalleled-customer-engagement.webp" alt="Get unparalleled customer engagement">
              </div>
              <div class="col-lg-6 col-sm-6 item-content sibling-highlight hibr">
                 <h5 class="service-title text-center">
                    Get unparalleled customer engagement
                 </h5>
                 <ul class="list-icon list-icon-check list-icon-colored">
                    <li>Hyper-personalized video messages</li>
                    <li>Multi-touch customer engagements using CRM data</li>
                    <li>Facilitates contextual messages rather than mundane and generic messages</li>
                    <li>Results in higher open rates and campaign receptivity</li>
                 </ul>
              </div>
           </div>
        </div>
        <!-- .col-* -->
        <div class="col-lg-12 col-sm-12">
           <div class="vertical-item rounded-image-top content-padding padding-small">
              <div class="col-lg-6 col-sm-6 item-media grow rotate img-hover-zoom img-hover-zoom--zoom-n-rotate sibling-hover hibl">
                 <img src="../../../../assets/frontend/images/connect-converse-and-convert.webp" alt="Connect, converse and convert">
              </div>
              <div class="col-lg-6 col-sm-6 item-content sibling-highlight hibr">
                 <h5 class="service-title text-center">
                    Connect, converse and convert
                 </h5>
                 <ul class="list-icon list-icon-check list-icon-colored">
                    <li>Elevates conversion rates by 20-50%</li>
                    <li>Stay clear from the marketing clutter</li>
                    <li>Automated delivery of messages, based on frequency defined</li>
                    <li>Delivers compelling call-to-action</li>
                 </ul>
              </div>
           </div>
        </div>
        <!-- .col-* -->
        <div class="col-lg-12 col-sm-12">
           <div class="vertical-item rounded-image-top content-padding padding-small">
              <div class="col-lg-6 col-sm-6 item-media grow rotate img-hover-zoom img-hover-zoom--zoom-n-rotate sibling-hover hibl">
                 <img src="../../../../assets/frontend/images/improve-customer-retention.webp" alt="Improve customer retention">
              </div>
              <div class="col-lg-6 col-sm-6 item-content sibling-highlight hibr">
                 <h5 class="service-title  text-center">
                    Improve customer retention
                 </h5>
                 <ul class="list-icon list-icon-check list-icon-colored">
                    <li>Gain (limited) attention of customers</li>
                    <li>Improve customer experiences</li>
                    <li>Build loyalty and trust with customers</li>
                    <li>Improve customer referrals</li>
                 </ul>
                 <div class="divider-40"></div>
              </div>
           </div>
        </div>
        <!-- .col-* -->
        <div class="col-lg-12 col-sm-12">
           <div class="vertical-item rounded-image-top content-padding padding-small">
              <div class="col-lg-6 col-sm-6 item-media  grow rotate img-hover-zoom img-hover-zoom--zoom-n-rotate sibling-hover hibl">
                 <img src="../../../../assets/frontend/images/integrate-with-any-core-system.webp" alt="Integrate with any core system">
              </div>
              <div class="col-lg-6 col-sm-6 item-content sibling-highlight hibr">
                 <h5 class="service-title text-center">
                    Integrate with any core system
                 </h5>
                 <ul class="list-icon list-icon-check list-icon-colored">
                    <li>Independent platform</li>
                    <li>Easily integrates with any core solution to retrieve customer data for video personalisation</li>
                    <li>Built-in APIs to enable smooth data flow</li>
                    <li>Built-in connectors to generate campaign reports</li>
                 </ul>
              </div>
           </div>
        </div>
        <!-- .col-* -->
     </div>
  </div>
</section>
<!-- Benefits  Ends Here -->
<!-- Personalized Videos -->
<section class="s-pt-80 s-pb-0 s-pt-lg-160 s-pb-lg-60 s-pt-lg-160 s-pb-lg-60 c-gutter-60  ls pv">
  <div class="container">
     <div class="row">
        <div class="col-lg-8">
           <h4 class="top-title pvt">STAY RELEVANT WITH CUSTOMERS USING PERSONALIZED VIDEOS</h4>
           <p>Let's talk on the next steps!</p>
        </div>
        <div class="col-lg-3">
           <div class="d-block d-lg-none divider-30"></div>
           <div class="row" style="padding-top: 50px">
              <div class="col-sm-8 gcv-but">
                 <button  id="rad" h name="rad" class="btn btn-maincolor"><span><a routerLink="/request-a-demo.html">Request a Demo</a></span>
                 </button>
              </div>
              <div class="col-sm-4 gcv-but">
                 <button id="contact-me" name="contact-me" class="btn btn-maincolor"><span><a routerLink="/contact.html">Contact Me</a></span>
                 </button>
              </div>
           </div>
        </div>
     </div>
  </div>
</section>
<!-- Personalized Videos -->