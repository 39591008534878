<section class="page_title cs s-pt-60 s-pb-10 s-pt-lg-100 s-pb-lg-30 page_title text-center">
  <div class="container-fluid">
     <div class="row">
        <div class="divider-60"></div>
        <div class="col-md-12 text-center">
           <h1> Personalized Video Marketing</h1>
        </div>
        <div class="divider-50"></div>
     </div>
  </div>
  <ol class="breadcrumb full-width">
     <li class="breadcrumb-item">
        <a href="">Home</a>
     </li>
     <li class="breadcrumb-item">
        <a href="javascript:viod(0)">Solutions</a>
     </li>
     <li class="breadcrumb-item active">
        Personalized Video Marketing
     </li>
  </ol>
</section>

<section class="ls s-pt-75 s-pb-80 s-pb-lg-100 about-section text-center text-lg-left">
  <div class="d-none d-lg-block divider-10"></div>
  <div class="container">
     <!--<h4 class="top-title text-center">MOVE FROM GENERIC AUDIENCE TARGETING TO FOCUSED AUDIENCE ENGAGEMENT</h4>-->
     <div class="row align-items-center">
        <!--<div class="col-12 col-lg-12 ">-->
        <div class="col-12 col-lg-6 order-1 order-lg-1">
           <div class="d-none d-lg-block divider-20"></div>
           <div class="item-content">
              <h4 class="top-title text-center">AUDIENCE TARGETING TO AUDIENCE ENGAGEMENT</h4>
              <p class="big">Maximizing the ROI from generic one-size-fits-all brand promotions can be an uphill task. GoCampaign’s personalized video marketing solution helps you hyper-target, automate and optimize marketing videos, at the speed and volume you need, to capture user attention and drive maximum user engagement and conversion.</p>
              <div class="divider-20"></div>
              <div style="padding-left: 15px"><button id="rad" h="" name="rad" class="btn btn-maincolor" ><span><a routerLink="/contact.html">Get Started</a></span>
                 </button>
              </div>
              <div class="divider-20"></div>
           </div>
        </div>
        <div class="col-12 col-lg-6 order-2 order-lg-2">
           <div class="pdvimagehide">
               <img src="../../../../assets/frontend/images/personalized-video-marketing/personalized-video-marketing.webp" alt="Personalized video marketing"/> 
           </div>
           <div id="pvmcontainer">
              <ul id="pvmcontinents1">
                 <li class="pvm-1">
                    <a href="javascript:void(0)">
                    <span class="pvmimage1">
                    <img src="../../../../assets/frontend/images/personalized-video-marketing/m-1.png">
                    </span>
                    </a>
                 </li>
                 <li class="pvm-2">
                    <a href="javascript:void(0)">
                    <span class="pvmimage2">
                    <img class="img-responsive" src="../../../../assets/frontend/images/personalized-video-marketing/w-1.png">
                    </span>
                    </a>
                 </li>
                 <li class="pvm-3">
                    <a href="javascript:void(0)">
                    <span class="pvmimage3">
                    <img src="../../../../assets/frontend/images/personalized-video-marketing/w-2.png" class="img-responsive" >
                    </span>
                    </a>
                 </li>
                 <li class="pvm-4">
                    <a href="javascript:void(0)">
                    <span class="pvmimage4">
                    <img src="../../../../assets/frontend/images/personalized-video-marketing/m-2.png" class="img-responsive" >
                    </span>
                    </a>
                 </li>
              </ul>
           </div>
        </div>
     </div>
  </div>
</section>
<!-- Fast Facts Starts here -->
<section class="s-pt-80 s-pb-0 s-pt-lg-160 s-pb-lg-60 s-pt-lg-160 s-pb-lg-60 c-gutter-60  ls ms">
  <div class="container">
     <h4 class="top-title text-center">CONTEXTUALIZE YOUR MARKETING CampaignS</h4>
     <p class="text-center">Use GoCampaign personalized video marketing solution in your campaigns to promote products, support new product launches, and launch offers and discounts - to every prospect, by unleashing the power of data personalized into unique video marketing promotions.</p>
     <div class="divider-20"></div>
     <div class="col-lg-12">
        <div class="row pvmt-sec " >
           <div class="text-box1 box-21 animate" data-animation="slideRight" data-delay="150">
              <img src="../../../../assets/frontend/images/icons/hyper-targeted.webp" alt="Targeted">
              <p>TARGETED</p>
              <div class="over-lay">
                 <div class="text">Hyper-personalize your marketing video campaign to target specific audience types</div>
              </div>
           </div>
           <div class="text-box1 box-21 animate" data-animation="slideUp" data-delay="350">
              <img src="../../../../assets/frontend/images/icons/historical-data.webp" alt="Database">
              <p>DATABASE</p>
              <div class="over-lay">
                 <div class="text">Curate personalized marketing video campaigns based on past user interaction and behaviour data</div>
              </div>
           </div>
           <div class="text-box1 box-21 animate" data-animation="slideLeft" data-delay="550">
              <img src="../../../../assets/frontend/images/icons/interests.webp" alt="Interests">
              <p>INTERESTS</p>
              <div class="over-lay">
                 <div class="text">Personalize your marketing video campaigns based on registered user interest</div>
              </div>
           </div>
           <div class="text-box1 box-21 animate" data-animation="slideUp" data-delay="750">
              <img src="../../../../assets/frontend/images/icons/contextual.webp" alt="Contextual">
              <p>CONTEXTUAL</p>
              <div class="over-lay">
                 <div class="text">Personalize your marketing video campaigns based on date/time/location of the user</div>
              </div>
           </div>
           <div class="text-box1 box-21 animate" data-animation="slideLeft" data-delay="950">
              <img src="../../../../assets/frontend/images/icons/business.webp" alt="Business">
              <p>BUSINESS</p>
              <div class="over-lay">
                 <div class="text">Personalize your marketing campaigns based on product promotion, inventory, events, or competitive activity</div>
              </div>
           </div>
        </div>
     </div>
  </div>
</section>
<section class="ls s-pt-80 s-pb-50 s-pb-lg-60 s-pb-xl-60 c-mb-30 service-page2">
  <div class="container">
     <div class="row">
        <div class="col-md-4 col-12">
           <div class="hovereffect">
              <img class="img-responsive" src="../../../../assets/frontend/images/personalized-video-marketing/turn-prospects-into-customers.webp" alt="Turn prospects into customers">
              <div class="overlay">
                 <h2>Turn prospects into customers</h2>
                 <a class="info" href="javascript:void(0)">Attract attention and turn prospects into customers with hyper-targeted, hyper-personalized multi-channel video marketing campaigns.</a>
              </div>
           </div>
        </div>
        <!-- .col-* -->
        <div class="col-md-4 col-12">
           <div class="hovereffect">
              <img class="img-responsive" src="../../../../assets/frontend/images/personalized-video-ads/rapidly-influence-path-to-purchase.webp" alt="Influence customer's path to purchase">
              <div class="overlay">
                 <h2>Influence customer's path to purchase</h2>
                 <a class="info" href="javascript:void(0)">Earn positive campaign reactions and trigger purchase decisions with highly informative, personalized, and visually-appealing video marketing campaigns.</a>
              </div>
           </div>
        </div>
        <!-- .col-* -->
        <div class="col-md-4 col-12">
           <div class="hovereffect">
              <img class="img-responsive" src="../../../../assets/frontend/images/personalized-video-ads/automate-and-maximize-marketing-campaign-roi.webp" alt="Maximize marketing campaign ROI">
              <div class="overlay">
                 <h2>Maximize marketing campaign roi</h2>
                 <a class="info" href="javascript:void(0)">Automate and run personalized video marketing campaigns directly to prospects or on social media. Ensure maximum campaign ROI, and improved customer wins.</a>
              </div>
           </div>
        </div>
        <!-- .col-* -->
     </div>
  </div>
</section>
<!-- Benefits -->
<section class="ls s-pt-50 s-pb-20 s-pt-lg-120 s-pb-lg-20 s-pt-xl-50 c-mb-30 c-mb-lg-50 ls tab">
  <div class="divider-10"></div>
  <div class="container">
     <!--<h4 class="top-title text-center">MOVE FROM INTERRUPTIVE TO RELEVANT<br> CUSTOMER ENGAGEMENTS</h4>
        <p class="text-center">Serve customers with contextual, relevant and personalized videos to keep them informed and to influence them into taking actions.</p>-->
     <div class="divider-30"></div>
     <div class="row">
        <div class="col-12">
           <div class="row">
              <div class="col-lg-12">
                 <!-- tabs start -->
                 <ul class="nav nav-tabs" role="tablist" >
                    <li class="nav-item">
                       <a class="nav-link active" id="tab04" data-toggle="tab" href="#tab04_pane" role="tab" aria-controls="tab04_pane" aria-expanded="true">Exceed campaign reach & scale</a>
                    </li>
                    <li class="nav-item">
                       <a class="nav-link" id="tab05" data-toggle="tab" href="#tab05_pane" role="tab" aria-controls="tab05_pane">Micro-target customers & convert</a>
                    </li>
                    <li class="nav-item">
                       <a class="nav-link" id="tab06" data-toggle="tab" href="#tab06_pane" role="tab" aria-controls="tab06_pane">Amplify marketing performance</a>
                    </li>
                 </ul>
                 <div class="tab-content tab-media" style="background-color: #fff">
                    <div class="tab-pane fade show active" id="tab04_pane" role="tabpanel" aria-labelledby="tab04">
                       <div class="media">
                          <img src="../../../../assets/frontend/images/personalized-video-ads/exceed-campaign-reach-scale.jpg" alt="Exceed campaign reach & scale with ease" class="solu-noimg">
                          <div class="media-body">
                             <ul class="list-icon list-icon-check list-icon-colored">
                                <li>
                                   Create and deliver thousands of promotional videos while connecting at an individual level with each prospect
                                </li>
                                <li>
                                   Integrate customer data into curated content and improve campaign  effectiveness
                                </li>
                                <li>
                                   Connect with customers in a personal way to turn interactions into relationships
                                </li>
                             </ul>
                          </div>
                       </div>
                    </div>
                    <div class="tab-pane fade" id="tab05_pane" role="tabpanel" aria-labelledby="tab05">
                       <div class="media">
                          <div class="media-body">
                             <ul class="list-icon list-icon-check list-icon-colored">
                                <li>
                                   Avoid generic promotional campaign messages. Bring your product or service to life in the minds of your customers with personalized video marketing
                                </li>
                                <li>
                                   Showcase your product in action with precise messages and personalized narratives
                                </li>
                                <li>
                                   Multi-channel distribution of videos to ensure maximum visibility across digital mediums - social, emails, SMS, WhatsApp, and online ads
                                </li>
                             </ul>
                          </div>
                          <img src="../../../../assets/frontend/images/personalized-video-ads/micro-target-customers-convert.webp" alt="Micro-Target Customers & Convert" class="solu-noimg">
                       </div>
                    </div>
                    <div class="tab-pane fade" id="tab06_pane" role="tabpanel" aria-labelledby="tab06">
                       <div class="media">
                          <img src="../../../../assets/frontend/images/personalized-video-ads/amplify-marketing-performance.webp" alt="Amplify Marketing Performance" class="solu-noimg">
                          <div class="media-body">
                             <ul class="list-icon list-icon-check list-icon-colored">
                                <li>
                                   Automate and optimize your marketing campaigns to ensure uninterrupted promotions
                                </li>
                                <li>
                                   Give your marketing teams a single dashboard view to manage video campaigns across all digital channels
                                </li>
                                <li>
                                   Enable your marketing & sales teams to build campaigns, track leads and scale engagement with prospects
                                </li>
                             </ul>
                          </div>
                       </div>
                    </div>
                 </div>
                 <!-- tabs end-->
              </div>
           </div>
        </div>
     </div>
  </div>
</section>
<!-- Benefits -->
<!-- Personalized Videos -->
<section class="s-pt-80 s-pb-0 s-pt-lg-160 s-pb-lg-60 s-pt-lg-160 s-pb-lg-60 c-gutter-60  ls pv">
  <div class="container">
     <div class="row">
        <div class="col-lg-8">
           <h4 class="top-title pvt">INCREASE LEAD CONVERSIONS WITH PERSONALIZED VIDEO CAMPAIGNS</h4>
           <p>Let's talk on the next steps!</p>
        </div>
        <div class="col-lg-3">
           <div class="d-block d-lg-none divider-30"></div>
           <div class="row" style="padding-top: 5px">
              <div class="col-sm-8 gcv-but">
                 <button  id="rad1" h name="rad" class="btn btn-maincolor"><span><a routerLink="/request-a-demo.html">Request a Demo</a></span>
                 </button>
              </div>
              <div class="col-sm-4 gcv-but">
                 <button id="contact-me" name="contact-me" class="btn btn-maincolor"><span><a routerLink="/contact.html">Contact Me</a></span>
                 </button>
              </div>
           </div>
        </div>
     </div>
  </div>
</section>
<!-- Personalized Videos -->