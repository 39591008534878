<section class="page_title cs s-pt-60 s-pb-10 s-pt-lg-100 s-pb-lg-30 page_title text-center">
  <div class="container-fluid">
     <div class="row">
        <div class="divider-60"></div>
        <div class="col-md-12 text-center">
           <h1>Personalized Social Media Videos</h1>
        </div>
        <div class="divider-50"></div>
     </div>
  </div>
  <ol class="breadcrumb full-width">
     <li class="breadcrumb-item">
        <a href="">Home</a>
     </li>
     <li class="breadcrumb-item">
        <a href="javascript:void(0)">Solutions</a>
     </li>
     <li class="breadcrumb-item active">
        Personalized Social Media Videos
     </li>
  </ol>
</section>

<section class="ls s-pt-75 s-pb-80 s-pb-lg-100 about-section text-center text-lg-left">
  <div class="d-none d-lg-block divider-10"></div>
  <div class="container">
     <!--<h4 class="top-title text-center">Differentiated Marketing Videos For Differentiated Social Users</h4>-->
     <div class="row align-items-center">
        <div class="col-12 col-lg-6 order-1 order-lg-1">
           <div class="d-none d-lg-block divider-20"></div>
           <div class="item-content2">
              <h4 class="top-title text-center">Differentiated Videos For Different Social Users</h4>
              <p class="big">Videos have become an undeniable part of social media marketing strategy to engage with the digital audience anywhere. Videos with static information is consuming most of the consumer traffic on social media today. However, different social networks have different audiences with different profiles and priorities. How do you cater to their differentiated interests? Enter the world of geo-targeted personalized social media videos.</p>
           </div>
           <div class="divider-60"></div>
           <div style="padding-left: 15px"><button id="rad" h="" name="rad" class="btn btn-maincolor" ><span><a routerLink="/contact.html">Get Started</a></span>
              </button>
           </div>
        </div>
        <div class="col-12 col-lg-6 order-2 order-lg-2">
           <div class="divider-60"></div>
           <div class="psmvimagehide">
              <img src="../../../../assets/frontend/images/personalized-social-media-videos/personalized-social-media-videos.webp" alt="Personalized Social Media Videos"  />
           </div>
           <div id="psmvcontainer">
              <ul id="psmvcontinents1">
                 <li class="psmv-1">
                    <a href="javascript:void(0)">
                    <span class="psmvimage1">
                    <img src="../../../../assets/frontend/images/personalized-social-media-videos/automatic.webp">
                    </span>
                    </a>
                 </li>
                 <li class="psmv-2">
                    <a href="javascript:void(0)">
                    <span class="psmvimage2">
                    <img class="img-responsive" src="../../../../assets/frontend/images/personalized-social-media-videos/pin.webp">
                    </span>
                    </a>
                 </li>
                 <li class="psmv-3">
                    <a href="javascript:void(0)">
                    <span class="psmvimage3">
                    <img src="../../../../assets/frontend/images/personalized-social-media-videos/pearl-grey.webp" class="img-responsive" >
                    </span>
                    </a>
                 </li>
                 <li class="psmv-4">
                    <a href="javascript:void(0)">
                    <span class="psmvimage4">
                    <img src="../../../../assets/frontend/images/personalized-social-media-videos/dealer5.webp" class="img-responsive" >
                    </span>
                    </a>
                 </li>
                 <li class="psmv-5">
                    <a href="javascript:void(0)">
                    <span class="psmvimage5">
                    <img src="../../../../assets/frontend/images/personalized-social-media-videos/suv.webp" class="img-responsive" >
                    </span>
                    </a>
                 </li>
              </ul>
           </div>
        </div>
     </div>
  </div>
</section>
<!-- .......................... -->
<section class="ls s-pb-50 s-pb-lg-60 s-pb-xl-60 c-mb-30 service-page2">
  <div class="container">
     <div class="row">
        <div class="col-md-4 col-12">
           <div class="hovereffect">
              <img class="img-responsive" src="../../../../assets/frontend/images/personalized-social-media-videos/feed-into-the-audience-interest.webp" alt="Feed into the audience interest">
              <div class="overlay">
                 <h2>Feed into the audience interest</h2>
                 <a class="info" href="javascript:void(0)">Create personalized social media videos in real-time, tailored for each social media user based on their unique set of personal attributes and preferences.</a>
              </div>
           </div>
        </div>
        <!-- .col-* -->
        <div class="col-md-4 col-12">
           <div class="hovereffect">
              <img class="img-responsive" src="../../../../assets/frontend/images/personalized-social-media-videos/data-driven-campaigns.webp" alt="Data-Driven Campaigns">
              <div class="overlay">
                 <h2>Data-Driven Campaigns</h2>
                 <a class="info" href="javascript:void(0)">Apply intelligent data and AI to dynamically serve the right video content across different social media platforms.</a>
              </div>
           </div>
        </div>
        <!-- .col-* -->
        <div class="col-md-4 col-12">
           <div class="hovereffect">
              <img class="img-responsive" src="../../../../assets/frontend/images/personalized-social-media-videos/personalized-digital-experience.webp" alt="Personalized Digital Experience">
              <div class="overlay">
                 <h2>Personalized Digital Experience</h2>
                 <a class="info" href="javascript:void(0)">Create highly engaging video experiences that convert and help you grow your business on social media.</a>
              </div>
           </div>
        </div>
        <!-- .col-* -->
     </div>
  </div>
</section>
<!-- .......................... -->
<!-- End-to-End Solution -->
<!-- End-to-End Solution -->
<!-- Benefits -->
<section class="ls s-pt-50 s-pb-20 s-pt-lg-120 s-pb-lg-20 s-pt-xl-50 c-mb-30 c-mb-lg-50 ls tab">
  <div class="divider-10"></div>
  <div class="container">
     <h4 class="top-title text-center">CREATE HYPER-PERSONALIZED SOCIAL ENGAGEMENTS AND DRIVE HIGHER CONVERSIONS</h4>
     <p class="text-center">GoCampaign social video marketing platform can be used to automatically generate thousands of social media videos using AI and data that successfully blend creativity, content, and information into one integrated whole.  Precisely target social customers and stand out in the social clutter.</p>
     <div class="divider-30"></div>
     <div class="row">
        <div class="col-12">
           <div class="row">
              <div class="col-lg-12">
                 <!-- tabs start -->
                 <ul class="nav nav-tabs" role="tablist" >
                    <li class="nav-item">
                       <a class="nav-link active" id="tab04" data-toggle="tab" href="#tab04_pane" role="tab" aria-controls="tab04_pane" aria-expanded="true">Adaptive Rendering</a>
                    </li>
                    <li class="nav-item">
                       <a class="nav-link" id="tab05" data-toggle="tab" href="#tab05_pane" role="tab" aria-controls="tab05_pane">Cross-Channel Distribution</a>
                    </li>
                    <li class="nav-item">
                       <a class="nav-link" id="tab06" data-toggle="tab" href="#tab06_pane" role="tab" aria-controls="tab06_pane">Social Storytelling</a>
                    </li>
                    <li class="nav-item">
                       <a class="nav-link" id="tab07" data-toggle="tab" href="#tab07_pane" role="tab" aria-controls="tab07_pane">Improve SEO</a>
                    </li>
                 </ul>
                 <div class="tab-content tab-media" style="background-color: #fff">
                    <div class="tab-pane fade show active" id="tab04_pane" role="tabpanel" aria-labelledby="tab04">
                       <div class="media">
                          <img src="../../../../assets/frontend/images/personalized-social-media-videos/adaptive-rendering.webp" alt="Adaptive Rendering" class="solu-noimg">
                          <div class="media-body">
                             <ul class="list-icon list-icon-check list-icon-colored">
                                <li class="tab-middle-psmv">
                                   Play on all browsers and devices with videos created in all standard video codecs, formats and adaptive bitrates for seamless playback
                                </li>
                             </ul>
                          </div>
                       </div>
                    </div>
                    <div class="tab-pane fade" id="tab05_pane" role="tabpanel" aria-labelledby="tab05">
                       <div class="media">
                          <div class="media-body">
                             <ul class="list-icon list-icon-check list-icon-colored">
                                <li class="tab-middle-psmv">
                                   Distribute your videos across all the major social media platforms and track its performance using real-time data and campaign dashboards
                                </li>
                             </ul>
                          </div>
                          <img src="../../../../assets/frontend/images/personalized-social-media-videos/cross-channel-distribution.webp" alt="Cross-Channel Distribution" class="solu-noimg">
                       </div>
                    </div>
                    <div class="tab-pane fade" id="tab06_pane" role="tabpanel" aria-labelledby="tab06">
                       <div class="media">
                          <img src="../../../../assets/frontend/images/personalized-social-media-videos/social-story-telling.webp" alt="Social Storytelling" class="solu-noimg">
                          <div class="media-body">
                             <ul class="list-icon list-icon-check list-icon-colored">
                                <li class="tab-middle-psmv">
                                   Reduce video dropouts in social media by engaging the customer right from the start using contextual, dynamic information that is automatically embedded in the video
                                </li>
                             </ul>
                          </div>
                       </div>
                    </div>
                    <div class="tab-pane fade" id="tab07_pane" role="tabpanel" aria-labelledby="tab07">
                       <div class="media">
                          <div class="media-body">
                             <ul class="list-icon list-icon-check list-icon-colored">
                                <li class="tab-middle-psmv">
                                   Optimize your SEO strategy with social media videos and backlinks. Increase website traffic and ranking
                                </li>
                             </ul>
                          </div>
                          <img src="../../../../assets/frontend/images/personalized-social-media-videos/improve-seo.webp" alt="Improve SEO" class="solu-noimg">
                       </div>
                    </div>
                 </div>
                 <!-- tabs end-->
              </div>
           </div>
        </div>
     </div>
  </div>
</section>
<!-- Benefits -->
<!-- Personalized Videos -->
<section class="s-pt-80 s-pb-0 s-pt-lg-160 s-pb-lg-60 s-pt-lg-160 s-pb-lg-60 c-gutter-60  ls pv">
  <div class="container">
     <div class="row">
        <div class="col-lg-8">
           <h4 class="top-title pvt">Drive geo-targeted social media engagements and results</h4>
           <p>Let's talk on the next steps!</p>
        </div>
        <div class="col-lg-3 ">
           <div class="d-block d-lg-none divider-30"></div>
           <div class="row" style="padding-top: 50px">
              <div class="col-sm-8 gcv-but">
                 <button  id="rad1" h name="rad" class="btn btn-maincolor"><span><a routerLink="/request-a-demo.html">Request a Demo</a></span>
                 </button>
              </div>
              <div class="col-sm-4 gcv-but">
                 <button id="contact-me" name="contact-me" class="btn btn-maincolor"><span><a routerLink="/contact.html">Contact Me</a></span>
                 </button>
              </div>
           </div>
        </div>
     </div>
  </div>
</section>
<!-- Personalized Videos -->