import { OnInit } from '@angular/core';
import { FrontendService } from '../../../../../_services';
import { FormBuilder, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
var HotelWelcomeComponent = /** @class */ (function () {
    function HotelWelcomeComponent(formBuilder, frontendService, toastr, router, titleService) {
        this.formBuilder = formBuilder;
        this.frontendService = frontendService;
        this.toastr = toastr;
        this.router = router;
        this.titleService = titleService;
        this.loading = false;
        this.submitted = false;
        this.hide_error = false;
        this.portrait = false;
        this.landscape = false;
    }
    HotelWelcomeComponent.prototype.ngOnInit = function () {
        // CREATE FORM VALIDATION
        this.landingForm = this.formBuilder.group({
            first_name: ['', Validators.required],
            company: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            message: ['', Validators.required],
            page: ['', '']
        });
        this.page = 'insurance';
        this.titleService.setTitle('Video Landing Page | Go campaign'); //SET PAGE TITLE
        this.router.queryParams.subscribe(function (params) {
            var code = params.id;
            localStorage.setItem('_vc', btoa(code));
        });
        var video_url = 'https://top2d3ddiag.blob.core.windows.net/videocampaign/videos/website/hotel_welcome.mp4?sp=rl&st=2020-10-14T05:49:06Z&se=2028-10-18T05:49:00Z&sv=2019-12-12&sr=b&sig=wg4BISa1t%2FfXoTJ5mLZ8WJSKLtRj63wROk3M9%2F8uZxQ%3D';
        this.video_url = video_url;
        this.landscape = true;
        localStorage.removeItem('_vc'); //REMOVE LOCALSTORAGE
    };
    Object.defineProperty(HotelWelcomeComponent.prototype, "f", {
        get: function () { return this.landingForm.controls; },
        enumerable: true,
        configurable: true
    });
    //ONSUBMIT EVENT 
    HotelWelcomeComponent.prototype.onSubmit = function () {
        var _this = this;
        this.submitted = true;
        // stop here if form is invalid
        if (this.landingForm.invalid) {
            return;
        }
        this.loading = true;
        var data = this.landingForm.value;
        console.log(data);
        var data = this.landingForm.value;
        this.frontendService.campaign_query(data)
            .pipe(first())
            .subscribe(function (data) {
            var myObjStr = JSON.stringify(data);
            console.log(myObjStr);
            var object = JSON.parse(myObjStr);
            var status = object.status;
            if (status == 'unsuccess') {
                var message = "something went wrong please try again";
                _this.toastr.errorToastr(message, 'Error', {
                    position: 'top-right'
                });
                _this.loading = false;
            }
            if (status == 'success') {
                //RESET ALL INPUT FIELD
                _this.landingForm.reset();
                var message = 'Thank you for contacting us. You are very important to us. All information received will always remain confidential. We will contact you as soon as we review your message.';
                _this.toastr.successToastr(message, 'Success', {
                    position: 'top-center'
                });
                _this.submitted = false;
                _this.loading = false; //LOADING REMOVED
            }
        }, function (error) {
            _this.loading = false;
        });
    };
    return HotelWelcomeComponent;
}());
export { HotelWelcomeComponent };
